.viewordermodal {
  position: absolute;
  left: -222px;
  bottom: 150%;
  width: 444px;
  background-color: white;
  color: #868887;
  text-align: left;
  padding: 0;
  -moz-box-shadow: 0 0 10px #000;
  -webkit-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: block;
  line-height: normal;
  font-size: 1rem;
  white-space: normal;
  cursor: default;
  &.rightOverflow {
      left: -412px;
      &:after {
        left: 93%;
      }
  }
  &.topOverflow {
    top: 150%;
    bottom: auto;
    &:after {
      top: auto;
      bottom: 100%;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: none;
      border-bottom: 8px solid white;
    }
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 2px;
  }
  .header {
    position: relative;
    width: 100%;
    line-height: normal;
    font-size: 1em;
    padding: 3% 0 3% 6%;
    text-align: left;
    color: #868887;
    background-color: #f6f6f6;
    .reserve {
      font-size: 1.5em;
    }
    .name {
      color: #19bd9a;
      font-size: 1.5em;
    }
    .phone {
      font-size: 0.9em;
    }
  }
  .body {
    padding: 3% 0 3% 6%;
    position: relative;
    overflow: visible;
    z-index: 500;
    .dateBlock {
      font-size: 1em;
      padding: 0 0 2% 0;
      .dates {
        vertical-align: middle;
        span {
          display: inline-block;
          vertical-align: middle;
          &.date, &.time {
            color: #73ae2e;
            font-size: 1.2em;
          }
        }
        .shift {
          color: #b7caaa;
          font-size: 0.8em;
          text-align: center;
          line-height: 2;
          border: 1px solid #73ae2e;
          border-radius: 2em;
          display: inline-block;
          padding: 0 3% 0 3%;
          width: auto;
        }
      }
      .duration {
        font-size: 0.9em;
        line-height: 3;
        .value {
          color: #73ae2e;
        }
      }
    }
    .serviceBlock {
      padding: 0 0 10% 0;
      .orderDetail {
        font-size: 1em;
        .service {
          color: #ff6804;
          font-size: 1.2em;
          line-height: normal;
        }
        .resource {
          font-size: 0.9em;
          line-height: normal;
        }
      }
    }
    .cancelOrder {
      color: #d9736f;
      font-size: 1em;
      text-align: center;
      line-height: 2;
      border: 1px solid #cf2525;
      border-radius: 2em;
      display: block;
      width: 80%;
      margin: auto;
      cursor: pointer;
      &.toConfirm {
        border: none;
        div {
          display: inline-block;
          margin-left: 1%;
          line-height: 2;
          border: 1px solid transparent;
          width: 18%;
          text-align: center;
          &:hover {
            border: 1px solid #cf2525;
            border-radius: 2em;
          }
        }
      }
      a {
        color: #d9736f;
        padding: 0 3% 0 3%;
        display: inline-block;
      }
    }
  }
}