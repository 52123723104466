@keyframes buttonFetching {
  from {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;

  }
}

.ordermodal {
  background-color: white;
  color: #868887;
  text-align: center;
  padding: 0;
  display: block;
  line-height: 2;
  cursor: default;
  z-index: 10000;

  &.finish {
    width: 310px;
    background-image: url('../OrderModal/assets/finish-order-bgr.png');

    &:before {
      display: block;
      content: '';
      padding-top: 24%;
    }

    &.topAlign {
      bottom: 100%;
      margin-bottom: 10px;
      left: -153px;

      .triangle.bottom {
        display: block;
      }
    }

    &.bottomAlign {
      top: 100%;
      margin-top: 10px;
      left: -153px;

      .triangle.top {
        display: block;
      }
    }

    &.leftAlign {
      top: -12px;
      margin-right: 10px;
      right: 100%;

      .triangle.right {
        display: block;
      }
    }

    &.rightAlign {
      top: -12px;
      margin-left: 10px;
      left: 100%;

      .triangle.left {
        display: block;
      }
    }
  }

  .triangle {
    display: none;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
    position: absolute;

    &.bottom {
      top: 100%;
      left: 50%;
      margin-left: 2px;
    }

    &.top {
      bottom: 100%;
      left: 50%;
      margin-left: 2px;
      transform: rotate(180deg);
    }

    &.right {
      top: 20px;
      left: 100%;
      transform: rotate(-90deg);
      margin-left: -4px;
    }

    &.left {
      top: 20px;
      right: 100%;
      transform: rotate(90deg);
      margin-right: -4px;
    }
  }

  .header {
    position: relative;
    width: 100%;
    line-height: normal;
    padding: 4% 5% 4% 5%;
    text-align: center;
    color: #868887;
    background-color: #f6f6f6;
    white-space: nowrap;
    vertical-align: middle;
    overflow: hidden;

    .clientBlock {
      overflow: hidden;
      font-size: 1.6em;

      .text {
        display: inline-block;
        vertical-align: middle;
      }

      .client {
        color: #ff6804;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        margin-right: 0.5em;
        vertical-align: middle;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .search {
        display: inline-block;
        width: 0.7em;
        height: 0.7em;
        background-image: url('../OrderModal/assets/loupe.png');
        background-size: cover;
        vertical-align: middle;
        cursor: pointer;
      }

      .addClient {
        display: inline-block;
        vertical-align: middle;
        border-radius: 2.6em;
        border: 1px solid #bec3c3;
        color: #bec3c3;
        line-height: 1.2;
        padding: 0 .31em;
        text-align: center;
        font-size: 0.8em;
        margin-left: 0.5em;
        cursor: pointer;
      }
    }

    .searchBlock {
      white-space: nowrap;

      a {

      }

      .cancelSearch {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(../../../assets/close-delete-icon.png);
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: middle;
        margin: 0 0 .2em 0.6em;
        cursor: pointer;
      }

      :global {
        .label {
          color: #ff6804;
          display: inline-block;
          white-space: nowrap;
          margin-right: 0.5em;
          vertical-align: middle;
        }

        .clientSearch {
          display: inline-block;

          .label {
            display: none;
          }
        }
      }
    }
  }

  .body {
    padding: 0;
    position: relative;
    overflow: visible;
    z-index: 500;
    white-space: nowrap;

    &:before {
      content: '';
      width: 1px;
      margin-right: -1px;
      display: inline-block;
      padding-top: 46%;
      vertical-align: top;
    }

    .wrapper {
      display: inline-block;
      vertical-align: top;
      width: 100%;

      .addClientBody {
        width: 100%;
        display: inline-block;
        vertical-align: top;

        .row {
          padding-top: 4%;
          position: relative;
          line-height: 1.6;
          text-align: left;

          &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }

          .label {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            color: #848889;
            font-size: 0.9em;
            width: 33%;
          }

          input {
            display: inline-block;
            vertical-align: top;
            border: 1px solid #bec3c3;
            border-radius: 0.25em;
            font-size: 1.1em;
            color: #485051;
            width: 48%;
            margin-left: 2%;
            padding-left: 3%;
          }

          .phone {
            color: #1abc9c;
          }

          &:last-child {
            padding-bottom: 6%;

            input {
              height: 95px;
            }
          }

          textarea {
            display: inline-block;
            vertical-align: top;
            border: 1px solid #bec3c3;
            border-radius: 0.25em;
            font-size: 1.1em;
            color: #485051;
            width: 48%;
            margin-left: 2%;
            padding-left: 3%;
            resize: none;
          }
        }
      }
    }

    :global {
      .ClientsList {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .client {
          padding-left: 4%;
        }
      }
    }

    .resource {
      font-size: 0.9em;
      line-height: 3;
      padding-bottom: 4%;
    }

    .dateAndService {
      padding: 2% 4% 4% 1%;
      overflow: visible;
      height: auto;
      white-space: nowrap;
      position: relative;

      .date {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 0 0 0;
        margin-right: 2%;
        text-align: right;
        font-size: 0.9em;

        span {
          color: #73ae2e;
          font-size: 1.2em;
          font-weight: 600;
        }
      }

      .services {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 0 0 0;

        .reserveTitle {
          text-align: right;
          padding-right: 5%;
          color: #ff6804;
        }

        :global {
          .clubselect.select {
            color: #ff6804;

            .control {
              cursor: pointer;

              .value {
                padding-left: 2em;
                margin-right: 3em;
              }
            }
          }

          .clubselect {
            .control {
              cursor: pointer;
            }
          }
        }
      }

      .serviceDuration {
        font-size: 0.9em;
        text-align: right;
        padding-right: 5%;
        line-height: 3;

        > span {
          color: #73ae2e;
          display: inline-block;
        }

        :global {
          .clubselect.link {
            .error {
              .wrapper {
                right: 100%;
                position: absolute;
                margin-right: -30px;
              }
            }
          }
        }
      }
    }

    .services {
      width: 100%;

      .row {
        text-align: left;
        border-bottom: 1px solid #f1f0f0;
        margin: 0 5%;
        padding: 1.5% 0;

        &:before {
          content: '';
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }

        .name {
          width: 56%;
          display: inline-block;
          vertical-align: middle;
          white-space: nowrap;

          > div {
            display: inline-block;
            white-space: normal;
            vertical-align: text-top;
            padding: 0;
            line-height: 1.6;

            span {
              font-size: 0.9em;
              display: inline-block;
              white-space: nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 1;
            }
          }
        }

        .duration {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          color: #73ae2e;
          line-height: 1.5;

          > div {
            display: inline-block;
            border-bottom: 1px dashed #73ae2e;
            height: auto;
            cursor: pointer;
          }
        }

        .price {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          color: #73ae2e;
          font-weight: 600;
          padding-right: 1%;
          line-height: 1.5;

          input {
            border: 1px solid #bec3c3;
            border-radius: .25em;
            width: 100%;
            color: #868887;
            padding: 1% 3%;
            display: inline-block;
          }

          .value {
            display: inline-block;
            border-bottom: 1px dashed #73ae2e;
            cursor: pointer;
          }
        }

        .remove {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          background: url('../OrderModal/assets/delete-icon.png') no-repeat;
          background-size: cover;
          cursor: pointer;

          &:hover {
            background-position: 100% top;
          }
        }
      }
    }

    .summaryInfo {
      text-align: right;
      white-space: nowrap;

      .totalDuration {
        display: inline-block;
        width: auto;
        text-align: right;
        padding-right: 5%;

        :global {
          div.control {
            display: inline-block;
            border-bottom: 1px dashed #73ae2e;
            height: auto;
            cursor: pointer;
            color: #73ae2e;
            font-weight: 600;
            line-height: 1.5;
          }
        }

      }

      .totalPrice {
        text-align: right;
        padding: 3% 9% 10% 0;
        width: 40%;
        display: inline-block;

        .label {
          display: inline-block;
          padding-right: 2%;
        }

        .value {
          display: inline-block;
          color: #73ae2e;
          font-weight: 600;
        }
      }
    }


    .reserveClient {
      text-align: right;
      padding-right: 3%;
    }
  }

  hr {
    border: none;
    background-color: #e3e1e1;
    height: 1px;
    margin: auto;
  }

  .footer {
    text-align: right;
    width: 96%;
    overflow: hidden;
    line-height: normal;
    padding: 2% 1% 2% 1%;
    z-index: 10;

    > div {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      cursor: pointer;

      &.order {
        margin: 0;
        color: #fff;
        font-size: 1em;
        text-align: center;
        background-color: #73ae2e;
        border-radius: 1.4em;
        display: inline-block;
        width: auto;
        min-width: 1em;
        position: relative;
        overflow: hidden;
        line-height: normal;
        padding: 1% 1em 1% 1em;

        &.reserve {
          background-color: #47c5d8;
        }

        &.blocked {
          background: #848889;
          cursor: not-allowed;
        }

        &.fetching {
          -webkit-animation-name: buttonFetching;
          animation-name: buttonFetching;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          pointer-events: none;
        }
      }

      &.cancel {
        color: #2e7dad;
        line-height: normal;
        padding: 1% 1em 1% 1em;
        text-decoration: underline;
      }
    }
  }
}

.container {
  padding: 2% 5%;
  position: relative;
  height: 100%;
  .header {
    .title {
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5em;
      line-height: 2.4;
      color: #77AD3A;
      margin-bottom: 2%;
    }
  }
  .blockHeader {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6;
    padding-bottom: 2%;
    color: #848484;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 4%;
    &.noBlock {
      border-bottom: none;
    }
  }

  .services {
    position: relative;
    height: 80%;
    padding: 0% 0 4% 0;



    .servicesCatalogue {
      width: 100%;
      clear: both;
      overflow: visible;

      h3 {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2em;
        line-height: 25px;
        color: #1E1E1E;

        &:after {
          content: '';
          display: inline-block;
          height: 0.6em;
          width: 0.6em;
          background-image: url('../OrderModal/assets/triangle.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          vertical-align: middle;
          margin-left: 0.5em;
        }
      }

      .items {
        width: 104.5%;
        margin-left: 5px;

        .item {
          background: #EDF8F3;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
          width: 28.4%;
          float: left;
          margin: 0 4.4% 4% 0;
          overflow: hidden;

          &:before {
            content: '';
            //padding-top: 25%;
            float: left;
            display: block;
          }

          &.active {
            .title {
              background-color: #9DC2C6;

              &.withOptions {
                background-color: inherit;
              }
            }
          }

          .title {
            position: relative;
            left: 0;
            top: 0;
            width: auto;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.8;
            padding: 4% 16% 4% 16%;
            color: #52797E;
            font-size: 1em;
            cursor: default;

            &.withOptions {
              cursor: auto;
              font-size: 0.88em;
              color: #7F8384;
              padding: 4% 0 4% 4%;
            }
          }

          .options {
            line-height: 2.4;

            .template {
              color: #9b9b9b;

              .value {
                padding: 0.8em 10% 0.8em 10%;
                font-weight: 400;
                line-height: 1;
                color: #52797E;
                cursor: default;
                display: none;

                &.available {
                  display: block;
                }

                &.active {
                  background-color: #9DC2C6;
                  color: white;
                  padding: 0.8em 10% 0.8em 2%;

                  &:before {
                    content: '';
                    display: inline-block;
                    height: 1em;
                    width: 1em;
                    background-image: url('../OrderModal/assets/check-icon.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    vertical-align: middle;
                    margin-right: 0.5em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .confirm {
    position: absolute;
    height: 80%;
    width: 100%;
    top: 91.4%;
    left: 0;
    background-color: white;
    transition: top 0.5s ease-in;
    border-top: 1px solid #E9E9E9;
    padding: 2% 5%;
    .tab {
      position: absolute;
      width: 336px;
      height: 34px;
      right: 4%;
      bottom: 100%;
      margin-bottom: -24px;
      background: #FFFFFF;
      box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
      border-radius: 1rem 1rem 0 0;
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.125em;
      line-height: 2;
      text-align: center;
      color: #FF8A00;
    }
    .choosenServices {
      float: left;
      width: 69%;
      padding: 0 6% 0 0;
      .service {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125em;
        line-height: 1.6;
        color: #848484;
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid #E9E9E9;
        .title {
          margin-bottom: 1em;
          .duration {
            float: right;
            position: relative;
            color: #52797E;
            font-size: 1rem;
            text-align: right;
          }
          .price {
            float: right;
            border-bottom: 1px solid #52797E;
            position: relative;
            color: #52797E;
            font-size: 1rem;
            input {
              background: transparent;
              border: none;
              width: 4em;
              display: inline-block;
              color: #52797E;
              text-align: right;
            }
          }
        }

        .option {
          padding: 0 0 0 5em;
          font-family: Open Sans, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 1em;
          line-height: 1.6;
          color: #52797E;
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .description {
      float: left;
      width: 69%;
      padding: 0 6% 0 0;
      textarea {
        border: none;
        width: 100%;
        resize: none;
        font-style: italic;
      }
    }
    .clientSearch {
      float: right;
      width: 31%;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &.confirm {
    .confirm {
      top: 11.5%;
      z-index: 5;
      .tab {
        margin-bottom: -1px;
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8.5%;
  background-color: white;
  padding: 1.8% 0 1.8% 0;
  z-index: 10;
  :global {
    .row {
      overflow: visible;
      width: 96%;
      margin: auto;
      .control .value {
        text-decoration: underline;
      }
    }
  }
  .info {
    text-align: center;
    > div {
      display: inline-block;
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9em;
      line-height: 2;
      color: #0298D9;
      vertical-align: middle;
      width: 33%;
      text-align: left;
    }
    .duration {
      .error {
        position: absolute;
        font-size: 0.8em;
        top: 2.4em;
        color: red;
        vertical-align: top;
        line-height: 1;
      }
    }

  }

  .links {
    text-align: center;
    > div {
      display: inline-block;
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9em;
      line-height: 2;
      color: #0298D9;
      vertical-align: middle;
      width: 33%;
      text-align: left;
      cursor: pointer;
      &.cancel {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9em;
        line-height: 2;
        text-decoration-line: underline;
        color: #EB5757;
      }
      &.backward {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9em;
        line-height: 2;
        text-decoration-line: underline;
        color: #7D7D7D;
      }
      &.proceed {
        background: #72AC2F;
        border-radius: 0.5em;
        color: white;
        text-align: center;
        width: 60%;
        &.disabled {
          pointer-events: none;
          background: #7D7D7D;
        }
      }
    }
  }
}

.finishedWindow {
  .text {
    color: #70ad2b;
    font-size: 1.2em;
    line-height: normal;
    padding: 7% 8% 5% 8%;
  }
  .clear {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    margin: 0 0 10%;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
    background-color: #1abc9c;
    border-radius: 1.4em;
    display: inline-block;
    width: auto;
    position: relative;
    overflow: hidden;
    line-height: normal;
    padding: 2% 8% 3% 8%;
    cursor: pointer;
  }
  &.failed {
    background-image: none;

    &:before {
      padding-top: 0;
    }

    .text {
      color: #9d223c;
    }
  }
}


