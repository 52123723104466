.element {
  width: 76%;
  height: 100%;
  position: relative;
  float: right;
  background-color: white;
}

.header {
  width: 100%;
  //height: 12.3%;
  //position: relative;
  .client {
    display: inline-block;
    //vertical-align: middle;
    .name {
      font-size: 2em;
      padding-bottom: 10%;
      color: #e7832a;
    }
    .phone {
      font-size: 1.1em;
      color: #485051;
    }
  }
  .status {
    color: #6fceb8;
    font-size: 1.5em;
    float: right;
    padding: 1% 7% 0 0;
  }
}

.body {
  //height: 100%;
  .clientData {
    padding-top: 4%;
    .photoBlock {
      display: inline-block;
      margin-top: 0.8%;
      .photo {
        border: 1px solid #1abc9c;
        width: 135px;
        height: 190px;
        overflow: hidden;
        position: relative;
        :global {
          .preloader {
            &:before {
              width: 50%;
              padding-top: 50%;
              left: 26%;
              top: 34%;
            }
          }
        }

      }
      .birth {
        position: relative;
        text-align: center;
        padding-top: 4%;
        .birthDate {
          font-size: 1.1em;
          color: #1abc9c;
          display: inline-block;
          border-bottom: 1px solid;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    .dataBlock {
      display: inline-block;
      vertical-align: top;
      padding: 0 0 0 4%;
      width: 33.5%;
      &:last-child {
        padding-right: 0;
        width: 45%;
      }
      :global {
        .fieldInput {
          > div {
            color: #696e6f;
          }
        }
      }
    }
  }
}

.high {
  font-size: 1.5em;
}

.orange {
  color: #e7832a;
}

.grey {
  color: #696e6f;
}

.green {
  color: #1abc9c;
}

.medium {
  font-size: 1.1em;
}

.low {
  font-size: 0.9em;
}

.cursorPointer {
  cursor: pointer;
}

.age {
  padding-bottom: 5%;
  position: relative;
}

.loyalty {
  padding-top: 15.8%;
}

.character {
  padding-top: 14%;
}

.check {
  padding-bottom: 7.5%;
}

.lastActivity {
  padding-top: 5%;
}

.block {
  margin-bottom: 5%;
}

.checkInfo {
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.checkLengthDepth {
  display: inline-block;
  vertical-align: middle;
  white-space: pre;
  padding-right: 4em;
  //width: 76%;
  background: url("../../../assets/brace.png") 87% 0.4em no-repeat;
}

.checkLength {
  padding-bottom: 2.7em;
  display: block;
}

.checkDepth {
  display: block;
}

.checkAverage {
  display: inline-block;
  vertical-align: middle;
  //padding-left: 10%;
}

.visit {

}

.inlineBlock {
  display: inline-block;
}

.starLine {
  padding-top: 3.5%;
}

.brightStar {
  width: 2.2em;
  height: 2em;
  display: inline-block;
  background: url("../../../assets/brightStar.png") no-repeat;
}

.dimStar {
  width: 2.2em;
  height: 2em;
  display: inline-block;
  background: url("../../../assets/dimStar.png") no-repeat;
}

.changeValue {
  position: relative;
  display: inline-block;
  max-width: 100%;
  &:hover {
    cursor: pointer;
  }
  .value {
    font-size: 1.5em;
    line-height: 1.3;
    display: inline-block;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .select {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    background: url("../../../assets/select.png") 100% 50% no-repeat;
  }
  .edit {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    vertical-align: bottom;
    background: url("../../../assets/pencil.png") 100% 50% no-repeat;
  }
  .outerMenu {
    box-sizing: content-box;
    width: 100%;
    min-width: 200px;
    height: auto;
    max-height: 300px;
    position: absolute;
    background-color: white;
    border: 1px solid #ff7113;
    padding: 10px 10px 10px 10px;
    right: -1.5em;
    top: 140%;
    text-align: left;
    line-height: 1.8;
    white-space: normal;
    &:before {
      content: '';
      position: absolute;
      top: -7px;
      right: 25px;
      display: block;
      height: 11px;
      width: 11px;
      border-top: solid 1px transparent;
      border-right: solid 1px #ff7113;
      border-left: solid 1px transparent;
      border-bottom: solid 1px #ff7113;
      background-color: white;
      transform: rotate(-135deg);
    }
    .item {
      font-size: 0.9rem;
      color: #868887;
      margin: 0 10px 0 16px;
      padding: 0 6px 0 6px;

      cursor: pointer;
      &:hover {
        background-color: #dff6f1;
      }
    }
  }
}

.scrollTrack {
  position: absolute;
  width: 3px !important;
  transition: opacity 200ms;
  right: 0px;
  top: 7%;
  height: 89%;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 110%;
    background-color: #c0bcbc;
    top: -5%;
    left: 1px;
  }
}

.scrollThumb {
  width: 3px;
  //height: 16px !important;
  background-color: #949191;
  border-radius: 1px;
  overflow: hidden;
}

.message_name {
    width: 100%;
    margin-bottom: 5%;
    .label {
        display: inline-block;
        margin-right: 1%;
    }
    .pointer {
        cursor: pointer;
    }
    .edit {
        width: 1.5em;
        height: 1.5em;
        display: inline-block;
        vertical-align: bottom;
        background: url("../../../assets/pencil.png") 100% 50% no-repeat;
    }
    .editOk {
        width: 1.5em;
        height: 1.5em;
        display: inline-block;
        vertical-align: bottom;
        background: url("../../../assets/editOk.png") no-repeat;
        cursor: pointer;
    }
    .text {
        font-size: 1.5em;
        max-width: 90%;
        display: inline-block;
    }
    input {
        border: 1px solid #bec3c3;
        border-radius: .25em;
        font-size: 1.1em;
        color: #485051;
        width: 90%;
        padding: 2% 0 2% 2%;
        margin-right: 1%;
        resize: none;
    }
}

.comment {
  padding-top: 2em;
  width: 250%;
  .label {
    display: inline-block;
    margin-right: 1%;
  }
  .pointer {
    cursor: pointer;
  }
  .edit {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    vertical-align: bottom;
    background: url("../../../assets/pencil.png") 100% 50% no-repeat;
  }
  .editOk {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    vertical-align: bottom;
    background: url("../../../assets/editOk.png") no-repeat;
    cursor: pointer;
  }
  .text {
    max-width: 90%;
    display: inline-block;
  }
  textarea {
    border: 1px solid #bec3c3;
    border-radius: .25em;
    font-size: 1.1em;
    color: #485051;
    width: 90%;
    padding: 2% 0 2% 2%;
    margin-right: 1%;
    resize: none;
  }
}
