.default {
  border: 1px solid #bec3c3;
  border-radius: .25em;
  width: auto;
  line-height: 1.5;
  padding: 1% 3%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
}
.success {
  @extend .default;
  border-color: green !important;
}
.fail {
  @extend .default;
  border-color: #ce252c !important;
}
