.main {
  height: 100%;
  background-color: #fff;
  .header {
    height: 12.3%;
    background-color: #7d7f8e;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.1em;
    line-height: 1.4;
    .link {
      position: relative;
      background-color: #7d7f8e;
      vertical-align: middle;
      height: 100%;
      display: inline-block;
      cursor: pointer;
      background-repeat: no-repeat;
      text-align: left;
      padding-left: 14%;
      padding-right: 4%;
      &:before {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
      }
      &:hover {
        background-color: #646676;
      }
      .wrapper {
        display: inline-block;
        vertical-align: middle;
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 13px solid #646676;
        position: absolute;
        left: 138px;
        bottom: -12px;
        display: none;
      }
      &.active {
        background-color: #646676;
        &:after {
          display: block;
        }
      }
      &.employees {
        width: 33.3%;
        background-image: url('../CallsMain/assets/employees-icon.png');
      }
      &.lids {
        width: 33.4%;
        background-image: url('../CallsMain/assets/lid-icon.png');
      }
      &.statistic {
        background-image: url('../CallsMain/assets/statistic-icon.png');
        width: 33.3%;
      }
    }
  }
  .body {
    height: 87.7%;
  }
}