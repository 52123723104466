@import '../../../../../../styles/themes/default/colors.scss';

.main {
  width: 100%;
  //height: 1000px;
  position: relative;
  color: $grey-color;
  .pollBlock {
    border-bottom: 41px solid #f3f3f3;
    padding: 1.5% 2.9% 2.7%;
    .title {
      color: $h-color;
      font-size: 2.2em;
      letter-spacing: 0.015em;
      padding-left: 0.3%;
      padding-bottom: 1.8%;
    }
    .questionBlock {
      padding-top: 2.7%;
      .number {
        display: inline-block;
        vertical-align: top;
        height: 2.95em;
        text-align: center;
        width: 2.95em;
        color: $h-color;
        border: 1px solid $h-color;
        border-radius: 50%;
        margin-top: 0.2%;
        &:before {
          content: '';
          height: 100%;
          vertical-align: middle;
          display: inline-block;
        }
        > div {
          display: inline-block;
          vertical-align: middle;
          font-size: 1.5em;
          padding-bottom: 5%;
        }
      }
      .question {
        width: 75%;
        display: inline-block;
        vertical-align: top;
        padding-left: 1.5%;
        padding-right: 5%;
        .value {
          display: inline-block;
          font-size: 1.5em;
          padding-bottom: 0.4em;
          line-height: 1;
        }
        .info {
          font-size: .85em;
          :global {
            .club-datepicker {
              vertical-align: top;
              &.inline {
                margin: 0 0.5em;
              }
              .text {
                color: $h-color;
                vertical-align: top;
                cursor: pointer;
                border-bottom: 1px solid $h-color;
              }
              .dpSwitcher {
                display: none;
              }
            }
          }
        }
      }
      .button {
        display: inline-block;
        vertical-align: top;
        color: $orange;
        font-size: .75em;
        margin-top: 0.1%;
        padding: 0.66% 2.55%;
        border-radius: 2em;
        cursor: pointer;
        border: 1px solid #ffffff;
        &.active, &:hover {
          border: 1px solid $orange;
        }
      }
    }
    .chart {
      padding-top: 2.5%;
      padding-bottom: 2.7%;
      padding-left: 0.2%;
      border-bottom: 1px solid $grey-border-color;
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      &:before {
        content: '';
        height: 100%;
        vertical-align: middle;
        display: inline-block;
      }
      svg {
        vertical-align: middle;
        display: inline-block;
      }
      .answerColor {
        vertical-align: middle;
        display: inline-block;
        border-radius: 15%;
        height: 1.9em;
        width: 1.9em;
        background-color: #ef952e;
        margin: 0 1.5% 0.5% 5.4%;
        &.generalWow {
          background-color: #e66050;
        }
        &.generalGood {
          background-color: #f8cc9e;
        }
        &.generalBad {
          background-color: #ef952e;
        }
        &.paramFirstYes {
          background-color: #ec7e00;
        }
        &.paramFirstNo {
          background-color: #29afd9;
        }
        &.paramSecondYes {
          background-color: #85d0a4;
        }
        &.paramSecondNo {
          background-color: #ce95c7;
        }
        &.paramThirdYes {
          background-color: #47ba90;
        }
        &.paramThirdNo {
          background-color: #c32933;
        }
      }
      .answer {
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 0.7%;
        width: 16.5%;
        .value {
          font-size: 1.5em;
        }
        .count {
          display: inline-block;
          color: $orange;
          border-bottom: 1px solid #e67e22;
          font-size: 1.1em;
          cursor: pointer;
        }
      }
    }
    .lineChart {
      padding: 5.1% 0 3.7% 6%;
      border-bottom: 1px solid $grey-border-color;
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      .line {
        min-width: 3%;
        max-width: 50%;
        display: inline-block;
        vertical-align: middle;
        height: 1.9em;
        border-radius: 0.3em;
      }
      .lineCount {
        display: inline-block;
        vertical-align: middle;
        margin-left: 1%;
        color: $orange;
        border-bottom: 1px solid #e67e22;
        font-size: 1.1em;
        cursor: pointer;
      }
      .question {
        font-size: 1.5em;
        padding: .6% 0 3.7% .2%;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .addQuestionBlock {
      padding-top: 4%;
      padding-bottom: 6.0%;
      border-bottom: 1px solid $grey-border-color;
      .icon {
        display: inline-block;
        vertical-align: top;
        height: 2.95em;
        text-align: center;
        width: 2.95em;
        color: $h-color;
        border: 1px solid $h-color;
        border-radius: 50%;
        &:before {
          content: '';
          height: 100%;
          vertical-align: middle;
          display: inline-block;
        }
        > div {
          display: inline-block;
          vertical-align: middle;
          font-size: 2.6em;
          line-height: 1;
          padding-bottom: 5%;
        }
      }
      .text {
        display: inline-block;
        vertical-align: top;
        color: $h-color;
        font-size: 2.2em;
        letter-spacing: 0.015em;
        padding-left: 1.5%;
      }
      .button {
        display: inline-block;
        vertical-align: top;
        color: #ffffff;
        background-color: $h-color;
        padding: .85% 3.9%;
        border: 1px solid #1abc9c;
        border-radius: 2em;
        font-size: 1.1em;
        margin: 0.2% 0 0 1.6%;
        cursor: pointer;
      }
      .description {
        font-size: .85em;
        padding: 1.3% 33% 0 5.7%;
        line-height: 1.2;
      }
    }
  }
}
