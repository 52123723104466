:global {
  .renderInBody {
    &__placeHolder {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0%;
      top: 0;
      pointer-events: none !important;
    }
    &__anchor {
      * {
        pointer-events: auto;
      }
    }
  }
}