@charset "UTF-8";
@keyframes rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.cover {
  height: 96px;
  width: 86px;
  border-radius: 4px;
  display: block;
  overflow: visible;
  position: relative; }
  .cover :global(.slim) {
    background-color: white;
    border: 1px dashed #CCD1D9;
    border-radius: 4px;
    height: 96px;
    width: 86px; }
    .cover :global(.slim) label {
      width: 100%; }
    .cover :global(.slim):hover {
      background-color: gray; }
      .cover :global(.slim):hover:before {
        background-position: left -100%; }
    .cover :global(.slim):before {
      content: '';
      display: block;
      position: absolute;
      width: 22px;
      height: 22px;
      left: 32px;
      top: 28px;
      border-radius: 11px;
      background-image: url("./assets/add.svg"); }
    .cover :global(.slim):after {
      content: 'Фото';
      font-size: 14px;
      color: gray;
      font-weight: 600;
      width: 100%;
      text-align: center;
      display: block;
      position: absolute;
      top: 56%; }
  .cover:hover .coverImg .remove {
    opacity: 1; }
  .cover .coverImg {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #CCD1D9;
    height: 96px;
    width: 86px;
    border-radius: 4px;
    z-index: 10;
    pointer-events: none;
    background: white; }
    .cover .coverImg:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url("./assets/setting.svg");
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      animation-name: rotation;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; }
    .cover .coverImg:hover .remove {
      opacity: 1; }
    .cover .coverImg img {
      height: 96px;
      width: 86px;
      border-radius: 4px; }
    .cover .coverImg .remove {
      pointer-events: auto;
      transition: opacity 0.1s ease-in-out 0s;
      opacity: 0;
      display: block;
      position: absolute;
      right: -5px;
      top: -5px;
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 12px;
      background: url("./assets/remove.svg");
      z-index: 10;
      cursor: pointer; }
      .cover .coverImg .remove:hover {
        background-position: left -100%; }
    .cover .coverImg.error img {
      visibility: hidden; }
