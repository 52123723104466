@import './../../../../../styles/themes/default/colors';

:global {
    .settings-block--posts {
        margin-top: -2em;
        .infoHeader {
            white-space: nowrap;
            font-size: 0.75em;
            line-height: 1;
            margin-bottom: 4%;
            div {
                display: inline-block;
                margin-right: 5em;
                span.red {
                    color: red;
                }
            }
        }

        .resources {
            &__theader {
                white-space: nowrap;
                font-size: 0.75em;
                line-height: 6;
                &__td {
                    display: inline-block;
                    text-align: center;
                    width: 25%;
                    &:first-child {
                        width: 56%;
                        padding-left: 2%;
                        text-align: left;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                }
            }
            > div, > a {
                border-bottom: 1px solid #E0E0E0;
            }

            .resource {
                font-size: 1em;
                line-height: 5.2;
                padding: 0 1% 0 1%;
                cursor: pointer;
                white-space: nowrap;
                display: block;
                &:hover {
                    background-color: #f2f5f6;
                }
                &:last-child {
                    border-bottom: none;
                }
                > div {
                    display: inline-block;
                }
                &__avatar {
                    width: 4.5%;
                    vertical-align: middle;
                    border-radius: 50%;
                    overflow: hidden;
                    padding-top: 4.5%;
                    position: relative;
                    margin-right: 2%;
                    background-color: white;
                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                &__title {
                    width: 50%;
                }
                &__service {
                    width: 25%;
                    text-align: center;
                }
                &__working {
                    text-align: left;
                    width: 15%;
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 0.5em;
                        padding-top: 0.5em;
                        background-color: #0ad100;
                        border-radius: 50%;
                        margin-right: 10%;
                    }
                    &.on {
                        &:before {
                            background-color: #0ad100;
                        }
                    }
                    &.off {
                        &:before {
                            background-color: $selected-color;
                        }
                    }
                }
                &__isOnline {
                    text-align: center;
                    width: 25%;
                }
                &.unserviced {
                    .resource__title {
                        color: #BDBDBD;
                    }
                    .resource__avatar {
                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: white;
                            opacity: 0.6;
                        }
                    }
                }
            }
            .addResource {
                display: inline-block;
                font-size: 1em;
                line-height: 2.1;
                border-radius: 1.2em;;
                padding: 0 0 0 1%;
                vertical-align: middle;
                white-space: nowrap;
                width: auto;
                margin: 2% 0;
                cursor: pointer;
                border-bottom: none;
                color: #72AC2F;
                &:before {
                    content: '';
                    width: 3em;
                    height: 3em;
                    display: inline-block;
                    vertical-align: middle;
                    border: 1px solid #72AC2F;
                    border-radius: 50%;
                    margin-right: 1em;

                }
            }
        }
    }

    .createPost {
        position: fixed;
        background-color: rgba(0, 0, 0, .8);
        overflow: scroll;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1001;
        font-size: 14px;
        &__content {
            position: relative;
            width: 1130px;
            background-color: #f6f6f6;
            margin: 10% auto 10% auto;
            border-radius: 15px;
            overflow: hidden;
        }
        &__header {
            width: 100%;
            background-color: #e8e8e8;
            white-space: pre-wrap;
            padding: 0 3% 0 0;
            overflow: hidden;
            white-space: nowrap;
            &:before {
                content: '';
                padding-top: 21%;
                width: 4%;
                display: inline-block;
                vertical-align: middle;
            }
            h1 {
                font-family: Open Sans;
                font-style: normal;
                font-weight: 300;
                line-height: 1.6;
                font-size: 2em;
                color: #2D9CDB;
                margin: 0% 0 1% 0;
            }

            .slim {
                overflow: hidden;
                display: block;
                vertical-align: middle;
                margin: 0 0 10px 3%;
                height: 167px;
                width: 167px;
                background-color: #ddd;
                border-radius: 50%;
                &.slim-no-bg {
                    background-color: transparent;
                }
                img {
                    height: 167px;
                    width: 167px;
                }
                .slim-btn-group {
                    padding: 0.5em 0;
                }
            }
            .image {
                height: 100px;
                width: 100px;
                display: inline-block;
                vertical-align: middle;
                margin: 10px 0 10px 3%;
                border-radius: 50%;
                background-color: #b7b7b7;
            }
            .peninput {
                width: 100%;
                vertical-align: middle;
                color: #666666;
                font-size: 1.8em;
                margin: 0.4em 0 1.2em 0;
            }
            .trigger {
                display: none;
                float: right;
                margin: 2.4em 0 1.2em;
                width: 37%;
                .link {
                    display: inline-block;
                    line-height: 2;
                    border-radius: 1em;
                    border: 1px solid #a6a6a6;
                    padding: 0 1em;
                    margin: 0 1em;
                    vertical-align: middle;
                    color: #fff;
                    width: 50%;
                    text-align: center;
                    color: #a6a6a6;
                    cursor: pointer;
                    &.online {
                        background-color: #1abc9c;
                        border-color: transparent;
                        color: white;
                    }
                }
            }
        }
        .body {
            .peninput {
                margin: 3%;
                color: $orange;
                font-size: 1.2em;
            }
        }
        &__infoBlock {
            overflow: hidden;
            line-height: 1.6em;
            background-color: white;
            padding: 2% 4% 2% 4%;
            border-bottom: 2px solid #e7e7e7;
            margin-bottom: 2%;
            &__logo {
                width: 10%;
                float: left;
                padding-top: 10%;
                background-repeat: no-repeat;
                margin-top: 2%;
                display: none;
            }
            &__info {
                width: 100%;
                overflow: hidden;
                padding-bottom: 1%;
                h2 {
                    color: #2D9CDB;
                    font-size: 2em;
                    font-weight: 300;
                    margin: 0;
                    line-height: 1.6em;
                }
                .text {
                    line-height: 1.6em;
                    margin-bottom: 4%;
                }
                .big {
                    font-size: 1.2em;
                }
            }
        }
        .schedule {
            position: relative;
            .info {
                overflow: hidden;
                font-size: 0.9em;
                margin-bottom: 3em;
                &__text {
                    width: 67%;
                    float: left;
                }
                &__legend {
                    width: 32%;
                    float: left;
                    > div {
                        white-space: nowrap;
                        .icon {
                            width: 2.2em;
                            height: 2.2em;
                            display: inline-block;
                            border-radius: 50%;
                            background: #D0EEFF;
                            border: 1px solid #C4C4C3;
                            vertical-align: middle;
                            margin-right: 0.5em;
                        }
                        .text {
                            width: auto;
                            white-space: normal;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 1;
                            margin: 0;
                        }
                    }
                    &__company {
                        width: 50%;
                        float: left;
                        vertical-align: middle;
                    }
                    &__custom {
                        width: 50%;
                        float: left;
                        div.icon {
                            background: #E9FFD0;
                        }
                    }
                }
            }
            .createPost__infoBlock__logo {
                background-image: url('../../assets/icon-schedule.png');
            }
            &__patterns {
                width: 100%;
                margin-bottom: 2%;
                text-align: center;
                font-size: 0.875em;
                > div {
                    display: inline-block;
                    width: 25%;
                    color: #72AC2F;
                    text-align: center;
                    line-height: 2;
                    cursor: pointer;
                    &.active {
                        border: 1px solid #72AC2F;;
                        border-radius: 1.2em;
                    }
                }
            }
            .datepicker-container {
                margin-bottom: 0;
            }
            .selectMode {
                vertical-align: middle;
                line-height: 2;
                text-align: center;
                margin-bottom: 1%;
                &__title {
                    display: inline-block;
                    width: 25%;
                    color: #7f8c8d;
                    text-align: center;
                    line-height: 2;
                    cursor: pointer;
                    font-size: 1.6em;
                }
                &__link {
                    display: inline-block;
                    width: 25%;
                    color: #72AC2F;
                    text-align: center;
                    line-height: 2;
                    cursor: pointer;
                    &.selected {
                        border: 1px solid;
                        border-radius: 1.2em;
                    }
                    &.clear {
                        color: #EB5757;
                    }
                    &.offDates {
                        color: #7f8c8d;
                    }
                }
                .select {
                    display: inline-block;
                    width: auto;
                    vertical-align: middle;
                    margin-left: 1em;
                    height: 2.2em;

                    .control {
                        border-color: transparent;
                        .arrow {
                            border-color: $orange;
                            color: $orange;
                            border-radius: 50%;
                            border: 1px solid $orange;
                            width: 0.8em;
                            height: 0.8em;
                            top: .7em;
                        }
                        .value {
                            color: $orange;
                            padding-left: 0;
                            margin-right: 2em;
                        }
                    }
                }
            }
            .daySchedule {
                text-align: center;
                margin-bottom: 4%;
                .select {
                    display: inline-block;
                    width: auto;
                    vertical-align: middle;
                    .control {
                        border-color: transparent;
                        border: 0px;
                        .value {
                            padding-left: 0;
                            padding-right: 0;
                            margin-right: 0;
                            color: #6cb1a0;
                            text-decoration: underline;
                            cursor: default;
                        }
                        .arrow {
                            visibility: hidden;
                            right: 50%;
                            margin-right: -0.8em;
                        }
                    }
                }
                .time, .break {
                    width: 50%;
                    display: inline-block;
                    text-align: center;
                    vertical-align: top;
                    font-size: 1.2em;
                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        //background-image: url('../Common/assets/worktime-icon.png');
                        width: 1em;
                        height: 1em;
                        margin-right: 0.5em;
                        vertical-align: middle;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    .breakTrigger {
                        vertical-align: middle;
                        font-size: 1em;
                        input {
                            margin: 0 2% 0 1%;
                            vertical-align: middle;
                        }
                    }
                }
                .break.disabled {
                    span {
                        border-bottom: 1px solid #c7c0c0;
                    }
                }
            }
            .saveSchedule {
                text-align: center;
                &__button {
                    display: inline-block;
                    font-size: 1.2em;
                    line-height: 2.4;
                    border-radius: 1.2em;
                    border: 1px solid #a6a6a6;
                    background-color: #a6a6a6;
                    padding: 0 1em;
                    margin: 0 1em;
                    vertical-align: middle;
                    color: #fff;
                    width: 50%;
                    text-align: center;
                    cursor: pointer;
                    pointer-events: none;
                    &--active {
                        background-color: #1abc9c;
                        border-color: transparent;
                        color: #fff;
                        pointer-events: auto;
                    }
                }
            }
        }
        .divisionLink {
            line-height: 3;
            position: relative;
            z-index: 100;
            .createPost__infoBlock__logo {
                background-image: url('../../assets/icon-res-to-division.png');
            }
            &__link {
                display: inline-block;
                line-height: 2;
                vertical-align: middle;
                color: $orange;
                margin-right: 1em;
                > div {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-image: url('../../assets/delete-icon.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    vertical-align: middle;
                    margin: 0 0 0.1em 0.4em;
                    cursor: pointer;
                    &:hover {
                        background-position: 100%;
                    }
                }
            }
            &__select {
                display: inline-block;
                line-height: 2;
                text-align: left;
                color: #e67e22;
                margin: 0 1em 0 0em;
                cursor: pointer;
                vertical-align: middle;
                .control {
                    border: 1px solid #1abc9c;
                    color: #1abc9c;
                    padding-left: 11%;
                    .arrow {
                        &:before {
                            border-right: 1px solid #1abc9c;
                            border-bottom: 1px solid #1abc9c;
                        }
                    }
                }

            }
        }
        .selectService {
            width: 100%;
            margin-bottom: 2%;
            line-height: 2;
            text-align: center;
            color: #e67e22;
            .select {
                width: auto;
                display: inline-block;
                cursor: pointer;
                .control {
                    padding: 0 50px 0 30px;
                    .placeHolder {
                        white-space: nowrap;
                    }
                }
            }
        }
        .services {
            .createPost__infoBlock__logo {
                background-image: url('../../assets/icon-res-services.png');
            }
            .list {
                margin-bottom: 4%;
            }
            .service {
                white-space: nowrap;
                font-size: 1em;
                line-height: 2.8;
                border-bottom: 1px solid #f2f3f3;
                width: 100%;
                clear: both;

                &__title {
                    display: inline-block;
                    width: 54%;
                    padding-left: 2%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    vertical-align: middle;
                    white-space: normal;
                    line-height: 1.2;
                    margin-right: 1%;
                }
                &__duration {
                    display: inline-block;
                    width: 15%;
                    position: relative;
                    vertical-align: middle;
                    input {
                        border: 1px solid #bec3c3;
                        border-radius: .25em;
                        width: 80%;
                        line-height: 1.5;
                        padding: 1% 3%;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 1rem;
                    }
                    .trigger {
                        width: 1rem;
                        height: 1rem;
                        margin-left: 10px;
                        display: inline-block;
                        background: url("../../assets/editOk.png") left top no-repeat;
                        background-size: contain;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }
                &__price {
                    display: inline-block;
                    width: 20%;
                    position: relative;
                    text-align: right;
                    vertical-align: middle;
                    input {
                        border: 1px solid #bec3c3;
                        border-radius: .25em;
                        width: 80%;
                        line-height: 1.5;
                        padding: 1% 3%;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 1rem;
                        text-align: left;
                    }
                    .trigger {
                        width: 1rem;
                        height: 1rem;
                        margin-left: 10px;
                        display: inline-block;
                        background: url("../../assets/editOk.png") left top no-repeat;
                        background-size: contain;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }
                &__remove {
                    display: inline-block;
                    width: 10%;
                    text-align: right;
                    vertical-align: middle;
                    > div {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-image: url('../../assets/delete-icon.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        vertical-align: middle;
                        margin: 0 0 0.2em 1em;
                        cursor: pointer;
                        &:hover {
                            background-position: 100%;
                        }
                    }
                }
            }
        }
        &-footer {
            text-align: center;
            width: 100%;
            overflow: hidden;
            line-height: normal;
            z-index: 1;
            clear: both;
            padding: 0% 10% 2% 4%;
            &__delete {
                margin: 0;
                color: #EB5757;
                font-size: 0.875em;
                white-space: nowrap;
                text-align: center;
                border-radius: 1.4em;
                width: auto;
                position: relative;
                overflow: hidden;
                line-height: 2.2;
                padding: 0 5.5% 0 5.5%;
                vertical-align: middle;
                border: 1px solid #EB5757;
                cursor: pointer;
                float: left;
            }
            &__save {
                margin: 0;
                color: #EB5757;
                font-size: 0.875em;
                white-space: nowrap;
                text-align: center;
                border-radius: 1.4em;
                width: auto;
                position: relative;
                overflow: hidden;
                line-height: 2.2;
                padding: 0 5.5% 0 5.5%;
                vertical-align: middle;
                border: 1px solid #EB5757;
                cursor: pointer;
                float: right;
                &.disabled {
                    background-color: #e8e8e8;
                    color: #a6a6a6;
                    border-color: #a6a6a6;
                    cursor: not-allowed;
                }
            }
            &__cancel {
                color: #59b7ec;
                line-height: normal;
                padding: 0 5%;
                line-height: 2.2;
                text-decoration: none;
                vertical-align: middle;
                float: right;
                cursor: pointer;
            }
        }
    }
}
