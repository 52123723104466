:global {
  .cmt-pager {
    width: auto;
    text-align: center;
    padding: 1% 0 1% 0;
    white-space: nowrap;
    &__page {
      display: inline-block;
      position: relative;
      padding: 0;
      line-height: 1.42857143;
      text-decoration: none;
      color: #8db6cf;
      background-color: #fff;
      border: 1px solid #ddd;
      overflow: hidden;
      margin-right: -1px;
      &:first-child {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
      a {
        color: black;
        display: inline-block;
        width: 100%;
        padding: 4px 20px;
      }
      &:hover {
        background-color: #e8e8e8;
      }
      &.active {
        background-color: #e8e8e8;
      }
    }
  }
}