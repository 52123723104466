:global {
  .orderfooter {
    position: relative;
    min-height: 300px;
    &--hidden {
      height: 0;
      min-height: 0 !important;
      overflow: hidden;
    }
  }
  .footer-fixed-header {
    position: fixed !important;
    left: 0;
    top: 0;
    height: 63px;
    z-index: 1000 !important;
    margin: 0 !important;
  }
  #footerheader {
    &.footer-fixed-header {
      top: 0px;
      background-color: rgba(17, 42, 66, .8);
    }
    background-color: #e9e9e9;
  }
  #toOrder {
    &:empty {
      display: none;
    }
    &.footer-fixed-header {
      width: 100%;
      height: 80px;
      top: 63px;
      background-color: rgba(17, 42, 66, .8);
    }
    .editOrder {
      position: relative;
      height: 100%;
      .wrapper {
        a {

        }
      }
    }
    width: 0;
    height: 0;
    overflow: hidden;
    margin: auto;
    text-align: center;
    position: relative;
    display: block;
    font-size: 1.4em;
    line-height: 1.8;
    background-color: rgba(0, 0, 0, .4);
    -webkit-transition: background-color 0.5s ease-out 0.0s;
    -moz-transition: background-color 0.5s ease-out 0.0s;
    -o-transition: background-color 0.5s ease-out 0.0s;
    transition: background-color 1.5s ease-out 0.0s;
    .wrapper {
      position: relative;
      padding: 0;
      max-width: 1188px;
      margin: auto;
      height: 100%;
      //элемент для вертикального выравнивания
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
      .text {
        display: inline-block;
        vertical-align: middle;
        color: white;
        span {
          color: white;
          display: inline-block;
          vertical-align: bottom;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis
        }
      }
      .cancel {
        width: 20%;
        color: #fff;
        font-size: 1em;
        text-align: center;
        line-height: 1.8;
        border-radius: 2em;
        border: 1px solid white;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}
.takeOffSubmit {
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  background-color: #67b6f8;
  color: #fff;
  font-size: 1.2em;
  line-height: 2.1;
  border-radius: 2em;
  top: 43px;
  left: -107px;
  padding: 0 1.7em 0 1.7em;
  white-space: nowrap;
  cursor: pointer;
  &:before {
    position: absolute;
    top:0;
    margin-top: -0.6em;
    left: 50%;
    margin-left: -0.3em;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.6em 0.6em 0.6em;
    border-color: transparent transparent #67b6f8 transparent;

  }
}
.order {
  width: 1188px;
  margin: auto;
  text-align: center;
  position: relative;
  .wrapper {
    .text {

      span {

      }
    }
    a {

    }
  }
}

.editOrder {
  .wrapper {
    .text {
      span {
      }
    }
    a {

    }
  }
}

.resfooter {
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
  padding-top: 60px;
  &.night {
  }
  .gradient {
    z-index: 2;
    width: 1188px;
    height: 100%;
    position: absolute;
    left: 0;
    margin-left: 0;
    background: #c36178; /* Old browsers */
    background: -moz-linear-gradient(left, #c36178 0%, #febb62 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #c36178 0%, #febb62 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #c36178 0%, #febb62 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c36178', endColorstr='#febb62', GradientType=1); /* IE6-9 */
    &.night {
      background: #4b535d; /* Old browsers */
      background: -moz-linear-gradient(left, #4b535d 0%, #564c72 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #4b535d 0%, #564c72 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #4b535d 0%, #564c72 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4b535d', endColorstr='#564c72', GradientType=1); /* IE6-9 */
    }
    @media (min-width: 1188px) {
      left: 50%;
      margin-left: -594px;
    }
  }
  .headbgrDummy {
    height: 60px;
  }
  .headbgr {
    background-color: rgba(0, 0, 0, 0.25);
    position: relative;
    min-width: 1188px;
    width: 100%;
    margin: -60px auto 0 auto;
    left: 0;
    top: 0;
    z-index: 11;
    min-height: 60px;
    -webkit-transition: background-color 0.5s ease-out 0.0s;
    -moz-transition: background-color 0.5s ease-out 0.0s;
    -o-transition: background-color 0.5s ease-out 0.0s;
    transition: background-color 0.5s ease-out 0.0s;
    &.over {
      z-index: 12;
    }
  }
  .wrapper {
    z-index: 10;
    width: 100%;
    max-width: 1188px;
    min-width: 1188px;
    height: 100%;
    margin: auto;
    position: relative;
    clear: both;
    &.current {
      z-index: 20;
    }
  }
  .head {
    width: 100%;
  }
  .date {
    width: 24.4%;
    float: left;
    padding-top: 1%;
    padding-bottom: 1%;
    color: #e7c319;
    line-height: 2;
    padding-left: 1%;
    vertical-align: middle;
    span {
      text-transform: capitalize;
      color: #a8a3a3;
    }
    a.dpSwitcher {
      display: inline-block;
      background-image: url('../OrderFooter/assets/date-switch.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 5.8%;
      vertical-align: middle;
      &:before {
        padding-top: 100%;
        content: '';
        display: block;
      }
    }
    .datePicker {
      display: inline-block;
      position: relative;
      overflow: visible;
      &.hidden {
        display: none;
      }
      > div > div {
        position: absolute;
        width: 284px;
        left: -150px;
        top: 20px;
      }
    }
  }
    .pastAllow {
        width: 24.4%;
        float: left;
        padding-top: 1%;
        padding-bottom: 1%;
        color: #a8a3a3;
        line-height: 2;
        padding-left: 1%;
        vertical-align: middle;
        cursor: pointer;
        &.active {
            color: #5499d3;
        }
    }

    .dayTimes {
    padding-top: 1.2%;
    padding-bottom: 1%;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    > div {
      color: #5499d3;
      font-size: 1em;
      text-align: center;
      line-height: 2;
      border: solid 1px transparent;
      border-radius: 2em;
      display: inline-block;
      min-width: 195px;
      margin-left: 1.5%;
      margin-right: 1.5%;
      overflow: hidden;
      padding: 0 15px 0 30px;
      cursor: pointer;
      div {
        position: relative;
        max-width: 195px;
        //overflow: hidden;
        //text-overflow: ellipsis;
        &:before {
          content: '';
          background-size: contain;
          padding-right: 30px;
        }
        &.day {
          &:before {
            background: url('../OrderFooter/assets/day-icon.png') no-repeat left center;
          }
        }
        &.night {
          &:before {
            background: url('../OrderFooter/assets/night-icon.png') no-repeat left center;
          }
        }
      }
      &.active {
        border: solid 1px #5499d3;
        border-radius: 2em;
        background-color: white;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border: solid 1px white;
        border-radius: 2em;
      }
      //&:before {
      //  padding-right: 15%;
      //  background: url('../OrderFooter/assets/day-icon.png') no-repeat 30% 15%;
      //  background-size: 60%;
      //  display: inline-block;
      //  vertical-align: middle;
      //  padding-top: 10%;
      //  //padding-left: 2%;
      //  //padding-top: 10%;
      //  //background-size: cover;
      //  content: '';
      //}
    }
  }
  .schedules {
    padding-top: 3.6%;
    padding-bottom: 1%;
    .divisionTitle {
      &:before {
        content: '';
        float: left;
        display: block;
        padding-top: 40%;
      }
      float: left;
      font-size: 1.8em;
      color: #77ad3a;
      width: 17.2%;
      line-height: 1;
      background: url("../OrderFooter/assets/resbgr-first.png") no-repeat left top;
      background-size: contain;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .timePeriods {
      width: 82.4%;
      float: left;
      color: #dedede;
      overflow: hidden;
      white-space: nowrap;
      padding-top: 0%;
      div {
        width: 23.4%;
        text-align: center;
        line-height: 1.7;
        font-size: 1.5em;
        display: inline-block;
        &.half {
          width: 16.1%;
        }
        &:first-child {
          margin-left: -2%;
          width: 16.1%;
        }
        &:last-child {
          width: 16.1%;
        }
      }
    }
    .timeHours {
      float: left;
      width: 75%;
      color: #6fa930;
      white-space: nowrap;
      div {
        width: 8.2%;
        font-size: 1em;
        line-height: 1.7;
        display: inline-block;
        &:nth-child(3n-1) {
          margin-right: 1%;
        }

      }
    }
  }
  .resource {
    clear: both;
    margin: 0;
    width: 100%;
    height: 100%;
    background-image: url(../OrderFooter/assets/resbgr.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    position: relative;
    &.active {
      //z-index: 2;
    }
    .line {
      position: absolute;
      top: 0%;
      height: 100%;
      width: 1px;
      background-color: #e6e6e6;
      z-index: 0;
      &.first {
        left: 29.2%;
      }
      &.second {
        left: 48.4%;
      }
      &.third {
        left: 67.4%;
      }
      &.fourth {
        left: 86.7%;
      }
    }
    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden; //не отображаем
      line-height: 0; //высоту блока и строки сводим на ноль, чтобы не было видно этого блока
      height: 0;
    }
    &:last-child {
      background-image: url("../OrderFooter/assets/resbgr-last.png");
      .line {
        height: 45%;
      }
    }
    .title {
      width: 16%;
      padding-bottom: 2.7%;
      float: left;
      padding-left: 3%;
      color: #72aad9;
      overflow: visible;
      margin-right: 1.2%;
      text-overflow: ellipsis;
      position: relative;
      &:hover {
        .tooltip {
          display: block;
        }
      }
      .name {
        font-size: 1em;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden;
        color: #72aad9;
      }
      .description {
        display: block;
        font-size: 0.8em;
        line-height: 1;
        color: #bfbcbc;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .tooltip {
        position: absolute;
        left: -2%;
        bottom: 110%;
        width: auto;
        background-color: white;
        color: #ff7113;
        text-align: center;
        padding: 6% 4% 6% 4%;
        -moz-box-shadow: 0 0 10px #000;
        -webkit-box-shadow: 0 0 10px #000;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: none;
        white-space: nowrap;
        z-index: 2;
        &.hidden {
          display: none !important;
        }
        span {
          &:nth-child(1) {

          }
          &:nth-child(2) {
            color: #1abc9c;
          }
          &:nth-child(3) {
            display: none;
          }
        }
        &:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid white;
          position: absolute;
          top: 100%;
          left: 1.5em;
          margin-left: -8px;
        }
      }
    }
    .chunks {
      float: left;
      white-space: nowrap;
      transition: opacity 0.2s ease-out 0.1s;
      z-index: 1;
      position: relative;
      &.loading {
        opacity: 0.2;
      }
      .chunk {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 2px;
        border: solid 1px #7eb2de;
        position: relative;
        cursor: pointer;
          &.inpast {
              background-color: #e4e4e4;
              border: solid 1px #d8d8d8;
              cursor: default;
              &:hover {
                  background-color: #d6e590;
                  border: solid 1px #dcffad;
              }
          }
        //выбранные чанки в режиме отключения/включения времени
        &.takeOff {
          -webkit-box-shadow: 0 0 4px 1px #67b6f8;
          -moz-box-shadow: 0 0 4px 1px #67b6f8;
          box-shadow: 0 0 4px 1px #67b6f8;
        }


        &.active, &:hover {
          background-color: #d6e590;
          border: solid 1px #dcffad;
        }
        &.orderOn {
          background-color: #bafd99;
          border: solid 1px #6de956;
          &.odd {
            background-color: #99e076;
            border: solid 1px #47bf7b;
          }
          &.activeClient {
            -webkit-box-shadow: 0 0 5px 2px rgba(201,229,79,1);
            -moz-box-shadow: 0 0 5px 2px rgba(201,229,79,1);
            box-shadow: 0 0 5px 2px rgba(201,229,79,1);
          }
          &:hover {
            background-color: #d6e590;
            border: solid 1px #dcffad;
          }
          .tooltip {
            span:nth-child(3) {
              display: block;
            }
          }
        }
        &.reserveOn {
          background-color: #fdf46f;
          border: solid 1px #eeb850;
          &.odd {
            background-color: #f2e96d;
            border: solid 1px #eeb850;
          }
          &.activeClient {
            -webkit-box-shadow: 0 0 4px 1px rgba(243, 229, 116, 1);
            -moz-box-shadow: 0 0 4px 1px rgba(243, 229, 116, 1);
            box-shadow: 0 0 4px 1px rgba(243, 229, 116, 1);
          }
          &:hover {
            background-color: #d6e590;
            border: solid 1px #dcffad;
          }
          .tooltip {
            span:nth-child(3) {
              display: block;
            }
          }
        }
        &.unactive {
          background-color: #e4e4e4;
          border: solid 1px #d8d8d8;
          cursor: default;
          &:hover {
            background-color: rgba(255, 113, 19, 0.6);
          }
        }
        &.alert {
          background-color: rgba(236, 63, 63, 0.6);
          border: solid 1px #c56220;
          &:hover {
            background-color: rgba(236, 63, 63, 0.6);
          }
        }
        &.replaceOrderFromHere {
          background-color: #fbe57f;
          border: solid 1px #fbff00;
        }
        &:nth-child(odd) {
          margin-left: 2.4%;
          margin-right: 1%;
        }
        &:nth-child(6n-2) {
          margin-right: 1.8%;
        }
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          .tooltip {
            display: block;
          }
        }
        .tooltip {
          position: absolute;
          left: -290%;
          bottom: 150%;
          width: auto;
          background-color: white;
          color: #ff7113;
          text-align: center;
          padding: 40% 80% 40% 80%;
          -moz-box-shadow: 0 0 10px #000;
          -webkit-box-shadow: 0 0 10px #000;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          display: none;
          span {
            &:nth-child(1) {

            }
            &:nth-child(2) {
              color: #1abc9c;
            }
            &:nth-child(3) {
              display: none;
            }
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid white;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -8px;
          }
        }
      }
    }
  }

}
