@import '../../../styles/themes/default/colors.scss';

$selected-color: #e67e22;
$orange-color: #e67e22;
:global {
  .settings {
    width: 1186px;
    margin: auto;
    background-color: white;
    overflow: hidden;
    color: #485051;
    margin-top: 42px;
    position: relative;

    &__submit {
      color: #fff;
      font-size: 1.1em;
      text-align: center;
      line-height: 2.4;
      background-color: #1abc9c;
      border-radius: 2em;
      display: block;
      width: 200px;
      margin: 2% auto 2% auto;
      cursor: pointer;

      &.hidden {
        display: none;
      }

      &.disabled {
        border: 1px solid #a6a6a6;
        background-color: #a6a6a6;
        color: #fff;
        pointer-events: none;
      }


      /*            display: inline-block;
                  font-size: 1.2em;
                  line-height: 2.4;
                  border-radius: 1.2em;
                  border: 1px solid #a6a6a6;
                  background-color: #a6a6a6;
                  padding: 0 1em;
                  margin: 0 1em;
                  vertical-align: middle;
                  color: #fff;
                  width: 50%;
                  text-align: center;
                  cursor: pointer;
                  pointer-events: none;
                  &--active {
                      background-color: #1abc9c;
                      border-color: transparent;
                      color: #fff;
                      pointer-events: auto;
                  }*/

    }

    &-block {
      padding: 1.4% 0 0 0;
      width: 1146px;
      margin: auto;

      hr.margin-bottom {
        margin-bottom: 8%;
      }

      &__h1 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        line-height: 1.6;
        font-size: 2.25em;
        color: #2D9CDB;
        margin: 0% 0 4% 0;

        .showServices {
          font-size: 1rem;
          line-height: 3;
          color: #1abc9c;
          float: right;
          white-space: nowrap;
          cursor: pointer;
          /*                    &:before {
                                  content: '';
                                  display: inline-block;
                                  width: 19px;
                                  height: 19px;
                                  background-image: url('components/Spec/assets/expand.png');
                                  background-repeat: no-repeat;
                                  background-size: cover;
                                  margin-right: 1%;
                              }*/
          &.active {
            color: $selected-color;
          }
        }
      }

      &__h2 {
        font-size: 1.7em;
        font-weight: normal;
        color: #9fa9aa;
        line-height: 1.2;
        margin: 0 0 4.9% 0;
      }

      &--common {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        .peninput {
          font-size: 2em;
          color: $selected-color;
          margin: 1em 0 1.2em 0;
        }
      }

      &--posts {
        color: #7f8c8d;

        .division {
          margin-bottom: 4%;

          &__h1 {
            font-size: 1.5em;
            font-weight: normal;
            line-height: 1.8;

          }

        }
      }

    }

    .subBlock {
      width: 100%;
      margin: 2% 0 6% 0;

      .error {
        color: red;
        line-height: 1.6;
        font-size: 0.725em;
        margin: 0.4em 0 0.4em 0;

        &:empty {
          display: none;
        }
      }

      &.editing {
        .error {
          display: none;
        }
      }
    }

    /*        .common-block {
                width: 50%;
                overflow: hidden;
                &__avatar {
                    width: 32%;
                    float: left;
                    margin-right: 4%;
                    .wrapper {
                        width: 100%;
                        padding-top: 100%;
                        border: 1px solid #1abc9c;
                        box-sizing: content-box;
                        position: relative;
                        overflow: hidden;
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: auto;
                        }
                        .slim {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .avatar__text {
                        text-align: center;
                        position: absolute;
                        top: 35%;
                        width: 100%;
                        height: 30%;
                        line-height: 100%;
                        font-size: 1em;
                        color: #dadcdc;
                    }
                }
                &__fields {
                    width: 60%;
                    float: left;
                    label {
                        display: block;
                        color: #1abc9c;
                        text-align: left;
                        margin-right: 2.5%;
                        vertical-align: top;
                        font-size: .9em;
                        line-height: 1.8;
                    }
                    input {
                        margin-bottom: 1%;
                    }
                }
            }
            .workdays-block {
                .settings-block__h2 {

                }
                width: 50%;
                &__days {
                    margin-top: -3%;
                    vertical-align: top;
                    &:before {
                        display: inline-block;
                        height: 100%;
                        //background-image: url('components/Common/assets/diary-icon.png');
                        background-size: contain;
                        content: '';
                        width: 7%;
                        width: 37px;
                        margin-right: 3.5%;
                        padding-top: 10.5%;
                        vertical-align: top;
                    }
                }
                &__day {
                    display: inline-block;
                    color: #1abc9c;
                    width: 5.6%;
                    width: 29px;
                    text-align: center;
                    margin-right: 2.5%;
                    vertical-align: top;
                    font-size: 0.9em;
                    line-height: 1.8;
                    cursor: pointer;
                    &:nth-child(6) {
                        margin-left: 4%;
                    }
                    &:nth-child(6), &:nth-child(7) {
                        color: $selected-color;
                    }
                    &.is_on {
                        &:after {
                            //background-image: url('components/Common/assets/is-on.png');
                            background-position: center center;
                            background-repeat: no-repeat;
                        }
                    }
                    &:after {
                        content: '';
                        color: transparent;
                        border: 1px solid #bec3c3;
                        display: block;
                        width: 100%;
                        height: 50%;
                        padding-top: 100%;
                        box-sizing: content-box;
                        border-radius: 4px;
                    }
                }
            }
            .worktime-block {
                width: 50%;
                &__time {
                    margin-top: -3%;
                    color: #1abc9c;
                    position: relative;
                    vertical-align: top;
                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        //background-image: url('components/Common/assets/worktime-icon.png');
                        width: 6.5%;
                        padding-top: 6.2%;
                        vertical-align: top;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    input {
                        width: 8%;
                        padding: 1% 2%;
                        text-align: center;
                        color: #818788;
                        margin: 0 0.6% 0 0.6%;
                    }
                }
            }
            .breaktime-block {
                width: 50%;
                &__time {
                    margin-top: -3%;
                    color: #1abc9c;
                    position: relative;
                    vertical-align: top;
                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        //background-image: url('components/Common/assets/breaktime-icon.png');
                        width: 6.5%;
                        padding-top: 6.2%;
                        vertical-align: top;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    input {
                        width: 8%;
                        padding: 1% 2%;
                        text-align: center;
                        color: #818788;
                        margin: 0 0.6% 0 0.6%;
                    }
                }
                &__switch {
                    margin-left: 12%;
                    font-size: 1em;
                    line-height: 2.4;
                    input {
                        margin: 0 2% 0 1%;
                    }
                }
            }
            .booking-block {
                width: 50%;
                &__days {
                    margin-top: -3%;
                    color: #1abc9c;
                    position: relative;
                    vertical-align: top;
                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        //background-image: url('components/Common/assets/worktime-icon.png');
                        width: 6.5%;
                        padding-top: 6.2%;
                        vertical-align: top;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    input {
                        width: 8%;
                        padding: 1% 2%;
                        text-align: center;
                        color: #818788;
                        margin: 0 0.6% 0 0.6%;
                    }
                }
            }
            .specialization-block {
                overflow: hidden;
                position: relative;
                &:after {
                    content: '';
                    display: block;
                }
                &__node {
                    margin: 0 0 2%;
                    overflow: hidden;
                }
                &__header {
                    font-size: 1em;
                    font-weight: 400;
                    color: #1abc9c;
                    line-height: 1.2;
                    margin: 0 0 1%;
                    vertical-align: middle;
                     &:before {
                        content: '';
                        display: inline-block;
                        height: 19px;
                    }
                    &:hover {
                        color: #00e5ca;
                    }
                    > div {
                        margin-left: 2em;
                    }
                }
                &__link {
                    color: #485051;
                    display: block;
                    font-size: 1em;
                    line-height: 2;
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 19px;
                        height: 19px;
                        background-image: url('components/Spec/assets/checkbox-icon.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-right: 1%;
                    }
                    &:hover {
                      cursor: pointer;
                      color: $selected-color;
                    }
                    &.active {
                        &:before {
                            background-position: 100% top;
                        }
                    }
                    > div {
                        margin-left: 2em;
                    }
                    .catalogueName {
                        display: inline-block;
                        margin-left: 0;
                        &:hover {
                            .addService {
                                opacity: 1;
                            }
                        }
                        .addService {
                            display: inline-block;
                            vertical-align: middle;
                            color: $selected-color;
                            margin-left: 1em;
                            opacity: 0;
                            text-decoration: underline;
                        }

                    }
                    .catalogueServices {
                        &.hidden {
                            display: none;
                        }
                        &__item {

                            color: $selected-color;
                            &.custom {
                                color: #1abc9c;
                            }
                        }
                        &__tmplItem {
                            color: $primary-color;
                            position: relative;
                            font-size: 0.8em;
                            margin: 0;
                            padding: 0.4em 0 0.4em 0.4em;
                            border-bottom: 1px solid #f3f3f3;
                            cursor: pointer;
                            line-height: 1.2;
                            &.custom {
                                color: #1abc9c;
                            }
                            &:hover {
                                background-color: #f2f5f6;
                            }
                            .name{
                                display: inline-block;
                                vertical-align: middle;
                                width: 60.3%;
                            }
                        }
                        &__item {
                            position: relative;
                            font-size: 0.8em;
                            margin: 0;
                            padding: 0.4em 0 0.4em 0.4em;
                            border-bottom: 1px solid #f3f3f3;
                            cursor: pointer;
                            line-height: 1.2;
                            &:hover {
                                background-color: #f2f5f6;
                            }
                            .name{
                                display: inline-block;
                                vertical-align: middle;
                                width: 60.3%;
                            }
                            .duration {
                                display: inline-block;
                                vertical-align: middle;
                                width: 16%;
                                text-align: right;
                            }
                            .price {
                                display: inline-block;
                                vertical-align: middle;
                                width: 19%;
                                text-align: right;
                            }
                            .text {
                                display: inline-block;
                                color: #1abc9c;
                            }
                            .warning {
                                display: inline-block;
                                overflow: visible;
                                vertical-align: middle;
                                margin-left: 1em;
                                &:hover {
                                }
                            }
                            .warnIcon {
                                position: relative;
                                padding: 0 8px;
                                border: 1px solid #ce252c;
                                border-radius: 50%;
                                font-weight: 600;
                                color: #ce252c;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .division-block {
                &__division {
                    vertical-align: middle;
                    font-size: 1em;
                    line-height: 2.2;
                    .remove {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-image: url('components/Spec/assets/delete-icon.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        vertical-align: middle;
                        cursor: pointer;
                        &:hover {
                            background-position: 100% top;
                        }
                    }
                }
                .addDivision {
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    &__button {
                        color: $selected-color;
                        display: inline-block;
                        font-size: 1em;
                        border: 1px solid $selected-color;
                        line-height: 2;
                        border-radius: 1.2em;;
                        padding: 0 8% 0 8%;
                        vertical-align: middle;
                        white-space: nowrap;
                        width: auto;
                        cursor: pointer;
                    }
                    input {
                        border: 1px solid #bec3c3;
                        border-radius: .25em;
                        width: 80%;
                        line-height: 1.5;
                        padding: 1% 3%;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 1rem;
                        margin-right: 4%;
                    }
                    &__trigger {
                        margin-right: 4%;
                        cursor: pointer;
                        &--save {
                            width: 1rem;
                            height: 1rem;
                            display: inline-block;
                            background: url('components/Spec/assets/editOk.png') left top no-repeat;
                            background-size: contain;
                            vertical-align: middle;
                        }
                        &--cancel {
                            width: 1rem;
                            height: 1rem;
                            display: inline-block;
                            background: url('components/Spec/assets/delete-icon.png') left top no-repeat;
                            background-size: cover;
                            vertical-align: middle;
                        }
                    }
                }
                .noDivisions {
                    padding: 0 0 40px 10px;
                }
            }
            .no-client-free-time-block {
                margin-top: -3%;
                &:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                   //background-image: url('components/Common/assets/worktime-icon.png');
                    width: 6.5%;
                    padding-top: 6.2%;
                    vertical-align: top;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .workdays-block__day {
                    width: 33px;
                    margin-left: 0.6%;
                }
            }

            .h1__businessType {
                color: $selected-color;
                display: inline-block;
                font-size: 0.5em;
                border: 1px solid $selected-color;
                line-height: 2;
                border-radius: 1.2em;;
                padding: 0 2% 0 2%;
                vertical-align: middle;

            }*/

    &-menu {
      width: 100%;
      height: auto;
      background-color: #9DA2A4;
      color: #686868;
      font-size: 0.875em;
      line-height: 1.3;
      white-space: nowrap;
      &__link {
        display: inline-block;
        width: 25%;
        background-repeat: no-repeat;
        background-position: 16% center;
        border-right: 1px solid #7a8183;
        background-size: 13%;
        position: relative;

        &:last-child {
          border-right: none;
        }

        span {
          color: #686868;
          text-align: left;
          display: block;
          padding: 9.5% 6% 9.5% 34%;
          max-height: 72px;
          width: 100%;
        }

        &--common {
          background-image: url(../Main/assets/icon--common.svg);
        }

        &--services {
          background-image: url(../Main/assets/icon--services.svg);
        }

        &--spec {
          background-image: url(../Main/assets/icon-specs.png);
        }

        &--resources {
          background-image: url(../Main/assets/icon--posts.svg);
        }

        &--cpro {
          background-image: url(../Main/assets/icon--connectpro.svg);
        }

        &.active, &:hover {
          background-color: white;

          &:after {
            content: '';
            position: absolute;
            bottom: 1%;
            width: 80%;
            height: 1px;
            background-color: #C5C3C3;
            left: 10%;
          }
        }
      }

    }

    .black-wrapper {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      z-index: 1002;
      top: 0;
      left: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
