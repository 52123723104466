@import '../../../../styles/themes/default/colors.scss';

.question {
  position: fixed;
  background-color: rgba(0, 0, 0, .8);
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  .content {
    position: relative;
    width: 690px;
    max-height: 100%;
    overflow: auto;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    background-color: #fff;
    .header {
      background-color: #f6f6f6;
      color: $h-color;
      font-size: 2.2em;
      line-height: 2.9;
      padding-left: 6.4%;
      letter-spacing: 0.015em;
    }
    .body {
      color: $grey-color;
      padding: 5.5% 7% 6.4%;
      .title {
        font-size: 1.5em;
      }
      textarea {
        border: 1px solid #bec3c3;
        border-radius: .25em;
        font-size: 1.1em;
        color: #485051;
        width: 100%;
        margin: 3% 0 10%;
        padding: 2% 2% 3.5%;
        resize: none;
      }
      .answer {
        padding-top: 1.6%;
        font-size: 1.1em;
        >div {
          display: inline-block;
        }
      }
      .btnContainer {
        text-align: right;
        margin-top: 19.9%;
        font-size: 1.1em;
        .cancel {
          display: inline-block;
          color: #229fe6;
          margin-right: 1.7%;
          cursor: pointer;
        }
        .button {
          display: inline-block;
          background-color: grey;
          color: #fff;
          padding: 1.6% 6.4% 2.0%;
          border-radius: 2em;
          text-align: center;
          cursor: not-allowed;
          &.active {
            background-color: $h-color;
            cursor: pointer;
          }
          &.archive {
            float: left;
            cursor: pointer;
            background-color: white;
            border: 1px solid #c6272b;
            color: #c6272b;
          }
        }
      }
    }
  }
  .orange {
    color: $orange;
  }
  .green {
    color: $h-color;
  }
}
