:global {
  .html__header {
    height: 42px;
    width: 100%;
    min-width: 1188px;
    color: white;
    position: absolute;
    top: 0;
    z-index: 1000;
    background-color: #8b8b8b;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: height 0.4s ease-out 0.1s;
    -moz-transition: height 0.4s ease-out 0.1s;
    -o-transition: height 0.4s ease-out 0.1s;
    transition: height 0.4s ease-out 0.1s;
    overflow: hidden;
    &:before {
      position: absolute;
      width: 100%;
      height: 42px;
      content: '';
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }
    &-wrapper {
      width: 100%;
      max-width: 1188px;
      height: 100%;
      margin: auto;
      position: relative;
      &__logo {
        height: 40px;
        width: 14%;
        position: absolute;
        color: white;
        top: 3px;
        left: 8px;
        font-size: 1.1em;
        cursor: pointer;
        &__hamburger {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          width: 14%;
          height: 100%;
          margin-right: 10%;
          &:hover {
            span {
              background-color: #c88909;
            }
          }
          span {
            background-color: white;
            width: 100%;
            height: 1px;
            display: block;
            position: absolute;
            top: 18px;
            left: 0;
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-transition: background-color 0.2s ease-out;
            -moz-transition: background-color 0.2s ease-out;
            -o-transition: background-color 0.2s ease-out;
            transition: background-color 0.2s ease-out;
            &:first-child {
              top: 13px;
            }
            &:last-child {
              top: 23px;
            }
            &.active {
              background-color: #c88909;
            }
          }
        }
        &__home {
          color: white;
          white-space: nowrap;
          span {
            color: #1ba085;
          }
        }
      }
      &__links {
        position: absolute;
        top: 3px;
        right: 1%;
        text-align: right;
        min-width: 75%;
        &__link {
          display: inline-block;
          text-align: left;
          padding-left: 3.5%;
          width: auto;
          font-size: 1.1em;
          color: white;
          line-height: 2;
          background-size: auto 200%;
          background-position: left top;
          background-repeat: no-repeat;
          margin-left: 2%;
          cursor: pointer;
          &:hover, &.active {
            background-position: left 100%;
            color: #c88909;
          }
          &--callcenter {
            background-image: url(../Header/assets/callcenter-icon.png);
          }
          &--clients {
            background-image: url(../Header/assets/clients-icon.png);
          }
          &--orders {
            background-image: url(../Header/assets/orders-icon.png);
          }
          &--statistics {
            background-image: url(../Header/assets/orders-icon.png);
          }
          &--feedback {
            background-image: url(../Header/assets/icon-backlink.png);
          }
        }
      }
      &__lists {
        position: absolute;
        height: 100px;
        width: 600px;
        left: 0.6%;
        top: 57px;
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-transition: opacity 0.4s ease-out 0.1s;
        -moz-transition: opacity 0.4s ease-out 0.1s;
        -o-transition: opacity 0.4s ease-out 0.1s;
        transition: opacity 0.4s ease-out 0.1s;
        &.hidden {
          opacity: 0;
        }

        .list {
          position: relative;
          width: 50%;
          font-size: 1em;
          color: #ffffff;
          float: left;
          &__block {
            display: inline-block;
            vertical-align: top;
          }
          &__title {
            position: relative;
            padding-left: 30px;
            width: auto;
            font-size: 1.1em;
            color: white;
            line-height: 2;
            background-size: auto 65%;
            background-position: left 6px;
            background-repeat: no-repeat;
            cursor: default;
            &--account {
              background-image: url(../Header/assets/account-icon.png);
            }
            &--settings {
              background-image: url(../Header/assets/settings-icon.png);
            }
          }
          &__link {
            white-space: nowrap;
            display: block;
            margin-left: 20px;
            position: relative;
            color: white;
            font-size: 0.9em;
            line-height: 1.6;
            cursor: pointer;
            &:before {
              content: '';
              position: relative;
              width: 4px;
              height: 4px;
              margin-right: 16px;
              background-color: rgba(255, 255, 255, 0.5);
              -moz-border-radius: 50%;
              -webkit-border-radius: 50%;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
            }
            &__account {
              &--logout {
                &:before {
                  content: '';
                  position: relative;
                  width: 13px;
                  height: 13px;
                  margin-right: 8px;
                  background-color: transparent;
                  background-image: url(../Header/assets/icon-exit.png);
                  background-repeat: no-repeat;
                  vertical-align: middle;
                  border-radius: 0;
                }
                &:hover, &.active {
                  &:before {
                    background-color: transparent !important;
                  }
                }
              }
            }
            &:hover, &.active {
              color: #c88909;
              &:before {
                background-color: rgba(200, 137, 9, 0.5);
              }
            }
          }
        }
      }
    }
  }
  .fullHeader {
    @extend .html__header;
    height: 211px;
  }
}

