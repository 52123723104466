:global {
  .club-datepicker {
    color: #5499d3;
    white-space: nowrap;
    vertical-align: middle;
    &.disabled {
      color: #a6a6a6 !important;

        .dpSwitcher {
          pointer-events: none;
          border-color: #a6a6a6 !important;
          &:before {
            border-color: #a6a6a6 !important;
          }
        }

    }
    &__leftArrow {
      display: inline-block;
      position: relative;
      width: 2em;
      height: 2em;
      color: #5499d3;
      vertical-align: middle;
      cursor: pointer;
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 25%;
        width: 25%;
        left: 40%;
        top: 40%;
        border-top: none;
        border-right: solid 1px;
        border-left: none;
        border-bottom: solid 1px;
        background-color: transparent;
        transform: rotate(135deg);
      }
    }
    &__rightArrow {
      display: inline-block;
      position: relative;
      width: 2em;
      height: 2em;
      color: #5499d3;
      vertical-align: middle;
      cursor: pointer;
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 25%;
        width: 25%;
        left: 40%;
        top: 40%;
        border-top: none;
        border-right: solid 1px;
        border-left: none;
        border-bottom: solid 1px;
        background-color: transparent;
        transform: rotate(-45deg);
      }
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      &.active {
        cursor: pointer;
      }
    }
    .dpSwitcher {
      cursor: pointer;
      display: inline-block;
      width: 1em;
      height: 1em;
      position: relative;
      border-radius: 50%;
      border: 1px solid;
      margin-left: 0.2em;
      vertical-align: middle;
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 40%;
        width: 40%;
        left: 30%;
        top: 15%;
        border-top: none;
        border-right: solid 1px;
        border-left: none;
        border-bottom: solid 1px;
        background-color: transparent;
        transform: rotate(45deg);
        transition: all 0.2s ease 0s;
      }
    }
    &.orange {
      color: #ff7113;
      .dpSwitcher {
        border-color: #ff7113;
        &:after {
          border-color: #ff7113;
        }
      }
    }
    //весь заголовок оранжевый
    &.full-orange {
      .text {
        color: #ff7113
      }
      .dpSwitcher {
        border-color: #ff7113;
        &:after {
          border-color: #ff7113;
        }
      }
      .club-datepicker__rightArrow, .club-datepicker__leftArrow {
        color: #ff7113;
      }
    }
    //без переключателей в заголовке
    &.no-header-arrows {
      .club-datepicker__rightArrow, .club-datepicker__leftArrow {
        display: none;
      }
    }
    &.white {
      .dpSwitcher {
        border-color: #5499d3;
        background-color: white;
        &:after {
          border-color: #5499d3;
        }
      }
    }
    &.inline {
      display: inline-block;
      line-height: inherit;
      vertical-align: middle;
      margin: 0 1em 0 1em;
      .club-datepicker__leftArrow, .club-datepicker__rightArrow {
        display: none;
      }
    }
  }
  .datePicker-wrapper {
    position: absolute;
    &.hidden {
      display: none;
    }
  }

  .react-datepicker {
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #e98c3a;
    border-radius: 0;
    padding-bottom: 10%;
    font-family: Open Sans;
    position: absolute;
    width: 284px;
    top: 12px;
    right: -142px;

    &:before {
      content: '';
      height: 17%;
      width: 100%;
      background-color: #f7f7f7;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  .react-datepicker__current-month {
    color: #e88e3e;
    text-transform: capitalize;
    text-align: left;
    font-size: 1.6em;
    line-height: 2.5;
    font-weight: 100;
    padding: 0;
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    margin: 0;
    width: 100%;
    padding: 0 10% 0 10%;
    z-index: 2;
  }
  .react-datepicker__month {
    background-color: white;
    border: solid 1px #dfdfdf;
    width: 78%;
    margin: auto auto 6%;
  }
  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border: 1px solid #e98c3a !important;
    border-radius: 50%;
    position: absolute;
    top: 55%;
    width: 18px;
    height: 18px;
    &:before {
      content: '';
      display: block;
      position: relative;
      width: 7px;
      height: 7px;
      border-top: 1px solid #e98c3a;
    }
  }
  .react-datepicker__navigation--previous {
    left: 2.5%;
    &:before {
      border-left: 1px solid #e98c3a;
      transform: rotate(-45deg);
      margin: 23% auto 20% 38%;
    }
  }
  .react-datepicker__navigation--next {
    right: 2.5%;
    &:before {
      border-right: 1px solid #e98c3a;
      transform: rotate(45deg);
      margin: 23% 38% 20% auto;
    }
  }
  .react-datepicker__day-name {
    color: #1abc9c;
    width: 14%;
    font-size: 1.2em;
    padding: 0;
    margin: 0;
  }
  .react-datepicker__day {
    line-height: 2.2;
    border-right: solid 1px #dfdfdf;
    padding: 0;
    margin: 0;
    width: 14.25%;
    font-size: 1.2em;
    color: #666666;
    &:last-child {
      border-right: none;
    }
    &:hover {
      border-radius: 0;
    }
  }
  .react-datepicker__day--today {
    background-color: #d3f2ec;
    font-weight: bold;
    color: #666666;
    &:hover {
      background-color: #b4e0d8;
    }
  }
  .react-datepicker__day--outside-month {
    color: transparent;
  }
  .react-datepicker__day--selected {
    background-color: #1abc9c;
    font-weight: normal;
    color: white;
    padding: 0;
    margin: 0;
    border-radius: 0;
    &:hover {
      background-color: #1abc9c;
      font-weight: normal;
      color: white;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }
  }
  .react-datepicker__week {
    line-height: 1.1;
    border-bottom: solid 1px #dfdfdf;
    text-align: left;
    white-space: nowrap;
    &:last-child {
      border-bottom: none;
    }
  }
  .react-datepicker__triangle {
    border: 1px solid #e98c3a;
    box-sizing: content-box;
    background-color: #f7f7f7;
    display: block;
    left: 50%;
    margin-left: -7px;
    width: 12px;
    height: 12px;
    padding: 0px;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
  }



  .boardComputer {
    .react-datepicker {
      position: absolute;
      width: 300px;
      top: 32px;
      right: -139px;
      z-index: 1;
      padding-bottom: 3%;
    }
    .react-datepicker__navigation--previous {
      left: -14%;
      &:before {
        margin: 28% auto 20% 38%;
      }
    }
    .react-datepicker__navigation--next {
      right: 0;
      &:before {
        margin: 28% 38% 20% auto;
      }
    }
    .react-datepicker__day--disabled {
      background-color: #f0f0f0;
    }
  }
  .mainInfo {
    .react-datepicker {
      position: absolute;
      width: 300px;
      top: 39px;
      left: 0;
      z-index: 1;
      padding-bottom: 3%;
    }
    .react-datepicker__navigation--previous {
      left: 2.5%;
      top: 52%;
      &:before {
        margin: 28% auto 20% 38%;
      }
    }
    .react-datepicker__navigation--next {
      right: 2.5%;
      top: 52%;
      &:before {
        margin: 28% 38% 20% auto;
      }
    }
    .react-datepicker__triangle {
      left: 22.5%;
    }
    .react-datepicker__header__dropdown--scroll {
      position: absolute;
      top: 14px;
      right: 10px;
      padding-right: 10px;
    }
    .react-datepicker__year-read-view {
      width: 21%;
      left: 70%;
      bottom: 51px;
      color: #e88e3e;
      font-size: 1.6em;
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        right: 0;
        display: inline-block;
        height: 9px;
        width: 9px;
        border-top: 1px solid transparent;
        border-right: 1px solid #ff7113;
        border-left: 1px solid transparent;
        border-bottom: 1px solid #ff7113;
        background-color: transparent;
        transform: rotate(45deg);
      }
      .react-datepicker__year-read-view--down-arrow {
        display: none;
      }
    }
    .react-datepicker__year-dropdown {
      border-color: #e88e3e;
      z-index: 2;
      padding-left: 50%;
      padding-right: 50%;
      right: 0;
      top: 0;
      left: auto;
      width: auto;
    }
    .react-datepicker__year-option--selected {
      color: #e88e3e;
    }
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #1abc9c;
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: #1abc9c;
    }

  }

  .react-datepicker__header__dropdown--scroll {
    position: absolute;
    top: 14px;
    right: 10px;
    padding-right: 10px;
  }
  .react-datepicker__year-read-view {
    width: 21%;
    left: 70%;
    bottom: 51px;
    color: #e88e3e;
    font-size: 1.6em;
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      right: 0;
      display: inline-block;
      height: 9px;
      width: 9px;
      border-top: 1px solid transparent;
      border-right: 1px solid #ff7113;
      border-left: 1px solid transparent;
      border-bottom: 1px solid #ff7113;
      background-color: transparent;
      transform: rotate(45deg);
    }
    .react-datepicker__year-read-view--down-arrow {
      display: none;
    }
  }
  .react-datepicker__year-dropdown {
    border-color: #e88e3e;
    z-index: 2;
    padding-left: 50%;
    padding-right: 50%;
    right: 0;
    top: 0;
    left: auto;
    width: auto;
  }
  .react-datepicker__year-option--selected {
    color: #e88e3e;
  }
  .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #1abc9c;
  }
  .react-datepicker__navigation--years-previous {
    border-top-color: #1abc9c;
  }
}
