:global {
  .clientSearch {
    position: relative;
    height: 100%;
    .search {
      width: 248px;
      border-radius: 2.6em;
      border: 1px solid #bec3c3;
      line-height: 1.6;
      padding: 0 1.7em 0 .94em;
      background-image: url('../ClientSearch/assets/loupe.png');
      background-position: 220px center;
      background-repeat: no-repeat;
      z-index: 2;
      &:active, &:focus {

      }
        &.withClear {
            background-image: none;
        }
    }
      &__clear {
          position: absolute;
          right: 2.5%;
          top: 19%;
          width: 1em;
          height: 1em;
          background-image: url('../ClientSearch/assets/clear-icon.png');
          background-size: contain;
          z-index: 2;
      }
    .label {
      z-index: 1;
      position: relative;
      display: inline-block;

      font-size: 1em;
      line-height: 2.8;
      text-align: center;
      color: #a8a4a4;
      margin-right: 0;
    }
    .search:focus+.label {

    }
  }
}
