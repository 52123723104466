.editorForService {
  position: relative;
  height: auto;
  width: 1188px;
  margin: 10% auto 10% auto;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  z-index: 12;
  color: #696e6f;

  .disabled {
    &.button {
      background-color: #cacaca;
    }

    * {
      color: #cacaca !important;
      border-color: #e0e0e0 !important;

      &:before {
        border-color: #e0e0e0 !important;
      }
    }

    .changeValue {
      pointer-events: none;
    }

    .error {
      color: #ce252c !important;
    }
  }

  .header {
    color: #e7832a;
    max-width: 100%;
    background-color: #f6f6f6;
    padding: 2.2% 4%;

    .logo {
      width: 4.1em;
      height: 4.1em;
      float: left;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #E2E2E2;
      margin-right: 0.7em;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }

    .h2 {
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.2;
      font-size: 2em;
      color: #2D9CDB;
      margin: 0% 0 1% 0;
    }

    .catalogue {
      font-size: 0.875em;
      color: #828282;
    }

    .editButton {
      position: relative;
      font-size: 2.22em;
      display: inline-block;
      line-height: 2.8;
      cursor: pointer;
      width: 6.8%;
      vertical-align: top;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 37%;
        padding: 41%;
        background: url("../../../assets/pencil.png") center 56% no-repeat;
      }

      &:hover {
        &:before {
          background-color: #e6e6e6;
          border-radius: 10%;
        }
      }
    }

    .value {
      font-size: 2.22em;
      display: inline-block;
      line-height: 2.8;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 94.7%;
      vertical-align: middle;

      &:hover {
        .tooltip {
          display: block;
        }

        .shadowTriangle {
          display: block;
        }
      }

      .tooltip {
        position: absolute;
        top: 85%;
        max-width: 100%;
        background-color: white;
        font-size: 1rem;
        line-height: 1.25;
        text-align: center;
        padding: 1% 1%;
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: none;
        white-space: normal;
        z-index: 3;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #fff;
          position: absolute;
          bottom: 100%;
          left: 2.9em;
        }

        &.hidden {
          display: none !important;
        }
      }

      .shadowTriangle {
        position: absolute;
        bottom: 10%;
        left: 3rem;
        background-color: #fff;
        height: 11.3px;
        width: 11.3px;
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: rotate(45deg);
        display: none;

        &.hidden {
          display: none !important;
        }
      }
    }

    .selectedValue {
      @extend .value;
      max-width: 100%;
      color: #1abc9c;
      cursor: default;
    }

    .isCustom {
      @extend .selectedValue;
      cursor: pointer;
      max-width: 570px;
    }

    .select {
      //width: 1.2rem;
      //height: 1.2rem;
      width: 19px;
      height: 19px;
      display: inline-block;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #e7832a;
      vertical-align: middle;
      margin-top: 0.5rem;
      margin-left: 1rem;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 40%;
        height: 40%;
        border-bottom: 1px solid #e7832a;
        border-right: 1px solid #e7832a;
        left: 30%;
        top: 25%;
        transform: rotate(45deg);
      }
    }

    .edit {
      width: 1.6em;
      height: 1.5em;
      display: inline-block;
      margin-top: 0.5rem;
      vertical-align: middle;
      //padding-bottom: 3.4em;
      background: url("../../../assets/pencil.png") 100% 0 no-repeat;
    }

    .editOk {
      width: 2.8em;
      height: 2.8em;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      background: url("../../../assets/editOk.png") 50% 35% no-repeat;
    }
  }

  .body {
    font-size: 1em;
    padding: 2.2% 4%;
    border-bottom: 6px solid #F2F2F2;
    overflow: visible;

    div {
      vertical-align: top;
    }

    .serviceName {
      .label {
        color: #828282;
        font-size: 0.75em;
        display: block;
        line-height: 1;
        margin-bottom: 0.4em;
      }

      .notInput {
        font-size: 1.5em;
        line-height: 1.6;
        color: #666666;
      }

      input {
        width: 100%;
        font-size: 1em;
        border: 1px solid #bec3c3;
        border-radius: .25em;
        line-height: 1.7;
        padding: 0 0.5em;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        margin: 0 0.6em 0 0;
      }
    }

    .inputGroup {
      margin-bottom: 1em;

      .row {
        margin-bottom: 0.4em;

        &.disabled {
          input {
            color: #dddddd;
          }

          //opacity: 0.8;
        }
      }

      div {
        vertical-align: middle;
      }

      > div {
        margin-bottom: 0;
      }

      .title {
        color: #828282;
        font-size: 0.75em;
        display: block;
        line-height: 1;
      }

      input[type="text"], input[type="number"] {
        width: 2.6em;
        font-size: 1em;
        border: 1px solid #bec3c3;
        border-radius: .25em;
        line-height: 1.7;
        padding: 0 0.5em;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin: 0 0.6em 0 0;
      }

      &.cyclic {
        float: left;

        label {
          font-size: 0.8em;
        }
      }

      &.price {
        width: auto;
        float: right;

        .title {
          text-align: left;
        }

        .changeValue {
          color: inherit;
          width: auto;

          input {
            width: 4.8em;
            text-align: left;
          }
        }

      }

      &.serviceSelect {
        color: #72AC2F;

        .changeValue {
          color: #72AC2F;
        }
      }

      &.comment {
        label {
          color: #828282;
          font-size: 0.75em;
          line-height: 1.6;
          margin-bottom: 0.4em;
          display: block;
        }

      }

      .changeValue {
        position: relative;
        display: inline-block;
        color: #e7832a;
        height: 1.5em;

        input {
          width: 50%;
          margin: 0;
          text-align: left;
          padding: 0 2%;
        }

        .editOk {
          width: 1.8em;
          height: 1.8em;
          display: inline-block;
          vertical-align: bottom;
          background: url("../../../assets/editOk.png") 60% 50% no-repeat;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }

        &:hover {
          cursor: pointer;
        }

        .value {
          display: inline-block;
        }

        .select {
          //width: 1.2rem;
          //height: 1.2rem;
          width: 19px;
          height: 19px;
          display: inline-block;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #e7832a;
          vertical-align: middle;
          margin-top: 0.1em;
          margin-left: 1rem;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            width: 40%;
            height: 40%;
            border-bottom: 1px solid #e7832a;
            border-right: 1px solid #e7832a;
            left: 30%;
            top: 25%;
            transform: rotate(45deg);
          }
        }

        .edit {
          width: 1.2em;
          height: 1.2em;
          display: inline-block;
          vertical-align: bottom;
          background: url("../../../assets/pencil.png") 100% 0 no-repeat;
          background-size: cover;
          vertical-align: middle;

        }
      }
    }

    .h2 {
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.2;
      font-size: 1.5em;
      color: #2D9CDB;
      margin: 0 0 0.5em 0;
    }

    .h3 {
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.2;
      font-size: 0.75em;
      color: #828282;
      margin: 0 0 1em 0;
    }

    &.resources {
      .add {
        font-size: 1.125em;
        line-height: normal;
        color: #72AC2F;
        cursor: pointer;
        padding: 2% 0 2% 0;
      }

      .resource {
        padding: 2% 0 2% 0;
        border-bottom: 1px solid #C8C8C8;

        div {
          vertical-align: middle;
        }

        .info {
          white-space: nowrap;

          .image {
            width: 9%;
            padding-top: 9%;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            background-color: #d6d5d9;
            margin-right: 1%;

            .wrapper {
              position: absolute;
              width: 200%;
              height: 100%;
              left: -50%;
              top: 0;
              text-align: center;

              img {
                display: inline-block;
                height: 100%;
                width: auto;
              }
            }

          }

          .title {
            white-space: nowrap;
            font-size: 1em;
            line-height: 2;
            max-height: 2.4em;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            width: auto;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5em;
          }
        }

        .showResourceServices {
          text-align: right;
          font-size: 1em;
          line-height: 2;
          color: #FF8A00;
          cursor: pointer;
        }

        .resourceServices {
          margin-top: 2%;

          .service {
            margin-bottom: 4%;

            :global {
              .hlpCheckbox {
                width: 1.45em;
                height: 1.45em;
                margin-right: 0.5em;
              }
            }

            .title {
              vertical-align: middle;
            }
          }
        }
      }
    }

    &.options {
      background-color: #E5E5E5;
      border-bottom: none;
      font-size: 1.125em;

      .aService {
        position: relative;
        padding-left: 1em;
        border-left: 1px solid #C4C4C4;
        padding-bottom: 2%;

        > div {
          display: inline-block;
          vertical-align: top;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          height: 100%;
          width: 1em;
          vertical-align: middle;
          border-top: 1px solid #C4C4C4;
          left: 0;
          top: 0.8em;
        }

        &:first-child {
          border-left: none;

          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 1em;
            vertical-align: middle;
            border-left: 1px solid #C4C4C4;
            border-top: 1px solid #C4C4C4;
            left: 0;
            top: 0.8em;
          }
        }

        &:last-child {
          border-left: none;

          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 1em;
            width: 1em;
            vertical-align: middle;
            border-left: 1px solid #C4C4C4;
            border-bottom: 1px solid #C4C4C4;
            border-top: none;
            left: 0;
            top: 0em;
          }
        }

        :global {
          .hlpCheckbox {
            width: 1.45em;
            height: 1.45em;;
            margin-right: 0.5em;
          }
        }

        .sOptions {
          color: #666666;
          font-size: 1em;
          display: inline-block;
        }
      }
    }
  }

  .footer {
    font-size: 1em;
    padding: 2.2% 4%;
    overflow: hidden;
  }

  .cyclicTitle {
    padding: 9% 0 2%;
  }

  .customName {
    width: 50% !important;
    font-size: 1.5em;
    display: inline-block;
    margin: 5.25% 0 !important;
    color: #696e6f;
    text-align: left !important;
    padding: 0 2%;
  }

  .text {
    display: inline-block;
    white-space: pre;
  }

  .regulatory {
    color: #1abc9c;
    display: inline-block;
    vertical-align: middle;
    width: 30.4%;
    line-height: 2.4;
    border-radius: 2.6em;
    border: 1px solid #1abc9c;
    text-align: center;
    font-size: 0.75em;
    margin-left: 3%;

    &:hover {
      cursor: pointer;
    }
  }

  textarea {
    border: 1px solid #bec3c3;
    border-radius: 0.25em;
    font-size: 1.1em;
    color: #485051;
    width: 100%;
    padding: 2%;
    resize: none;
    background: #FCFBFB;
  }

  .buttons {
    width: 50%;
  }

  .rightButtons {
    @extend .buttons;
    text-align: right;
    float: right;
    white-space: nowrap;
  }

  .leftButtons {
    @extend .buttons;
    float: left;
    text-align: left;
  }

  .cancel {
    color: #EB5757;
    margin: 0 3% 0 16.5%;
    line-height: 2.3;
    font-size: 0.875em;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }

  .button {
    display: inline-block;
    vertical-align: middle;
    line-height: 2.3;
    border-radius: 2.6em;
    text-align: center;
    width: auto;
    color: white;
    font-size: 0.875em;
    padding: 0 3em 0 3em;

    &:hover {
      cursor: pointer;
    }
  }

  .addButton {
    @extend .button;
    background-color: #72AC2F;
  }

  .saveButton {
    @extend .button;
    background-color: #72AC2F;
  }

  .deleteButton {
    @extend .button;
    border: 1px solid #c6272b;
    color: #c6272b
  }
}

.outerMenu {
  box-sizing: content-box;
  width: 100%;
  min-width: 200px;
  height: auto;
  max-height: 300px;
  position: absolute;
  background-color: white;
  border: 1px solid #ff7113;
  padding: 10px 10px 10px 10px;
  right: -1.44rem;
  top: 130%;
  text-align: left;
  line-height: 1.8;
  white-space: normal;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    right: 25px;
    display: block;
    height: 11px;
    width: 11px;
    border-top: solid 1px transparent;
    border-right: solid 1px #ff7113;
    border-left: solid 1px transparent;
    border-bottom: solid 1px #ff7113;
    background-color: white;
    transform: rotate(-135deg);
  }

  .item {
    font-size: 0.9rem;
    color: #868887;
    margin: 0 10px 0 16px;
    padding: 0 6px 0 6px;
    cursor: pointer;

    &:hover {
      background-color: #dff6f1;
    }
  }

  .duration {
    font-size: 1.2rem;
    color: #ff7113;
    margin: 0 10px 0 16px;
    padding: 0 6px 0 6px;
  }

  .group {
    color: #53cdb5;
    font-size: 1.2rem;
    margin-left: 12px;
  }

  .customService {
    width: 90%;
    border-radius: 2.6rem;
    border: 1px solid #e7832a;
    color: #e7832a;
    text-align: center;
    margin: 1.5em auto 0;
    line-height: 2.4;
  }
}

.outerPeriodMenu {
  @extend .outerMenu;
  min-width: 150px;

  &:before {
    top: -7px;
  }
}

.outerDurationMenu {
  @extend .outerMenu;

  &:before {
    top: -7px;
  }
}

.outerNameMenu {
  @extend .outerMenu;
  top: 78%;
  min-width: 250px;
  left: 0;

  &:before {
    display: none;
  }
}

.scrollTrack {
  position: absolute;
  width: 3px !important;
  transition: opacity 200ms;
  right: 0px;
  top: 7%;
  height: 89%;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 110%;
    background-color: #c0bcbc;
    top: -5%;
    left: 1px;
  }
}

.scrollThumb {
  width: 3px;
  //height: 16px !important;
  background-color: #949191;
  border-radius: 1px;
  overflow: hidden;
}

.errorMenu {
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 24%;
    display: block;
    height: 11px;
    width: 11px;
    border-top: solid 1px transparent;
    border-right: solid 1px #ce252c;
    border-left: solid 1px transparent;
    border-bottom: solid 1px #ce252c;
    background-color: white;
    transform: rotate(-135deg);
  }

  width: 100%;
  min-width: 200px;
  height: auto;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ce252c;
  color: #ce252c;
  padding: 10px;
  top: 150%;
  //left: -10%;
  line-height: 1.8;
  white-space: normal;
  z-index: 1;
  cursor: default;
}

.errorMenuRepeats {
  @extend .errorMenu;
  top: 54%;
  width: 40%;

  &:before {
    left: 9.5%
  }
}

.error {
  position: absolute;
  color: #ce252c;
  bottom: 14%;
  left: 34.5%;
  font-size: 0.9em;

  &.unknown {
    bottom: 1%;
  }
}

.serviceNameError {
  @extend .error;
  bottom: 86%;
  left: 6.5%;
}

.periodError {
  @extend .error;
  bottom: 41%;
  left: 7%;
}

.durationError {
  @extend .error;
  left: 25.5%;
  bottom: 72%;
}

.priceError {
  @extend .error;
  left: 25.5%;
  bottom: 65%;
}
