.parent {
  width: 100%;
  height: 2.4em;
  border-radius: 1.2em;
  padding: 0 1.2em;
  font-family: "Fira Sans";
  cursor: pointer;
}
.primary {
  width: 100%;
  height: 2.4em;
  border-radius: 1.2em;
  padding: 0 1.2em;
  border: 0;
  font-family: "Fira Sans";
  background: #4490d6;
  color: #fff;
  cursor: pointer;
}
.default {
  width: 100%;
  height: 2.4em;
  border-radius: 1.2em;
  padding: 0 1.2em;
  border: 1px solid #fff;
  font-family: "Fira Sans";
  background: none;
  color: #fff;
}
.default_primary {
  width: 100%;
  height: 2.4em;
  border-radius: 1.2em;
  padding: 0 1.2em;
  border: 1px solid #fff;
  font-family: "Fira Sans";
  background: rgba(21,35,98,0.65);
  color: #fff;
}
.save {
  @extend .parent;
  border: 1px solid #fff;
  background: #1abc9c;
  color: #fff;
}
.blockedSave {
  @extend .parent;
  border: 1px solid #fff;
  background: #75bca5;
  color: #fff;
  cursor: not-allowed;
}
.change {
  @extend .parent;
  border: 1px solid #fff;
  background: #73ae2e;
  color: #fff;
}
.blockedChange {
  @extend .parent;
  border: 1px solid #fff;
  background: #85ae73;
  color: #fff;
  cursor: not-allowed;
}
.carRemove {
  @extend .parent;
  border: 1px solid #c72c2c;
  background: #fff;
  color: #c72c2c;
  font-size: 0.8rem;
}
.active {
  background-color: white;
}
.orange {
  @extend .parent;
  height: 2em;
  border: 1px solid #e7832a;
  background: #fff;
  color: #e7832a;
}
