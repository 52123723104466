.viewordermodal {
  position: absolute;
  width: 586px;
  background-color: white;
  color: #868887;
  text-align: left;
  padding: 0;
  -moz-box-shadow: 0 0 10px #000;
  -webkit-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: block;
  line-height: normal;
  font-size: 1rem;
  white-space: normal;
  cursor: default;
  &.topAlign {
    top: auto;
    bottom: 100%;
    margin-bottom: 10px;
    left: -290px;
    .triangle.bottom {
      display: block;
    }
  }
  &.bottomAlign {
    top: 100%;
    bottom: auto;
    margin-top: 10px;
    left: -290px;
    .triangle.top {
      display: block;
    }
  }
  &.leftAlign {
    top: -12px;
    bottom: auto;
    margin-right: 10px;
    right: 100%;
    left: auto;
    .triangle.right {
      display: block;
    }
  }
  &.rightAlign {
    top: -12px;
    margin-left: 10px;
    left: 100%;
    right: auto;
    .triangle.left {
      display: block;
    }
  }
  .triangle {
    display: none;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
    position: absolute;
    &.bottom {
      top: 100%;
      left: 50%;
      margin-left: 2px;
    }
    &.top {
      bottom: 100%;
      left: 50%;
      margin-left: 2px;
      transform: rotate(180deg);
    }
    &.right {
      top: 20px;
      left: 100%;
      transform: rotate(-90deg);
      margin-left: -4px;
    }
    &.left {
      top: 20px;
      right: 100%;
      transform: rotate(90deg);
      margin-right: -4px;
    }
  }
  .header {
    position: relative;
    width: 100%;
    line-height: normal;
    font-size: 1em;
    padding: 3% 0 3% 6%;
    text-align: left;
    color: #868887;
    background-color: #f6f6f6;
    .name {
      color: #19bd9a;
      font-size: 1.5em;
    }
    .phone {
      font-size: 0.9em;
    }
  }
  .body {
    padding: 1% 6% 4%;
    position: relative;
    overflow: visible;
    z-index: 500;
    .resource {
      text-align: center;
      font-size: 0.9em;
      line-height: 3;
      padding-bottom: 1%;
    }
    .dateBlock {
      font-size: 1em;
      padding: 0 0 2% 0;
      .dates {
        vertical-align: middle;
        span {
          display: inline-block;
          vertical-align: middle;
          &.date, &.time {
            color: #73ae2e;
            font-size: 1.2em;
          }
        }
        .shift {
          color: #b7caaa;
          font-size: 0.8em;
          text-align: center;
          line-height: 2;
          border: 1px solid #73ae2e;
          border-radius: 2em;
          display: inline-block;
          padding: 0 3% 0 3%;
          width: auto;
          cursor: pointer;
        }
      }
      .duration {
        font-size: 0.9em;
        line-height: 3;
        .value {
          color: #73ae2e;
        }
      }
    }
    .serviceBlock {
      padding: 0 0 4%;
      .row {
        text-align: left;
        border-bottom: 1px solid #f1f0f0;
        padding: 1.5% 0;
        &:before {
          content: '';
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }
        .name {
          width: 56%;
          display: inline-block;
          vertical-align: middle;
          white-space: normal;
        }
        .duration {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          color: #73ae2e;
          line-height: 1;
        }
        .price {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          color: #73ae2e;
          font-weight: 600;
          padding-right: 1%;
          line-height: 1;
        }
        .options {
          width: 56%;
          font-size: 0.8em;
          display: inline-block;
          vertical-align: top;
          white-space: normal;
          margin-left: 1.3em;
          line-height: 1;
        }
      }
      .totalPrice {
        text-align: right;
        padding: 3% 5% 0 0;
        .label {
          display: inline-block;
          padding-right: 2%;
        }
        .value {
          display: inline-block;
          color: #73ae2e;
          font-weight: 600;
        }
      }
    }
    .description {
      font-size: 0.9rem;
      margin-bottom: 1rem;
      color: #868887;

    }
    .cancelOrder {
      color: #d9736f;
      font-size: 1em;
      text-align: center;
      line-height: 2;
      border: 1px solid #cf2525;
      border-radius: 2em;
      display: block;
      width: 80%;
      margin: auto;
      cursor: pointer;
      &.toConfirm {
        border: none;
        div {
          display: inline-block;
          margin-left: 1%;
          line-height: 2;
          border: 1px solid transparent;
          width: 18%;
          text-align: center;
          &:hover {
            border: 1px solid #cf2525;
            border-radius: 2em;
          }
        }
      }
      a {
        color: #d9736f;
        padding: 0 3% 0 3%;
        display: inline-block;
      }
    }
  }
  hr {
    border: none;
    background-color: #e3e1e1;
    width: 100%;
    height: 1px;
  }
  .footer {
    text-align: right;
    width: 96%;
    overflow: hidden;
    line-height: normal;
    padding: 2% 1% 2% 1%;
    z-index: 1;
    clear: both;
    > div {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      cursor: pointer;
      &.change {
        margin: 0;
        color: #fff;
        font-size: 1em;
        text-align: center;
        background-color: #ff7113;
        border-radius: 1.4em;
        display: inline-block;
        width: auto;
        position: relative;
        overflow: hidden;
        line-height: normal;
        padding: 1% 7%;
      }
      &.cancel {
        color: #2e7dad;
        line-height: normal;
        padding: 1% 5%;
        text-decoration: underline;
      }
    }
  }
}