.excel {
  color: #379133;
  margin-right: 5%;
  display: inline-block;
  line-height: 2;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: #2e5d2a;
  }
  &.processing {
    text-decoration: none;
  }
}
.modal {
  position: fixed;
  background-color: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  z-index: 1002;
  top: 0;
  left: 0;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .content {
    position: relative;
    max-width: 500px;
    display: inline-block;
    vertical-align: middle;
    padding: 50px 50px 0 50px;
    background-color: #fff;
    text-align: left;
  }
  .text {
    display: inline-block;
    color: #696e6f;
  }
  .value {
    padding-left: 10px;
    display: inline-block;
    color: #e7832a;
  }
  .error {
    color: #c6272b;
  }
  .btnContainer {
    width: 70px;
    padding: 10px 0;
    margin: auto;
  }
}
.exportPopup{
  position: fixed;
  right: 0;
  bottom: 0;
  color: white;
  padding: 20px;
  background-color: #4d4c4c;
  span {
    color: #5794b8;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}