@import '../../../../../styles/themes/default/colors.scss';

:global {
  .settings-block.settings-block--services {
    .settings-block__h1 {
      margin: 0 0 1% 0;
    }

    .editSpec {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      line-height: 2.3;
      border-radius: 2.6em;
      border: 1px solid;
      text-align: center;
      width: auto;
      color: #72AC2F;
      font-size: 0.875em;
      padding: 0 3em 0 3em;
      margin: 0 0 2% 0;

      &--cancel {
        border: 0;
        color: #EB5757;
      }
    }

    .settings-block__services {
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        display: block;
      }

      .infoHeader {
        white-space: nowrap;
        font-size: 0.75em;
        line-height: 1;
        margin-bottom: 2%;
        > div {
          position: relative;
          display: inline-block;
          margin-right: 5em;
          cursor: pointer;
          span.red {
            color: red;
          }
          .toolBar {
            position: absolute;

            top: 100%;
            left: 0;
            padding: 4%;
            margin-top: 1em;
            &:after {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              left: 10px;
              top: -6px;
              box-sizing: content-box;
              transform: rotate(45deg);
              border-top: 1px solid #A0A3A6;
              border-left: 1px solid #A0A3A6;
              background-color: white;
              z-index: 2;
            }
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              border: 1px solid #A0A3A6;
              background-color: white;
              z-index: 1;
            }
            div {
              position: relative;
              display: block;
              z-index: 7;
              text-decoration: underline;
              line-height: 1.6;
              margin-bottom: 0.4em;
              font-size: 1em;
            }
          }
        }

      }

      &__node {
        margin: 0 0 2%;
        overflow: hidden;
      }

      &__header {
        font-size: 1em;
        font-weight: 400;
        color: #1abc9c;
        line-height: 1.2;
        margin: 0 0 1%;
        vertical-align: middle;

        &:before {
          content: '';
          display: inline-block;
          height: 19px;
        }

        &:hover {
          color: #00e5ca;
        }

        > div {
          margin-left: 2em;
        }
      }

      &__link {
        color: #416F8B;
        display: block;
        font-size: 1em;
        line-height: 2;
        margin-bottom: 1em;

        &:before {
          content: '';
          display: inline-block;
          width: 19px;
          height: 19px;
          //background-image: url('components/Spec/assets/checkbox-icon.png');
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 1%;
        }

        &.active {
          &:before {
            background-position: 100% top;
          }
        }

        .catalogueName {
          cursor: pointer;

          &:after {
            content: '';
            width: 1em;
            height: 1em;
            background: url('../Services/assets/expand.png') no-repeat center center;
            display: inline-block;
            vertical-align: middle;
          }
        }

        &.withChildren {
          .catalogueName {
            cursor: pointer;

            &:after {
              content: '';
              width: 1em;
              height: 1em;
              background: url('../Services/assets/expand.png') no-repeat center center;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        &.active {
          > .catalogueName {
            &:after {
              transform: rotate(90deg);
            }
          }
        }

        &.unlinked {
          color: #BDBDBD;
        }

        > div {
          margin-left: 2em;
        }

        .catalogueIcon {
          width: 2.4375em;
          height: 2.4375em;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.7em;
          position: relative;

          img {
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid #E2E2E2;
          }

          :global {
            .hlpCheckbox {
              position: absolute;
              width: 2em;
              height: 2em;
              display: block;
              left: 0;
              bottom: 0;
              margin-bottom: 0;
              margin-left: -10%;

              &:before {
                background-color: white;
                border-color: #79A0B8;
              }
            }
          }
        }

        .catalogueName {
          display: inline-block;
          margin: 0 0 1em 0;
          white-space: nowrap;

          span {
            text-decoration: underline;

          }

        }

        .catalogueServices {
          margin: 0 0 1em 4em;

          &:empty {
            display: none;
          }

          &.hidden {
            display: none;
          }

          .theader {
            > div {
              display: inline-block;
              color: #828282;
              font-size: 0.75em;
              padding: 0.4em;
              text-align: center;

              &:first-child {
                width: 60%;
                text-align: left;
              }

              &:nth-child(2) {
                width: 10%;
              }

              &:nth-child(3) {
                width: 15%;
              }

              &:nth-child(4) {
                width: 15%;
              }
            }
          }

          &__services {
            &:empty {
              display: none;
            }

            .body {
              border: 1px solid #DDDDDD;
            }

          }

          &__templates {
            &:empty {
              display: none;
            }

            .body {
              border: 1px solid #DDDDDD;
            }
          }

          &__item {
            border-bottom: 1px solid #DDDDDD;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
            }

            > div {
              display: inline-block;
              font-size: 0.875em;
              padding: 0.6em 0.4em 0.6em 0.4em;
              border-right: 1px solid #DDDDDD;
              white-space: nowrap;
              vertical-align: middle;
              height: 3.3rem;
              text-align: center;

              .icon {
                width: 2.4375em;
                height: 2.4375em;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                border: 1px solid #E2E2E2;
                margin-right: 0.7em;
                position: relative;

                img {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: block;
                  left: 0;
                  top: 0;
                  object-fit: cover;
                  overflow: hidden;
                  border-radius: 50%;
                }

                &__good, &__bad {
                  background: url('../Services/assets/service_is_good.svg') no-repeat center center;
                  background-size: contain;
                  position: absolute;
                  bottom: -22%;
                  right: -18%;
                  width: 80%;
                  height: 80%;
                }

                &__bad {
                  background: url('../Services/assets/service_is_bad.svg') no-repeat center center;
                }
              }

              &:first-child {
                width: 60%;
                text-align: left;
              }

              &:nth-child(2) {
                width: 10%;
              }

              &:nth-child(3) {
                width: 15%;
                text-align: center;
              }

              &:nth-child(4) {
                width: 15%;
              }

              &:last-child {
                border-right: none;
              }

              &.duration {
                input {
                  width: 4em;
                }
              }
              &.name {
                position: relative;
              }
              .name {
                position: relative;
                &__title {
                  display: inline-block;
                  vertical-align: middle;
                  padding: 0 1em 0 4em;
                  margin: 0 -1em 0 -4em;
                  max-width: 88%;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  line-height: 1;
                }
                &__isCustom {
                  height: 100%;
                  vertical-align: middle;
                  color: #828282;
                  font-size: 0.75em;
                  float: right;
                  &:before {
                    content: '';
                    position: relative;
                    display: inline-block;
                    height: 100%;
                    width: 1px;
                    vertical-align: middle;
                  }
                }
              }

            }

            input[type="text"], input[type="number"] {
              color: #797979;
              width: 4em;
              min-width: auto;
              font-size: 1em;
              border: 1px solid #bec3c3;
              border-radius: .25em;
              line-height: 1.5;
              padding: 1% 0.5em;
              display: inline-block;
              vertical-align: middle;
              text-align: center;
              margin: 0 0.5em 0 0.5em;

              &:first-child {
                margin: 0 0.5em 0 0;
              }

              &:last-child {
                margin: 0 0 0 0.5em;
              }

              &:first-child:last-child {
                margin: 0;
              }
            }

            input[type="number"] {
              width: 6em;
            }
          }
        }

        .addService {
          vertical-align: middle;
          color: #72AC2F;
          margin: 0 0 1em 4em;
          border: 1px solid #DDDDDD;
          line-height: 2.4375em;
          padding: 0.6em 0.4em 0.6em 0.4em;
          font-size: 1em;
          cursor: pointer;

          div:first-child {
            margin-right: 0.7em;
          }

          span {
            font-size: 0.875em;
            text-decoration: underline;
          }
        ;
        }
      }

    }

    .settings-block__catalogues {
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        display: block;
      }

      .infoHeader {
        white-space: nowrap;
        font-size: 0.75em;
        line-height: 1;
        margin-bottom: 2%;
        > div {
          position: relative;
          display: inline-block;
          margin-right: 5em;
          span.red {
            color: red;
          }
          .toolBar {
            position: absolute;
            div {
              display: block;
            }
          }
        }
      }

      &__node {
        margin: 0 0 2%;
        overflow: hidden;
      }

      &__header {
        font-size: 1em;
        font-weight: 400;
        color: #1abc9c;
        line-height: 1.2;
        margin: 0 0 1%;
        vertical-align: middle;

        &:before {
          content: '';
          display: inline-block;
          height: 19px;
        }

        &:hover {
          color: #00e5ca;
        }

        > div {
          margin-left: 2em;
        }
      }

      &__link {
        color: #416F8B;
        display: block;
        font-size: 1em;
        line-height: 2;
        margin-bottom: 1em;

        &:before {
          content: '';
          display: inline-block;
          width: 19px;
          height: 19px;
          //background-image: url('components/Spec/assets/checkbox-icon.png');
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 1%;
        }

        &.active {
          &:before {
            background-position: 100% top;
          }
        }

        &.withChildren {
          .catalogueName {
            cursor: pointer;

            &:after {
              content: '';
              width: 1em;
              height: 1em;
              background: url('../Services/assets/expand.png') no-repeat center center;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        &.active {
          > .catalogueName {
            &:after {
              transform: rotate(90deg);
            }
          }
        }

        &.unlinked {
          color: #BDBDBD;

          .catalogueTemplates {
            margin-left: 3em;

            &__template {
              color: #BDBDBD;
            }
          }
        }

        > div {
          margin-left: 2em;
        }

        .catalogueIcon {
          width: 2.1em;
          height: 2.1em;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.7em;
          position: relative;
          border: 1px solid #E2E2E2;
          border-radius: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;

          &.beauty {
            background-image: url('../../../../../assets/beauty-business-type-icon.png');
          }

          &.auto {
            background-image: url('../../../../../assets/auto-business-type-icon.png');
          }

          img {
            overflow: hidden;
            border-radius: 50%;
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            object-fit: cover;
          }

          :global {
            .hlpCheckbox {
              position: absolute;
              width: 1.4em;
              height: 1.4em;
              display: block;
              left: 0;
              bottom: 0;
              margin-bottom: 0;
              margin-left: -10%;

              &:before {
                background-color: white;
                border-color: #79A0B8;
              }
            }
          }
        }

        .catalogueName {
          display: inline-block;
          margin: 0 0 1em 0;
          white-space: nowrap;

          span {
            text-decoration: underline;

          }

        }

        .catalogueTemplates {
          margin-left: 3em;

          &__template {
            color: #828282;
            font-size: 0.875em;
            margin-bottom: 1em;

            &__icon {
              width: 2.1em;
              height: 2.1em;
              display: inline-block;
              vertical-align: middle;
              margin-right: 0.7em;
              position: relative;
              border: 1px solid #E2E2E2;
              border-radius: 50%;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;

              &.beauty {
                background-image: url('../../../../../assets/beauty-business-type-icon.png');
              }

              &.auto {
                background-image: url('../../../../../assets/auto-business-type-icon.png');
              }

              img {
                overflow: hidden;
                border-radius: 50%;
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                left: 0;
                top: 0;
                object-fit: cover;
              }
            }
          }
        }
      }

    }
  }
}
