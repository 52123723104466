:global {
  .datepicker__container {
    overflow: hidden;
  }
  .schedule-datepicker {
    z-index: 1000;
    background-color: #fff;
    border-radius: 0;
    padding-bottom: 0;
    font-family: Open Sans, serif;
    font-size: 1rem;
    position: relative;
    width: 100%;
    right: auto;
    left: auto;
    &__header {
      width: 100%;
      text-align: center;
      position: relative;

      .arrow {
        height: auto;
        line-height: 1;
        overflow: hidden;
        vertical-align: middle;
        position: absolute;
        bottom: 1%;
        width: 30%;
        font-size: 0.625em;
        color: #C1C1C1;
        cursor: pointer;
        display: none;
        &--prev {
          left: 1px;
          padding-left: 1.1em;
          background: url('../../../assets/arrow--left.png') no-repeat left center;
          background-size: contain;
          text-align: left;

        }
        &--next {
          right: 1px;
          padding-right: 1.1em;
          background: url('../../../assets/arrow--right.png') no-repeat right center;
          background-size: contain;
          text-align: right;
        }
      }
      .month-name {
        color: #2D9CDB;
        text-transform: capitalize;
        text-align: left;
        font-size: 0.875em;
        line-height: 2.5;
        font-weight: 100;
        padding: 0;
        display: inline-block;
        right: auto;
        left: auto;
        vertical-align: middle;
        margin: 2%;
      }
    }
    &__weekdays {
      width: 100%;
      margin: auto;
      .day {
        display: inline-block;
        width: 14.28%;
        text-align: center;
        font-size: 0.875em;
        line-height: 2.2;
        color: #909090;
      }
    }
    &__body {
      width: 100%;
      margin: auto;
      .wrapper {
        width: 100%;
        display: inline-block;
        border-top: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
      }
      .day {
        display: block;
        float: left;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        width: 14.28%;
        text-align: center;
        font-size: 1.5em;
        line-height: 1;
        cursor: default;
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;
        padding-top: 19.2%;
        &:before {
          content: '';
          position: absolute;
          left: 4%;
          top: 4%;
          width: 92%;
          height: 92%;
          z-index: 0;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          border: 2px solid white;
        }

        &.byCompany {
          &:before {
            opacity: 0.8;
            box-shadow: none;
          }
        }

        &.selected {
          //селект
          &:before {
            background-color: #6EA234 !important;
          }
          .timeBlock {
            display: block;
            background-color: transparent !important;
            background-image: url('assets/selectedday__check.png');
            background-repeat: no-repeat;
            span {
              display: none !important;
            }
          }
          .day {
            &__number {
              color: white !important;
            }
          }
        }
          &:hover {
          &:before {
            background-color: #e3efe7;
          }
        }
        &.offDay.byCompany {
          cursor: not-allowed;
        }
        &.expired, &.notAvailable {
          cursor: not-allowed;
          color: #cccccc;
          &:before {
            opacity: 0.8;
            box-shadow: none;
          }
        }
        &.workDay {
          &:before {
            background-color: #E9FFD0;
          }
          .timeBlock {
            display: block;
            span.clear {
              display: none;
            }
            span.second {
              //display: block;
              display: none;
              color: #666666;
            }
            &:after {

            }
          }
          &.withBreak {
            .timeBlock {
              span.second {
                display: block;
              }
            }
            &:hover {
              .timeBlock {
                height: 40%;
                span.second {
                  display: block;
                }
                /*span.main {
                  display: none;
                }
                span.clear {
                  display: inline;
                }*/
              }
            }
          }
          &:hover {
            .timeBlock {
              /*height: 40%;
              span.second {
                display: block;
              }*/
              /*span.main {
                display: none;
              }
              span.clear {
                display: inline;
              }*/
            }
          }
          .day__number {
            color: #2D9CDB;
          }
        }
        &.workDay.byCompany {
          &:before {
            background-color: #D0EEFF;
          }
          .timeBlock {

          }
        }
        &.offDay {
          &:before {
            background-color: #f2f2f2;
          }
          .timeBlock {
            display: block;
            font-size: 0.3em;

            span.clear {
              display: none;
            }
          }
/*          &:hover {
            .timeBlock {
              background-color: #b92424;
              color: white;
              span.main {
                display: none;
              }
              span.clear {
                display: inline;
              }
            }
          }*/
        }

        &__number {
          z-index: 1;
          position: absolute;
          bottom: 6%;
          right: 10%;
        }
        &.withBreak {
          .timeBlock {
            position: absolute;
            top: 6%;
            left: 4%;
            height: 26%;
            width: 92%;
            font-size: 0.3em;
            line-height: 1.6;
            transition: height 0.2s ease-out 0s;
            &:before {
              content: '';
              width: 14%;
              padding-top: 14%;
              position: absolute;
              right: 4%;
              margin-top: -8%;
              transform: rotate(45deg);
              z-index: 1;
            }
          }
        }
        .timeBlock {
          position: absolute;
          top: 6%;
          left: 4%;
          height: 26%;
          width: 92%;
          font-size: 0.4em;
          line-height: 1.6;
          transition: height 0.2s ease-out 0s;
          text-align: left;
          &:before {
            content: '';
            width: 14%;
            padding-top: 14%;
            position: absolute;
            right: 4%;
            margin-top: -8%;
            transform: rotate(45deg);
            z-index: 1;
          }
          .clear {
            display: none;
          }
        }

      }
    }
    &__footer {
      .footer__selectedDays {
        text-align: center;
        line-height: 4;
        span {
          color: #73cfba;
        }
        .clearAll {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-image: url('../../../assets/delete-icon.png');
          background-repeat: no-repeat;
          background-size: cover;
          vertical-align: middle;
          margin: 0 0 0.2em 0.5em;
          &:hover {
            background-position: 100%;
          }
        }
        &.hidden {
          visibility: hidden;
          //display: none;
        }
      }
    }

    .react-datepicker {
      z-index: 1000;
      background-color: #fff;
      border: 1px solid #e98c3a;
      border-radius: 0;
      padding-bottom: 10%;
      font-family: Open Sans;
      position: relative;
      width: 100%;
      right: auto;
      left: auto;
      &:before {
        content: '';
        padding-top: 12%;
        width: 100%;
        background-color: #f7f7f7;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        height: auto;
      }
    }
    .react-datepicker__current-month {
      color: #e88e3e;
      text-transform: capitalize;
      text-align: left;
      font-size: 2em;
      line-height: 2.5;
      font-weight: 100;
      padding: 0;
      display: inline-block;
      right: auto;
      left: auto;
      vertical-align: middle;
      margin: 2% 2% 8% 2%;
    }

    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;
      margin: 0;
      width: 100%;
      padding: 0 10% 0 10%;
      z-index: 2;
    }
    .react-datepicker__month {
      background-color: white;
      border: solid 1px #dfdfdf;
      width: 78%;
      margin: auto auto 6%;
    }
    .react-datepicker__day-name {
      color: #1abc9c;
      width: 14%;
      font-size: 1.6em;
      padding: 0;
      margin: 0;
    }
    .react-datepicker__day {
      line-height: 2.2;
      border-right: solid 1px #dfdfdf;
      padding: 0;
      margin: 0;
      width: 14.25%;
      font-size: 3em;
      color: #666666;
      &:last-child {
        border-right: none;
      }
      &:hover {
        border-radius: 0;
      }
    }
    .react-datepicker__day--today {
      background-color: #d3f2ec;
      font-weight: bold;
      color: #666666;
      &:hover {
        background-color: #b4e0d8;
      }
    }
    .react-datepicker__day--outside-month {
      color: transparent;
    }
    .react-datepicker__day--selected {
      background-color: #1abc9c;
      font-weight: normal;
      color: white;
      padding: 0;
      margin: 0;
      border-radius: 0;
      &:hover {
        background-color: #1abc9c;
        font-weight: normal;
        color: white;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
    .react-datepicker__week {
      line-height: 1.1;
      border-bottom: solid 1px #dfdfdf;
      text-align: left;
      white-space: nowrap;
      &:last-child {
        border-bottom: none;
      }
    }
    .react-datepicker__triangle {
      display: none;
    }
  }
  .datePicker-wrapper {
    &.hidden {
      display: none;
    }
  }
  .datepicker-container {
    .col-md-4 {
      border-right: 1px solid #ECECEC;
      &:first-child {
        .schedule-datepicker {
          &__header {
            .arrow--prev {
              display: block;
            }
          }
        }
      }
      &:last-child {
        .schedule-datepicker {
          &__header {
            .arrow--next {
              display: block;
            }
          }
        }
      }
    }
  }
}
