.orderModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.8);
  z-index: 1002;
  text-align: center;
  &:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .element {
    //position: relative;
    max-height: 100vh;
    display: inline-block;
    vertical-align: middle;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    width: 586px;
    background-color: white;
    color: #868887;
    -moz-box-shadow: 0 0 10px #000;
    -webkit-box-shadow: 0 0 10px #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    line-height: normal;
    font-size: 1rem;
    white-space: normal;
    &.rightOverflow {
      left: -545px;
      &:after {
        left: 93%;
      }
    }
    .header {
      position: relative;
      width: 100%;
      line-height: normal;
      font-size: 1em;
      padding: 3% 0 3% 6%;
      text-align: left;
      color: #868887;
      background-color: #f6f6f6;
      .name {
        color: #19bd9a;
        font-size: 1.5em;
      }
      .phone {
        font-size: 0.9em;
      }
    }
    .body {
      padding: 3% 6% 8%;
      position: relative;
      overflow: visible;
      z-index: 500;
      .resource {
        text-align: center;
        font-size: 0.9em;
        line-height: 3;
        padding-bottom: 4%;
      }
      .dateBlock {
        font-size: 1em;
        padding: 0 0 2% 0;
        .dates {
          vertical-align: middle;
          span {
            display: inline-block;
            vertical-align: middle;
            &.date, &.time {
              color: #73ae2e;
              font-size: 1.2em;
            }
          }
          .shift {
            color: #b7caaa;
            font-size: 0.8em;
            text-align: center;
            line-height: 2;
            border: 1px solid #73ae2e;
            border-radius: 2em;
            display: inline-block;
            padding: 0 3% 0 3%;
            width: auto;
          }
        }
        .duration {
          font-size: 0.9em;
          line-height: 3;
          .value {
            color: #73ae2e;
          }
        }
      }
      .serviceBlock {
        padding: 0 0 5%;
        .row {
          text-align: left;
          border-bottom: 1px solid #f1f0f0;
          padding: 1.5% 0;
          &:before {
            content: '';
            height: 100%;
            display: inline-block;
            vertical-align: middle;
          }
          .name {
            width: 56%;
            display: inline-block;
            vertical-align: middle;
            white-space: normal;
            line-height: 1.8;
          }
          .duration {
            width: 20%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            color: #73ae2e;
            line-height: 1.5;
          }
          .price {
            width: 20%;
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            color: #73ae2e;
            font-weight: 600;
            padding-right: 1%;
            line-height: 1.5;
            input {
              border: 1px solid #bec3c3;
              border-radius: .25em;
              width: 100%;
              color: #868887;
              padding: 1% 3%;
              display: inline-block;
            }
            .value {
              display: inline-block;
              &.active {
                border-bottom: 1px dashed #73ae2e;
                cursor: pointer;
              }
            }
          }
        }
        .totalPrice {
          text-align: right;
          padding: 3% 5% 0 0;
          .label {
            display: inline-block;
            padding-right: 2%;
          }
          .value {
            display: inline-block;
            color: #73ae2e;
            font-weight: 600;
          }
        }
      }
      .cancelOrder {
        color: #d9736f;
        font-size: 1em;
        text-align: center;
        line-height: 2;
        border: 1px solid #cf2525;
        border-radius: 2em;
        display: block;
        width: 80%;
        margin: auto;
        cursor: pointer;
        &.toConfirm {
          border: none;
          > div {
            margin-left: 1%;
            line-height: 2;
            border: 1px solid transparent;
            width: 18%;
            text-align: center;
            &:hover {
              border: 1px solid #cf2525;
              border-radius: 2em;
            }
          }
        }
        > div {
          color: #d9736f;
          padding: 0 3% 0 3%;
          display: inline-block;
        }
      }
    }
    hr {
      border: none;
      background-color: #e3e1e1;
      width: 100%;
      height: 1px;
    }
    .footer {
      text-align: right;
      width: 96%;
      overflow: hidden;
      line-height: normal;
      padding: 2% 1% 2% 1%;
      z-index: 1;
      clear: both;
      > div {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        cursor: pointer;
        &.change {
          margin: 0;
          color: #fff;
          font-size: 1em;
          text-align: center;
          background-color: #ff7113;
          border-radius: 1.4em;
          display: inline-block;
          width: auto;
          position: relative;
          overflow: hidden;
          line-height: normal;
          padding: 1% 2.41em;
        }
        &.cancel {
          color: #2e7dad;
          line-height: normal;
          padding: 1% 1.72em;
          text-decoration: underline;
        }
      }
    }
  }
}
