@import 'styles/colors.scss';
.client {
  width: 100%;
  min-height: 100%;
  background: #fff;
  position: relative;
  padding-bottom: 4%;
  &:before {
    position: relative;
    content: '';
    padding-top: 166%;
    display: block;
  }
  .search {
    background: #EDF8F3;
    padding: 7.5% 3.3% 2.5%;
    box-shadow: 0 1px 3px 1px #e4e7e9;
    position: absolute;
    width: 100%;
    height: 40%;
    left: 0;
    top: 0;
    .title {
      color: #41B1E3;
      font-size: 1.125em;
      padding-left: 1.7%;
    }
    .field {
      padding-top: 8%;
      margin-bottom: 2.3%;
      .label {
        font-size: 0.835em;
        color: #b2b2b6;
        padding-left: 1%;
        padding-bottom: 1.4%;
        letter-spacing: -0.05%;
      }
      input {
        width: 100%;
        background: white;
        font-size: 1em;
        color: #434343;
        letter-spacing: -0.08%;
        border: 1px solid #C9C9C9;
        border-bottom: 1px solid #d5d5d5;
        border-radius: 0.2em;
        line-height: 1.7;
        padding: 0 1%;
        &.error {
          border-color: #eb5757;
        }
      }
      input::-webkit-input-placeholder {color:#7d7d7d;font-style:italic;}
      input::-moz-placeholder          {color:#7d7d7d;font-style:italic;}/* Firefox 19+ */
      input:-moz-placeholder           {color:#7d7d7d;font-style:italic;}/* Firefox 18- */
      input:-ms-input-placeholder      {color:#7d7d7d;font-style:italic;}
    }
    .avatar {
      position: absolute;
      right: 6.4%;
      top: 7.7%;
      width: 17.5%;
      height: 17.5%;
      border-radius: 50%;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  .body {
    padding-bottom: 20%;
    width: 100%;
    height: 60%;
    left: 0;
    top: 40%;
    position: absolute;
    overflow: hidden;
    .emptyClients {
      color: #b2b2b6;
      text-align: center;
      padding-top: 70%;
      line-height: 1.35;
      letter-spacing: -0.07%;
      position: relative;
      .text {
        width: 92%;
        font-size: 0.75em;
        left: 4%;
        position: absolute;
        top: 80%;
      }
      &.searchInfo {
        background: url('./assets/search-icon.svg') 44.6% 51.3% no-repeat;
        background-size: 24.5%;
      }
      &.newClient {
        background: url('./assets/new-client-image.svg') center 51% no-repeat;
        background-size: 22.9%;
      }
      &.oldClient {
        background: url('./assets/badge-icon.svg') center 51% no-repeat;
        background-size: 27%;
      }
      &.notFound {
        background: url('./assets/client-not-found.svg') center 51% no-repeat;
        background-size: 23%;
      }
    }
    .list {
      .title {
        font-size: 0.835em;
        letter-spacing: -0.07%;
        font-style: italic;
        color: #b2b2b6;
        padding: 4.4% 0 1% 3.1%;
      }
      .item {
        position: relative;
        padding: 6.3% 3.2% 5.5%;
        //border-bottom: 1px solid #d5d5d5;
        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          width: 91.4%;
          background: #d5d5d5;
          height: 1px;
        }
        .avatar {
          position: relative;
          width: 10%;
          height: auto;
          display: inline-block;
          vertical-align: top;
          background: #f9f9f9;
          border-radius: 50%;
          text-align: center;
          margin-top: 0.7%;
          &:before {
            content: '';
            width: 1px;
            padding-top:100%;
            display: inline-block;
            vertical-align: middle;
          }
          span {
            display: inline-block;
            vertical-align: middle;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .info {
          display: inline-block;
          vertical-align: top;
          width: 78.2%;
          padding-left: 3.2%;
          .name {
            color: $second-color;
            font-size: 1em;
            letter-spacing: -0.09%;
            line-height: 1.3;
          }
          .phone {
            font-size: 0.835em;
            letter-spacing: -0.07%;
            color: #7d7d7d;
          }
        }
      }
    }
  }
  .confirmButton {
    position: absolute;
    left: 0;
    bottom: -19.4%;
    height: 19.4%;
    width: 100%;
    text-align: center;
    transition: bottom 0.2s ease-out 0s;
    z-index: 5;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $second-color;
      opacity: 0.8;
      z-index: 1;
    }
    &.visible {
      bottom: 0;
    }
    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 91.5%;
      color: $second-color;
      background: #fff;
      border-radius: 7%;
      font-size: 0.975em;
      line-height: 2.8;
      z-index: 2;
    }
  }
}