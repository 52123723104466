@import '../../../../../../../styles/themes/default/colors.scss';

.header {
  padding-bottom: 4.8%;
}
.menu {
  display: inline-block;
  color: #5f7f77;
  text-decoration: underline;
  line-height: 2.8;
  border-radius: 2.6em;
  text-align: center;
  padding: 0 2.5% 0 6.5%;
  margin-right: 3%;
  font-size: 0.9em;
  //&.active {
  //  background-color: #e7ecea;
  //}
  &.private {
    background: url("../../../assets/private.png") 13% 0.8em no-repeat;
    &.active {
      background-color: #e7ecea;
    }
  }
  &.car {
    background: url("../../../assets/car.png") 13% 0.9em no-repeat;
    &.active {
      background-color: #e7ecea;
    }
  }
  &.insurance {
    background: url("../../../assets/insurance.png") 13% 0.8em no-repeat;
    &.active {
      background-color: #e7ecea;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #e7ecea;
  }
}
.cars {
  .car {
    position: relative;
    height: 77px;
    overflow: hidden;
    background-color: #f9f9f9;
    border: 1px solid #d6d6d6;
    padding: 1.5% 3%;
    margin-right: 4%;
    margin-bottom: 3%;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: height 0.4s ease-out 0s;
    -moz-transition: height 0.4s ease-out 0s;
    -o-transition: height 0.4s ease-out 0s;
    transition: height 0.4s ease-out 0s;
    &.open {
      height: 425px;
    }
    &.companyCarOpen {
      height: 470px;
    }
    .openButtonContainer {
      position: absolute;
      height: 77px;
      width: 77px;
      right: 0;
      top: 0;
      .openButton {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 30px;
        top: 25px;
        border-top: 2px solid transparent;
        border-right: 2px solid #c3c7c7;
        border-left: 2px solid transparent;
        border-bottom: 2px solid #c3c7c7;
        background-color: transparent;
        transform: rotate(45deg);
        transition: all .4s ease 0s;
        &.open {
          top: 30px;
          transform: rotate(-135deg);
          border-right-color: $orange;
          border-bottom-color: $orange;
        }
      }
    }
    hr {
      position: absolute;
      width: 94%;
      top: 76px;
    }
    .btnContainer {
      margin: 0 1% 0 76.5%;
    }
  }
  .btnContainer {
    margin: 0 6% 0 70.5%;
  }
}
.data {
  color: #696e6f;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 3%;
  .title {
    font-size: 0.9em;
  }
  .changeValue {
    display: inline-block;
    position: relative;
    max-width: 90%;
    cursor: pointer;
    &.immutable {
      cursor: auto;
      .value {
        max-width: 100% !important;
      }
    }
    .value {
      font-size: 1.5em;
      line-height: 1.3;
      display: inline-block;
      max-width: 92%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.orange {
        color: #ff7113;
      }
    }
    .power {
      display: inline-block;
      color: #ff7113;
    }
    .select {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      background: url("../../../assets/select.png") 100% 50% no-repeat;
    }
    .edit {
      width: 1.5em;
      height: 1.705em;
      display: inline-block;
      background: url("../../../assets/pencil.png") 100% 50% no-repeat;
    }
    input {
      border: 1px solid #bec3c3;
      border-radius: .25em;
      width: 11em;
      line-height: 1.5;
      padding: 1% 3%;
      display: inline-block;
    }
    .editOk {
      width: 2em;
      height: 2em;
      display: inline-block;
      vertical-align: bottom;
      background: url("../../../assets/editOk.png") 60% 50% no-repeat;
    }
    .outerMenu {
      box-sizing: content-box;
      width: 100%;
      //width: auto;
      min-width: 200px;
      height: auto;
      max-height: 300px;
      position: absolute;
      background-color: white;
      border: 1px solid #ff7113;
      padding: 10px 10px 10px 10px;
      //left: 0;
      top: 140%;
      text-align: left;
      line-height: 1.8;
      white-space: normal;
      z-index: 1;
      //&:before {
      //  content: '';
      //  position: absolute;
      //  top: -7px;
      //  right: 25px;
      //  display: block;
      //  height: 11px;
      //  width: 11px;
      //  border-top: solid 1px transparent;
      //  border-right: solid 1px #ff7113;
      //  border-left: solid 1px transparent;
      //  border-bottom: solid 1px #ff7113;
      //  background-color: white;
      //  transform: rotate(-135deg);
      //}
      .item {
        font-size: 0.9rem;
        color: #868887;
        margin: 0 10px 0 16px;
        padding: 0 6px 0 6px;

        cursor: pointer;
        &:hover {
          background-color: #dff6f1;
        }
      }
    }
  }
  .colorValue {
    color: #1abc9c;
    padding-bottom: 7%;
    font-size: 1.5em;
    &:last-child {
      padding-bottom: 0;
    }
  }
}
.fieldsData {
  @extend .data;
  width: 40%;
  &:nth-child(even) {
    margin-left: 13.3%;
    .triangle {
      display: none;
    }
    .outerMenu {
      right: -1.5em;
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        right: 25px;
        display: block;
        height: 11px;
        width: 11px;
        border-top: solid 1px transparent;
        border-right: solid 1px #ff7113;
        border-left: solid 1px transparent;
        border-bottom: solid 1px #ff7113;
        background-color: white;
        transform: rotate(-135deg);
      }
    }
  }
  &:nth-child(odd) {
    .triangle {
      content: '';
      position: absolute;
      top: 125%;
      right: 3.5px;
      z-index: 2;
      display: block;
      height: 11px;
      width: 11px;
      border-top: solid 1px transparent;
      border-right: solid 1px #ff7113;
      border-left: solid 1px transparent;
      border-bottom: solid 1px #ff7113;
      background-color: white;
      transform: rotate(-135deg);
    }
    .outerMenu {
      left: 0;
    }
  }
}
.carData {
  @extend .data;
  width: 30%;
  margin-left: 5%;
  padding-bottom: 4.6%;
  .value {
    max-width: 88% !important;
  }
  &:nth-child(3n + 2) {
    .triangle {
      display: none;
    }
    .outerMenu {
      right: -1.5em;
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        right: 25px;
        display: block;
        height: 11px;
        width: 11px;
        border-top: solid 1px transparent;
        border-right: solid 1px #ff7113;
        border-left: solid 1px transparent;
        border-bottom: solid 1px #ff7113;
        background-color: white;
        transform: rotate(-135deg);
      }
    }
  }
  &:nth-child(3n + 3) {
    .triangle {
      display: none;
    }
    .outerMenu {
      right: -1.5em;
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        right: 25px;
        display: block;
        height: 11px;
        width: 11px;
        border-top: solid 1px transparent;
        border-right: solid 1px #ff7113;
        border-left: solid 1px transparent;
        border-bottom: solid 1px #ff7113;
        background-color: white;
        transform: rotate(-135deg);
      }
    }
  }
  &:nth-child(3n + 1) {
    margin-left: 0;
    .triangle {
      content: '';
      position: absolute;
      top: 126%;
      right: 3.5px;
      z-index: 2;
      display: block;
      height: 11px;
      width: 11px;
      border-top: solid 1px transparent;
      border-right: solid 1px #ff7113;
      border-left: solid 1px transparent;
      border-bottom: solid 1px #ff7113;
      background-color: white;
      transform: rotate(-135deg);
    }
    .outerMenu {
      left: 0;
    }
  }
}
.margin {
  margin-left: 53.3%;
}
.scrollTrack {
  position: absolute;
  width: 3px !important;
  transition: opacity 200ms;
  right: 0px;
  top: 7%;
  height: 89%;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 110%;
    background-color: #c0bcbc;
    top: -5%;
    left: 1px;
  }
}
.scrollThumb {
  width: 3px;
  //height: 16px !important;
  background-color: #949191;
  border-radius: 1px;
  overflow: hidden;
}
.errorMenu {
  &:before {
    content: '';
    position: absolute;
    top: -0.4em;
    left: 40%;
    display: block;
    height: 11px;
    width: 11px;
    border-top: solid 1px transparent;
    border-right: solid 1px #ce252c;
    border-left: solid 1px transparent;
    border-bottom: solid 1px #ce252c;
    background-color: white;
    transform: rotate(-135deg);
  }
  width: 100%;
  min-width: 200px;
  height: auto;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ce252c;
  color: #ce252c;
  padding: 10px;
  top: 121%;
  //left: -10%;
  line-height: 1.8;
  white-space: normal;
  z-index: 1;
  cursor: default;
}
.addCar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.8);
  z-index: 1001;
  text-align: center;
  :global {
    .club-datepicker {
      font-size: 1.5em;
      .dpSwitcher {
        width: 0.7em;
        height: 0.7em;
        &:after {

        }
      }
      .text {
        span {
          display: none;
        }
      }
    }
  }
  .newCarWrapper {
    position: relative;
    max-height: 100vh;
    display: inline-block;
    vertical-align: middle;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    padding: 2% 4%;
    text-align: left;
    .headerCar {
      font-size: 2.2em;
      padding-bottom: 5%;
      color: #e67e22;
      //text-align: center;
    }
    .newCar {
      width: 800px;
      .buttons {
        text-align: center;
        vertical-align: middle;
        margin-top: 8%;
        .cancel {
          color: #2e7dad;
          display: inline-block;
          vertical-align: middle;
          font-size: 1.1em;
          border-bottom: 2px solid #acc5d9;
          margin-right: 5%;
          cursor: pointer;
        }
        button {
          width: 30%;
          display: inline-block;
          vertical-align: middle;
        }
        &:before {
          content: '';
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
