:global {
  .clientTop {
    height: 48px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    box-shadow: 0 0 10px 0.5px rgba(0,0,0,.1);
    position: relative;
    .wrapper {
      max-width: 1188px;
      height: 48px;
      position: relative;
      margin: auto;
    }
    .arrow {
      content: '';
      position: absolute;
      width: 38px;
      height: 38px;
      left: 50%;
      top: -19px;
      margin-left: -19px;
      background-color: white;
      border-radius: 50%;
      box-shadow: 0 0 10px 0.5px rgba(0,0,0,.1);
      clip: rect(-6px,45px,19px,-6px);
      &:before {
        content: '';
        position: absolute;
        left: 14px;
        top: 12px;
        width: 10px;
        height: 10px;
        border: 1px solid;
        border-top-color: #87b7e0;
        border-left-color: #87b7e0;
        border-right-color: transparent;
        border-bottom-color: transparent;
        transform: rotate(45deg);
        transition: all 0.2s ease-in 0.2s;
      }
      &.open {
        &:before {
          top: 6px;
          transform: rotate(225deg);
        }
      }
    }
    .text {
      position: absolute;
      width: 300px;
      top: 0;
      left: 50%;
      margin-left: -150px;
      font-size: 1em;
      line-height: 2.8;
      text-align: center;
      color: #a8a4a4;
    }
    .search {
      width: 248px;
      position: absolute;
      right: 10%;
      top: 20%;
      margin-left: -124px;
      background-position: 220px center;
      background-repeat: no-repeat;
      z-index: 2;
      &:active, &:focus {
        width: 810px;
        background-position: 782px center;
      }
      transition: all 0.3s ease-in 0s;
    }
    .label {
      z-index: 1;
      position: absolute;
      width: 300px;
      top: 0;
      right: 10%;
      margin-left: -150px;
      text-align: center;
      margin-right: 0;
      transition: all 0s ease-in 0s;
    }
    .search:focus+.label {
      margin-right: 810px;
      transition: all 0s ease-in 0.3s;
    }
    .addClient {
      border-radius: 2.6em;
      border: 1px solid #bec3c3;
      color: #bec3c3;
      line-height: 1.6;
      padding: 0 0.5em 0 0.5em;
      position: absolute;
      right: 6%;
      top: 20%;
      text-align: center;
      cursor: pointer;
    }
    &__name {
      position: absolute;
      width: 300px;
      top: 0;
      left: 10%;
      font-size: 1em;
      line-height: 2.8;
      text-align: center;
      color: #e7832a;
    }
    &.open {
      .clientTop__name {
        display: none;
      }
    }
  }
}
