.wrapper {
  width: 1186px;
  margin: auto;
  background-color: #fff;
  overflow: hidden;
  margin-top: 42px;
  .body {
    padding: 3% 5% 0;
  }
  .excel {
    color: #379133;
    margin-right: 5%;
    display: inline-block;
    line-height: 2;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #2e5d2a;
    }
    &.processing {
      text-decoration: none;
    }
  }
  .search {
    .text {
      display: inline-block;
      font-size: 1em;
      line-height: 2.8;
      color: #a8a4a4;
      padding-right: 30px;
    }
    input {
      width: 350px;
      border-radius: 2.6em;
      border: 1px solid #bec3c3;
      line-height: 1.6;
      padding: 0 1.7em 0 .94em;
      background: url('../../../assets/loupe-grey.png') 97% center no-repeat;
    }
    .addClient {
      display: inline-block;
      border-radius: 50%;
      border: 1px solid #bec3c3;
      color: #bec3c3;
      line-height: 1.6;
      padding: 0 .5em;
      margin-left: 1.3em;
      text-align: center;
      cursor: pointer;
    }
  }
  .client {
    border-bottom: 1px solid #f3f3f3;
    color: #696e6f;
    line-height: 1;
    font-size: 1.1em;
    white-space: nowrap;
    cursor: pointer;
      &.noOrtus {
          color: #a5acae;
          .nick {
              color: #a5acae;
          }
      }
    &:hover {
      background-color: #f2f5f6;
    }
    &.header {
      color: #a5acae;
      border-bottom: 1px solid #a5acae;
      cursor: inherit;
      &:hover {
        background-color: inherit;
      }
      .nick {
        color: rgb(230, 126, 34);
      }
    }
    > div {
      padding: 2% 1%;
      vertical-align: top;
    }
    .nick {
      display: inline-block;
      width: 35%;
      cursor: pointer;
      margin-right: 1%;
      white-space: normal;
      color: rgb(26, 188, 156);

    }
    .phone {
      display: inline-block;
      width: 15%;
      margin-right: 1%;
      white-space: normal;
    }
    .comment {
      display: inline-block;
      width: 30%;
      white-space: normal;
      line-height: 1;
      color: #969e9f;
      font-size: 0.8em;
    }
    .birthday {
      display: inline-block;
      width: 17%;
      margin-right: 1%;
      white-space: normal;
    }
    &:last-child {
      border-bottom: 1px solid #a5acae;
    }
  }
  form {
    display: none;
  }
  input {
    //background-color: blue;
  }
  .modal {
    position: fixed;
    background-color: rgba(0,0,0,.8);
    width: 100%;
    height: 100%;
    z-index: 1002;
    top: 0;
    left: 0;
    text-align: center;
    display: none;
    &.show {
      display: block;
    }
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .content {
      position: relative;
      max-width: 500px;
      display: inline-block;
      vertical-align: middle;
      padding: 50px;
      background-color: #fff;
      text-align: left;
    }
    .text {
      display: inline-block;
      color: #696e6f;
    }
    .value {
      padding-left: 10px;
      display: inline-block;
      color: #e7832a;
    }
    .error {
      color: #c6272b;
    }
    .btnContainer {
      position: absolute;
      width: 70px;
      right: 50px;
      bottom: 10px;
    }
  }
  .exportPopup{
    position: fixed;
    right: 0;
    bottom: 0;
    color: white;
    padding: 20px;
    background-color: #4d4c4c;
    span {
      color: #5794b8;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
