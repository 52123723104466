@import './../../../../styles/themes/default/colors';

:global {
  .feedback-component {
    width: 1186px;
    margin: auto;
    background-color: white;
    overflow: hidden;
    color: #485051;
    margin-top: 42px;
    .feedback__header {
      width: 100%;
      padding-top: 2%;
      padding-bottom: 2%;
      background-color: #e8e8e8;
      //white-space: pre-wrap;
      &__link {
        text-align: center;
        display: table-cell;
        width: 1%;
        vertical-align: middle;
        white-space: nowrap;
        &--header {
          line-height: 1;
          font-size: 1.2rem;
          text-align: right;
          white-space: normal;
          width: 100%;
          text-align: center;
          display: none;
        }
        a {
          color: $selected-color;
          line-height: 1.6;
          font-size: 1.2rem;
          border-radius: 1.4rem;
          padding: 0 1.8rem 0 1.8rem;
          display: inline-block;
        }
        &:hover, &.active {
          a {
            background-color: white;
          }
        }
      }
    }
    .feedback__body {
      padding: 4.4% 4.5% 0;
    }
    &-feedback {
      &__item {
        background-color: #fff;
        border-bottom: 1px solid #f2f3f3;
        padding: 1% 1% 1% 1%;
        &:last-child {
          border-bottom: none;
        }
        .feedback {
          &__row {
            display: block;
            white-space: nowrap;
            line-height: 1.8;
            &:before {
              content: '';
              display: block;
              clear: both;
            }
          }
          &__number, &__name, &__text {
            display: inline-block;
            color: $primary-color;
          }
          &__number {
            color: $primary-color;
          }
          &__client, &__action, &__action {
            display: block;
            color: $selected-color;
          }
          &__number, &__client, &__text {
            width: 40%;
          }
          &__number {
            width: 5%;
          }


          &__service {
            width: 90%;
          }

        }

      }
    }
  }
}