.list {
  padding: 4.4% 5% 0 3%;
  color: #696e6f;
}
.employee {
  border-bottom: 1px solid #f2f3f3;
  line-height: 3;
  &:before {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle;
  }
  .dot {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-left: 2%;
  }
  .dotTrue {
    @extend .dot;
    background: #067d2a;
  }
  .dotFalse {
    @extend .dot;
    background: #ab3633;
  }
  .name {
    width: 75%;
    font-size: 1.1em;
    padding-left: 3%;
    display: inline-block;
    vertical-align: middle;
  }
  .nameInactive {
    @extend .name;
    color: #ab3633;
  }
  .working {
    width: 9%;
    text-align: center;
    font-size: 0.8em;
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
  }
  .workingInactive {
    @extend .working;
    &:hover {
      border-radius: 2em;
      background-color: #8fcca1;
      color: #fff;
      cursor: pointer;
    }
  }
  .workingActive {
    @extend .working;
    border-radius: 2em;
    color: #fff;
    background: #067d2a;
  }
  .notWorking {
    width: 11%;
    text-align: center;
    font-size: 0.8em;
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
  }
  .notWorkingInactive {
    @extend .notWorking;
    &:hover {
      border-radius: 2em;
      background-color: #cc7c7c;
      color: #fff;
      cursor: pointer;
    }
  }
  .notWorkingActive {
    @extend .notWorking;
    border-radius: 2em;
    color: #fff;
    background: #ab3633;
  }
  &:last-child {
    border: none;
  }
}
.btnContainer {
  width: 36%;
  margin: auto;
  padding-top: 2.5%;
}