:global {
  .peninput {
    display: inline-block;
    color: gray;
    &__label {
      font-size: 1em;
      color: inherit;
      vertical-align: middle;
      input {
        border: 1px solid #bec3c3;
        border-radius: .25em;
        width: 80%;
        line-height: 1.5;
        padding: 1% 3%;
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
      }
    }
    &-trigger {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      background: url("assets/pencil.png") left top no-repeat;
      background-size: contain;
      vertical-align: middle;
      cursor: pointer;
      &--save {
        width: 1rem;
        height: 1rem;
        margin-left: 10px;
        display: inline-block;
        background: url("assets/editOk.png") left top no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
    }
  }
}