.container {
  width: 1186px;
  margin: auto;
  background-color: #fff;
  overflow: hidden;
  margin-top: 42px;
  .menu {
    width: 100%;
    background-color: #d66c1e;
    color: white;
    font-size: 1.1em;
    .link {
      position: relative;
      width: 25%;
      display: inline-block;
      padding: 1.8% 1% 1.8% 7%;
      background-color: #d66c1e;
      cursor: pointer;
      color: white;
      &.active {
        background-color: #cd5c17;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-left: .9em solid transparent;
          border-right: .9em solid transparent;
          border-top: .9em solid #cd5c17;
          left: 46%;
          bottom: -.8em;
        }
      }
      &.back {
        width: 29%;
        padding-left: 5.9%;
        background: #cd5c17 url('../../assets/left-arrow.png') no-repeat 8% 50%;
        background-size: 7%;
      }
      &:hover {
        background-color: #cd5c17;
      }
      &--common {
        //background-image: url(../Main/assets/icon-common.png);
      }
      &--services {
        //background-image: url(../Main/assets/icon-services.png);
      }
      &--spec {
        //background-image: url(../Main/assets/icon-specs.png);
      }
      &--resources {
        //background-image: url(../Main/assets/icon-resources.png);
      }
      &--card {
        //background-image: url(../Main/assets/icon-card.png);
      }
    }
    .link+.link {
      border-left: 1px solid #cd5c17;
    }
  }
}
