.anchorStatistic {
  height: 100%;
  padding-left: 4%;
  color: #9c9c9c;
  .header {
    font-size: 2.2em;
    line-height: 1.8;
    vertical-align: middle;
    padding: 0;
    .text {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }
  .period {
    font-size: 1.6em;
    color: #9c9c9c;
    vertical-align: middle;
    margin-bottom: 3%;
    .date {
      display: inline-block;
      margin-left: 1%;
      vertical-align: middle;
      > span {
        vertical-align: middle;
        display: inline-block;
      }
      :global {
        .club-datepicker {
          color: #1abc9c;
          display: inline-block;
          .text {
            color: #1abc9c;
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .modes {
    margin-bottom: 3%;
    .mode {
      display: inline-block;
      color: #5f7f77;
      text-decoration: underline;
      line-height: 2.8;
      border-radius: 2.6em;
      padding: 0 6.5% 0 6.5%;
      margin-right: 3%;
      font-size: 0.9em;
      background-position: left center;
      background-repeat: no-repeat;
      text-align: center;
      cursor: pointer;
      &.active {
        background-color: #e7ecea;
      }
      &.byAnchor {
        background-image: url('../Statistic/assets/anchor-icon.png');
      }
      &.byLid {
        background-image: url('../Statistic/assets/lid-icon.png');
      }
      &.byRegular {
        background-image: url('../Statistic/assets/regular-icon.png');
      }
    }
  }
  .statistics {
    position: relative;
    //height: 70%;
    height: 410px;
    padding: 0 0 2% 3.2%;
    overflow: hidden;
    .wrapper {
      display: block;
      width: 96%;
      .graphBlock {
        height: 400px;
        width: 100%;
        position: relative;
        overflow: hidden;
        .noData {
          position: relative;
          width: 100%;
          height: 100%;
          background-image: url('../Statistic/assets/no-data.png');
          background-position: center center;
          background-repeat: no-repeat;
          text-align: center;
          padding-top: 12%;
        }
      }
      .statusList {
        .total {
          display: block;
          font-size: 1em;
          line-height: 2;
          vertical-align: middle;
          margin-left: 3em;
          width: 90%;
          text-align: right;
          .count {
            vertical-align: middle;
            font-size: 1.4em;
            color: #e7832a;
            display: inline-block;
            margin-left: 2em;
          }
        }
        .status {
          display: block;
          font-size: 1em;
          line-height: 2.4;
          vertical-align: middle;
          border-bottom: 1px solid #f2f3f3;
          &:last-child {
            border-bottom: none;
          }
          &.active {
            &:before {
              background-image: url('../Statistic/assets/checkit-icon.png');
            }
          }
          &:before {
            content: ' ';
            display: inline-block;
            width: 2em;
            height: 2em;
            border-radius: 10%;
            border: 1px solid #bec3c3;
            vertical-align: middle;
            margin-right: 1em;
          }
          &.delivered {
            .title {
              &:before {
                background-color: #1dbd9d;
              }
            }
          }
          &.missed {
            .title {
              &:before {
                background-color: #ff9f5f;
              }
            }
          }
          &.moved {
            .title {
              &:before {
                background-color: #216997;
              }
            }
          }
          &.blacklisted {
            .title {
              &:before {
                background-color: #3c3c3c;
              }
            }
          }
          .title {
            vertical-align: middle;
            &:before {
              content: '';
              display: inline-block;
              width: 0.5em;
              height: 0.5em;
              border-radius: 50%;
              background-color: black;
              margin-right: 1em;
            }
            width: 70%;
            display: inline-block;
            color: #4e5c5d;
          }
          .count {
            vertical-align: middle;
            width: 20%;
            display: inline-block;
            text-align: right;
            font-size: 1.4em;
            color: #e7832a;
          }

        }
      }
    }
  }
}

:global {
  .select.callcenterStatisticEmployeeFilter {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    height: auto;
    font-size: 1em;
    .control {

      .value, .placeHolder {

      }
      .arrow {

      }
    }
  }
  .select.callcenterStatisticPeriodFilter {

    .control {

      .value, .placeHolder {
        padding: 0;
        width: auto;
        margin: 0;
        display: inline-block;
      }
      .arrow {
        border: 1px solid #e7832a;
        border-radius: 50%;
        width: 0.7em;
        height: 0.7em;
        position: relative;
        margin-left: .3em;
      }
    }
  }
  .scrollTrack {
    position: absolute;
    width: 3px !important;
    transition: opacity 200ms;
    right: 2%;
    top: 7%;
    height: 89%;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 104%;
      background-color: #c0bcbc;
      top: -2%;
      left: 1px;
    }
  }
  .scrollThumb {
    width: 3px;
    //height: 16px !important;
    background-color: #949191;
    border-radius: 1px;
    overflow: hidden;
  }
}