@import '../../../../styles/themes/default/colors.scss';

.orders {
  width: 1186px;
  margin: auto;
  background-color: white;
  overflow: hidden;
  color: #485051;
  margin-top: 42px;
  .menu {
    width: 100%;
    background-color: #586621;
    color: white;
    font-size: 1.1em;
    .link {
      position: relative;
      width: 25%;
      display: inline-block;
      padding: 1.8% 2% 1.8% 7%;
      cursor: pointer;
      &.ACTIVE {
        background: url('../../assets/active-icon.png') no-repeat 10% 48% #707e30;
      }
      &.EXPIRED {
        background: url('../../assets/expire-icon.png') no-repeat 9% 50% #707e30;
        padding-left: 7.5%;
        //padding-right:  2%;
      }
      &.CANCELED {
        background: url('../../assets/cancel-icon.png') no-repeat 10% 55% #707e30;
        padding-left:  6.6%;
      }
      &.EXECUTED {
        background: url('../../assets/execute-icon.png') no-repeat 8% 52% #707e30;
        padding-left:  6.5%;
      }
      &.current {
        background-color: transparent;
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-left: .9em solid transparent;
          border-right: .9em solid transparent;
          border-top: .9em solid #586621;
          left: 46%;
          bottom: -.8em;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
    .link+.link {
      border-left: 1px solid #586621;
    }
  }
  .wrapper {
    //padding: 0 3.4%;
    .header {
      padding: 2.5% 3.4% 1.6%;
      .title {
        font-size: 2.3em;
        letter-spacing: -0.5px;
        &.ACTIVE {
          span {
            color: #429624;
          }
        }
        &.EXPIRED {
          span {
            color: #c72c2c;
          }
        }
        &.CANCELED {
          span {
            color: #bababa;
          }
        }
        &.EXECUTED {
          span {
            color: #1abc9c;
          }
        }
      }
      .filter {
        font-size: 1.5em;
        padding-top: 0.6%;
        > div {
          display: inline-block;
          vertical-align: middle;
        }
        :global {
          .club-datepicker {
            margin: 0 1%;
          }
        }
      }
    }
    .list {
      overflow: hidden;
      background-color: #f6f6f6;
      >div {
        background-color: #fff;
        padding: 0 3.4%;
        border-bottom: 2px solid #e7e7e7;
        margin-bottom: 2%;
      }
      .date {
        padding: 2.6% 0;
        color: $selected-color;
        font-size: 1.5em;
      }
      .order {
        padding: 2.4% 0;
        margin: 0 0.5% 0 2.5%;
        border-top: 1px solid #f3f3f3;
        font-size: 1.1em;
        cursor: pointer;
        &:hover {
          background-color: #f2f5f6;
        }
        .time {
          display: inline-block;
          color: $selected-color;
          width: 9.2%;
          padding-left: 1%;
        }
        .clientName {
          display: inline-block;
          width: 40.8%;
        }
        .clientPhone {
          display: inline-block;
          width: 18%;
          text-align: right;
        }
        .orderNumber {
          display: inline-block;
          width: 18%;
          text-align: right;
        }
        .orderStatus {
          display: inline-block;
          color: #429624;
          width: 14%;
          text-align: right;
          padding-right: 1%;
          &.ACTIVE {
            color: #429624;
          }
          &.EXPIRED {
            color: #c72c2c;
          }
          &.CANCELED {
            color: #bababa;
          }
          &.EXECUTED {
            color: #1abc9c;
          }
        }
      }
    }
  }
}

.client {
  white-space: nowrap;
  .info {
    color: #485051;
    font-size: 1rem;
    display: inline-block;
  }
}
.clientFilter {
  position: relative;
  :global {
    .clientSearch {
      .label {
        display: none;
      }
    }
  }
}
.emptyBlock {
  height: 400px;
  position: relative;
}

  .orders-component {
    width: 1186px;
    margin: auto;
    background-color: white;
    overflow: hidden;
    color: #485051;
    margin-top: 42px;
    .orders__header {
      width: 100%;
      padding-top: 2%;
      padding-bottom: 2%;
      background-color: #e8e8e8;
      //white-space: pre-wrap;
      &__link {
        text-align: center;
        display: table-cell;
        width: 1%;
        vertical-align: middle;
        white-space: nowrap;
        &--header {
          line-height: 1;
          font-size: 1.2rem;
          text-align: right;
          white-space: normal;
          width: 100%;
          text-align: center;
          display: none;
        }
        a {
          color: $selected-color;
          line-height: 1.6;
          font-size: 1.2rem;
          border-radius: 1.4rem;
          padding: 0 1.8rem 0 1.8rem;
          display: inline-block;
        }
        &:hover, &.active {
          a {
            background-color: white;
          }
        }
      }
    }
    .orders__body {
      padding: 4.4% 4.5% 0;
    }
    &-orders {
      &__order {
        background-color: #fff;
        border-bottom: 1px solid #f2f3f3;
        padding: 1% 1% 1% 1%;
        &:last-child {
          border-bottom: none;
        }
        .order {
          &__row {
            display: block;
            white-space: nowrap;
            line-height: 1.8;
            &:before {
              content: '';
              display: block;
              clear: both;
            }
          }
           &__number, &__name, &__price, &__service {
            display: inline-block;
            color: $primary-color;
          }
          &__client, &__action, &__action {
            display: block
          }
          &__number, &__client {
            width: 40%;
            color: $selected-color;
            float: left;
          }
          &__actions {
            color: $selected-color;
            float: right;
            position: relative;
            color: #ff7113;
            height: auto;
            margin-bottom: .7rem;
            padding: 0 0 0 2%;
            width: 14%;
            white-space: nowrap;
            vertical-align: middle;
            &--executed {
              color: $some-good-color;
            }
            &--active {
              border: 1px solid #ff7113;
              border-radius: 1.2em;
            }
            &__action {
              color: $selected-color;
            }
            div.clubselect {
              display: inline-block;
              margin-left: 10%;
              cursor: pointer;
              width: 20%;
              vertical-align: middle;
              .control {
                border: none;
                .placeHolder {
                  display: none;
                }
              }
            }
          }

          &__number {
            width: 5%;
          }


          &__service {
            width: 90%;
          }

        }

      }
    }
  }
