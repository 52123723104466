.wrapper {
  height: 100%;
  width: 100%;
}
.element {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: -3px 0 5px -1px rgba(0,0,0,.1);
}
.header {
  width: 100%;
  position: relative;
  z-index: 3;
  .menu {
    height: 100%;
    width: 25%;
    max-width: 25%;
    display: inline-block;
    position: relative;
    padding: 1% 0;
    background-position: 15% 0;
    background-repeat: no-repeat;
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    .text {
      padding-left: 34%;
      color: #a8a4a4;
      font-size: 1.1em;
      display: inline-block;
      vertical-align: middle;
    }
    &:hover, &.active {
      cursor: pointer;
      background-position: 15% 100%;
      .text {
        color: #5499d3;
      }
    }
  }
}
.boardComp {
  background-image: url("../../assets/boardComp.png");
}
.history {
  background-image: url("../../assets/history.png");
}
.mainInfo {
  background-image: url("../../assets/mainInfo.png");
}
.privateInfo {
  background-image: url("../../assets/privateInfo.png");
}
.clientHeader {
  width: 100%;
  padding: 2% 0 2% 3.6%;
  height: 18.75%;
  .client {
    float: left;
    width: 70%;
    position: relative;
    &:hover {
      .tooltip {
        display: block;
      }
      .shadowTriangle {
        display: block;
      }
    }
    .tooltip {
      position: absolute;
      bottom: 0;
      width: auto;
      background-color: white;
      color: #ff7113;
      text-align: center;
      padding: 1% 1%;
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      display: none;
      white-space: nowrap;
      z-index: 3;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        position: absolute;
        bottom: 100%;
        left: 2.85em;
      }
      &.hidden {
        display: none !important;
      }
    }
    .shadowTriangle {
      position: absolute;
      bottom: 31%;
      left: 3em;
      background-color: #fff;
      height: 11.3px;
      width: 11.3px;
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transform: rotate(45deg);
      display: none;
      &.hidden {
        display: none !important;
      }
    }
    .name {
      color: #e7832a;
      .changeValue {
        display: inline-block;
        cursor: pointer;
        width: 100%;
        .value {
          font-size: 2em;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 93%;
          overflow: hidden;
          line-height: normal;
        }
        .edit {
          width: 2em;
          height: 2em;
          display: inline-block;
          background: url("../../assets/pencil.png") 60% 50% no-repeat;
        }
        input {
          border: 1px solid #bec3c3;
          border-radius: .25em;
          width: 80%;
          line-height: 1.5;
          padding: 1% 3%;
          display: inline-block;
          margin-bottom: 1.5%;
        }
        .editOk {
          width: 2em;
          height: 2em;
          display: inline-block;
          vertical-align: bottom;
          margin-bottom: 1.5%;
          background: url("../../assets/editOk.png") 60% 50% no-repeat;
        }
      }
      .constValue {
        @extend .changeValue;
        .value {
          max-width: 100%;
        }
        cursor: auto;
      }
    }
    .phone {
      font-size: 1.1em;
      padding-top: 0.8em;
      color: #485051;
    }
  }
  .status {
    font-size: 1.5em;
    display: block;
    float: left;
    width: 30%;
    padding: 1% 7% 0 0;
    &.inactive {
      color: #c01a1a;
    }
    &.lid {
      color: #696e6f;
    }
    &.active {
      color: #6fceb8;
    }
    &.sleep {
      color: #ffa59c;
    }
  }
}
.body {
  height: 87.7%;
  position: relative;
}
.record {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 20.5% 2% 20.5%;
  text-align: center;
}
.recordButton {
  color: white;
  font-size: 1.1em;
  text-align: center;
  line-height: 2.4;
  background-color: #1abc9c;
  border-radius: 2em;
  display: inline-block;
  width: 60%;
  &:hover {
    cursor: pointer;
  }
}
.activateButton {
  display: inline-block;
  width: 40%;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.triangleDown {

}
.scrollBlock {
  height: 80%;
  padding: 0 0 2% 3.2%;
}
.inactiveClient {
  height: 100%;
  padding: 2.2% 0 2% 0;
  .clientHeader {
    width: auto;
    padding: 0;
    padding-bottom: 3%;
    margin-right: 4%;
    margin-left: 4.6%;
    height: 14.9%;
    border-bottom: 1px solid #d3d3d3;
  }
  .body {
    height: 87%;
    position: relative;
    color: #696e6f;
    //margin-top: 3%;
    padding-top: 3.5%;
    padding-left: 4.6%;
    padding-right: 4%;
    .title {
      font-size: 0.9em;
    }
    .text {
      display: inline-block;
      max-width: 80%;
      font-size: 1.5em;
      padding-top: 0.5%;
      cursor: pointer;
      .edit {
        width: 1em;
        height: 1em;
        display: inline-block;
        vertical-align: bottom;
        background: url("../../assets/pencil.png") 60% 0 no-repeat;
      }
    }
    textarea {
      border: 1px solid #bec3c3;
      border-radius: 0.25em;
      font-size: 1.1em;
      color: #485051;
      width: 80%;
      margin-top: 1%;
      padding: 2% 0 2% 2%;
      resize: none;
    }
    .editOk {
      width: 2em;
      height: 2em;
      display: inline-block;
      vertical-align: bottom;
      background: url("../../assets/editOk.png") 60% 50% no-repeat;
      cursor: pointer;
    }
  }
}
.activateClient {
  padding: 5% 36% 0 30.5%;
  .activateClientButton {
    border-radius: 2.6em;
    border: 1px solid #e7832a;
    color: #e7832a;
    text-align: center;
    font-size: 1.1em;
    line-height: 2.35;
    &:hover {
      cursor: pointer;
    }
  }
}
.scrollTrack {
  position: absolute;
  width: 3px !important;
  transition: opacity 200ms;
  right: 2%;
  top: 7%;
  height: 89%;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 104%;
    background-color: #c0bcbc;
    top: -2%;
    left: 1px;
  }
}
.scrollThumb {
  width: 3px;
  //height: 16px !important;
  background-color: #949191;
  border-radius: 1px;
  overflow: hidden;
}
.startWindow {
  padding-top: 23.5%;
  text-align: center;
  .icons {
    .search {
      display: inline-block;
      vertical-align: middle;
      background: url("../../assets/binoculars.png") 0 0 no-repeat;
      width: 124px;
      height: 124px;
    }
    .or {
      display: inline-block;
      vertical-align: middle;
      margin: 0 3%;
      background: url("../../assets/or.png") 0 0 no-repeat;
      width: 41px;
      height: 25px;
    }
    .add {
      display: inline-block;
      vertical-align: middle;
      background: url("../../assets/addClientBig.png") 0 0 no-repeat;
      width: 124px;
      height: 124px;
    }
  }
  .startText {
    font-size: 1.1em;
    font-weight: 600;
    padding-top: 5%;
    color: #c9c9c9;
  }
}