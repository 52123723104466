:global {
  .createCompany {
    .page {
      width: 100%;
      height: auto;
      background: url('../assets/login-page.jpg');
      background-size: cover;
      min-height: 100vh;
      position: relative;
      &.error {
        h1 {
          color: white;
          font-weight: 300;
          font-size: 1.7em;
          line-height: 1.1;
          margin: 0;
          padding-bottom: 4%;
        }
      }
    }

    .inputs {
      padding: 2%;
      background: rgba(0, 0, 0, 0.65);
      border-bottom: 1px solid #e2e6ec;
      margin: auto;
      width: 100%;
      position: absolute;
      bottom: 0;
      min-height: 31%;
      .wrapper {
        width: 30%;
        min-width: 560px;
        margin: auto;
        .row {
          padding-bottom: 4%;
          position: relative;
          overflow: visible;
        }
        input {
          border-radius: .25em;
          line-height: 1.5;
          padding: 1% 3%;
          display: inline-block;
          vertical-align: middle;
          font-size: 1.8em;
          background-color: rgba(83, 122, 122, 0.25);
          border: solid 1px #33545d;
          color: white;
          width: 100%;
          &[type="password"] {
            padding-right: 30%;
          }
        }
      }
      @media(min-width: 768px) {
        padding: 10px;
      }
    }
    .row {
      .link {
        position: absolute;
        right: 5%;
        top: 23%;
        font-size: 0.8em;
        color: #989897;
        text-decoration: underline;
        cursor: pointer;
      }
      select {
        position: absolute;
        left: 101%;
        height: 72%;
        background-color: rgba(83,122,122,.25);
        border: 1px solid #33545d;
        border-radius: .25em;
        color: #fff;
      }
      select option {
        background: rgba(0,0,0,0.6);
      }
    }
    h1 {
      color: white;
      font-weight: 300;
      font-size: 2.5em;
      line-height: 1.2;
      margin: 0;
      padding-bottom: 4%;
      &.error {
        color: #9d223c;
        font-size: 1em;
        line-height: 3;
      }
    }

    .title {
      color: #fff;
      position: absolute;
      left: 0;
      text-align: center;
      bottom: 4%;
      font-weight: 200;
      font-size: 0.88em;
      width: 100%;
    }

    .submit {
      > div {
        line-height: 1.4;
        background-color: #6aa723;
        font-size: 1.8em;
        display: block;
        width: 100%;
        height: 1.7em;
        border-radius: .85em;
        padding: 0 .85em;
        border: 1px solid #6aa723;
        text-align: center;
        color: #485051;
        cursor: pointer;
        &.disabled {
          background-color: #9c9c9c;
          border: 1px solid #9c9c9c;
          cursor: not-allowed;
        }
      }
    }
  }
}
