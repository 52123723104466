@import '../../../../styles/themes/default/colors.scss';

.pollCard {
  position: fixed;
  background-color: rgba(0, 0, 0, .8);
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  .content {
    position: relative;
    margin: 7.2% auto;
    width: 690px;
    background-color: #f6f6f6;
    .header {
      background-color: #fff;
      color: $h-color;
      font-size: 2.2em;
      line-height: 3.2;
      padding-left: 15.7%;
      letter-spacing: 0.015em;
    }
    .body {
      color: $grey-color;
      padding: 3% 7.2% 6%;
      .clientBlock {
        border-bottom: 1px solid $grey-border-color;
        .dataBlock {
          width: 58%;
          display: inline-block;
          vertical-align: top;
          padding-bottom: 6.2%;
          .title {
            padding-left: 2px;
            font-size: 0.75em;
          }
          .data {
            display: inline-block;
            padding-top: 3px;
            font-size: 1.1em;
          }
          .phone {
            padding-top: 6px;
            font-size: 0.9em;
          }
          &:nth-child(2n) {
            width: 42%;
          }
        }
      }
      .pollBlock {
        padding-top: 3%;
        .title {
          font-size: 1.1em;
        }
        .value {
          color: #5d7d75;
          padding: 1% 0 7%;
          font-size: 1.5em;
          .li {
            height: 1.8em;
            padding-top: 1%;
            &:before {
              content: '';
              height: 100%;
              vertical-align: middle;
              display: inline-block;
            }
            .circle {
              width: 2px;
              height: 2px;
              display: inline-block;
              vertical-align: middle;
              background: #5d7d75;
              border-radius: 50%;
              margin-right: 1.5%;
            }
          }
        }
      }
      .button {
        width: 47%;
        margin: 2.7% auto 0;
        background-color: $h-color;
        color: #fff;
        padding: 1.5% 5%;
        font-size: 1.1em;
        border-radius: 2em;
        text-align: center;
      }
    }
  }
  .orange {
    color: $orange;
  }
  .green {
    color: $h-color;
  }
}
