.search {
  position: relative;
  input {
    width: 48%;
    border-radius: 2.6em;
    border: 1px solid #bec3c3;
    line-height: 1.6;
    padding: 0 1.7em 0 0.94em;
    background: url("../../../assets/loupe.png") 97% 50% no-repeat;
  }
  input::-webkit-input-placeholder {font-size: 0.8em; opacity: 0.3;}
  input:-moz-placeholder {font-size: 0.8em; opacity: 0.3;}
  .submit {
    position: absolute;
    right: 5%;
    top: 0.5em;
    height: 1.7em;
    width: 10%;
    &:hover{
      cursor: pointer;
    }
  }
}
.items {
  .date {
    padding-top: 2.7%;
    color: #1abc9c;
    font-size: 1.1em;
  }
}
.item {
  border: 1px solid #e0e0e0;
  background-color: #f7f7f7;
  color: #696e6f;
  width: 93%;
  margin-top: 1.4%;
  padding-left: 11%;
  padding-bottom: 1.5%;
  cursor: pointer;
  .itemHeader {
    padding-top: 1.2%;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
    }
  }
  .title {
    width: 70%;
    font-size: 1.5em;
    display: inline-block;
    vertical-align: middle;
  }
  .number {
    width: 27%;
    text-align: right;
    font-size: 0.9em;
    display: inline-block;
    vertical-align: middle;
  }
  .row {
    padding: 2.8% 0 2.3%;
    .data {
      display: inline-block;
      width: 29%;
      .label {
        font-size: 0.75em;
        padding-bottom: 2%;
      }
      .value {
        font-size: 0.9em;
      }
    }
  }
  .comment {
    font-size: 0.75em;
  }
}
.order {
  @extend .item;
  background: url("../../../assets/visit.png") 3% 17% no-repeat;
}
.call {
  @extend .item;
  background: url("../../../assets/call.png") 3% 17% no-repeat;
}

