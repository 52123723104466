.element {
  width: 100%;
  margin: auto;
  box-shadow: 0 0 10px 0.5px rgba(0,0,0,.1);
  background-color: white;
  z-index: 103;
  position: fixed;
  left:0;
  bottom: 0;
  .body {
    width: 1188px;
    margin: auto;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in 0s;
  }
  &.open {
    .body {
      height: 710px;

    }
  }
}


.right {
  width: 76%;
  height: 100%;
  position: relative;
}
.left {
  width: 24%;
  height: 100%;
  position: relative;
}