.addService {
  //padding-left: 3.2%;
  color: #e7832a;
  padding-top: 3.8%;
  .addServiceText {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
    margin-bottom: 0.5%;
    padding-left: 1%;
  }
  .addServiceButton {
    width: 18%;
    margin-left: 2%;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2.6em;
    border: 1px solid #e7832a;
    text-align: center;
    font-size: .9em;
    line-height: 2.9;
    &:hover {
      cursor: pointer;
    }
  }
}
.title {
  color: #696e6f;
  font-size: 0.9em;
  padding-bottom: 1.3%;
  .text {
    display: inline-block;
    &:nth-child(1) {
      width: 44%;
    }
    &:nth-child(2) {
      width: 25.5%;
    }
    &:nth-child(3) {
      width: 30%;
      white-space: pre;
    }
  }
}
.row {
  color: #696e6f;
  font-size: 1.1em;
  padding: 2.5% 0;
  margin-left: 5%;
  width: 90%;
  &:hover {
    cursor: pointer;
    background-color: #f2f5f6;
  }
  .value {
    display: inline-block;
    vertical-align: middle;
    &:nth-child(1) {
      padding-left: 1%;
      width: 72%;
    }
    &:nth-child(2) {
      width: 28%;
      white-space: pre;
      text-align: center;
    }
  }
}
.row+.row {
  border-top: 1px solid #f3f3f3;
}
.orange {
  color: #e7832a;
  display: inline-block;
  white-space: pre;
}