.black {
  position: fixed;
  background-color: rgba(0,0,0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  top: 0;
}
.wrapper {
  position: fixed;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  height: 418px;
  width: 585px;
  top: 126px;
  left: 387px;
  z-index: 1001;
}
.password {
  position: absolute;
  height: 418px;
  width: 585px;
  z-index: 12;
  color: #696e6f;
  .changePassword {
    position: relative;
  }
  .icon {
    width: 22.9%;
    height: 28.2%;
    float: left;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 72px;
      height: 72px;
      border: solid 2px #6a5b40;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      margin: 22px 28px;
    }
    background: #382200;
    background-size: auto;
    background-position: 46% 48%;
    background-image: url(../../Header/assets/password-change-icon.png);
    background-repeat: no-repeat;
  }
  .gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, #783003, #774400);
    width: 22.9%;
    height: 71.8%;
  }
  .main {
    background-color: white;
    height: 100%;
    float: right;
    width: 77.1%;
    .header {
      color: #e7832a;
      padding: 0 11.5% 1.7%;
      font-size: 2.22em;
      line-height: 2.8;
    }
    .body {
      font-size: 1.1em;
      padding: 0 6%;
      .raw {
        width: 100%;
        padding-bottom: 6%;
        position: relative;
        .text {
          width: 35%;
          display: inline-block;
          font-size: 0.9rem;
          text-align: center;
        }
        input {
          width: 54%;
        }
        &:first-child {
          padding-bottom: 10%;
        }
      }
      .buttons {
        width: 100%;
        padding-top: 13%;
        margin: auto;
      }
      button {
        width: 61.5%;
      }
      .cancel {
        color: #2e7dad;
        display: inline-block;
        vertical-align: middle;
        margin: 0 3% 0 11%;
        border-bottom: 2px solid #acc5d9;
        cursor: pointer;
      }
    }
  }
  :global {
    .error-block {
      position: absolute;
      top: 55%;
      font-size: 0.8rem;
      right: 11%;
      line-height: 1.6;
      &:before {
        top: -3px;
      }
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 10%;
        background-color: white;
        content: '';
      }
    }
  }
}