:global {
  .modalWindowCL {
    background-color: white;
    color: #868887;
    text-align: center;
    padding: 0;
    display: block;
    line-height: 2;
    cursor: default;
    z-index: 10000;
    position: absolute;
    .triangle {
      display: none;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid white;
      position: absolute;

      &.bottom {
        top: 100%;
        left: 50%;
        margin-left: 2px;
      }

      &.top {
        bottom: 100%;
        left: 50%;
        margin-left: 2px;
        transform: rotate(180deg);
      }

      &.right {
        top: 20px;
        left: 100%;
        transform: rotate(-90deg);
        margin-left: -4px;
      }

      &.left {
        top: 20px;
        right: 100%;
        transform: rotate(90deg);
        margin-right: -4px;
      }
    }

    &.finish {
      width: 310px;
      //background-image: url('../OrderModal/assets/finish-order-bgr.png');

      &:before {
        display: block;
        content: '';
        padding-top: 24%;
      }

      &.topAlign {
        bottom: 100%;
        margin-bottom: 10px;
        left: -153px;

        .triangle.bottom {
          display: block;
        }
      }

      &.bottomAlign {
        top: 100%;
        margin-top: 10px;
        left: -153px;

        .triangle.top {
          display: block;
        }
      }

      &.leftAlign {
        top: -12px;
        margin-right: 10px;
        right: 100%;

        .triangle.right {
          display: block;
        }
      }

      &.rightAlign {
        top: -12px;
        margin-left: 10px;
        left: 100%;

        .triangle.left {
          display: block;
        }
      }

      &.failed {
        background-image: none;

        &:before {
          padding-top: 0;
        }

        .text {
          color: #9d223c;
        }
      }

      .text {
        color: #70ad2b;
        font-size: 1.2em;
        line-height: normal;
        padding: 7% 8% 5% 8%;
      }

      .clear {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        margin: 0 0 10%;
        color: #fff;
        font-size: 1.2em;
        text-align: center;
        background-color: #1abc9c;
        border-radius: 1.4em;
        display: inline-block;
        width: auto;
        position: relative;
        overflow: hidden;
        line-height: normal;
        padding: 2% 8% 3% 8%;
        cursor: pointer;
      }
    }
    &.success {
      border-color: green !important;
    }
    .fail {
      border-color: #ce252c !important;
    }
  }
}

