:global {
    .resourcesList {
        width: 100%;
        .resource {
            display: inline-block;
            width: 10%;
            overflow: hidden;
            margin: 0 1.3%;
            vertical-align: top;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding-top: 100%;
                //background-color: rgba(255, 255, 255, 0.4);
            }
            &.active {
                display: none;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    padding-top: 100%;
                }

            }

            &__image {
                width: 90%;
                padding-top: 90%;
                overflow: hidden;
                border-radius: 50%;
                position: relative;
                display: block;
                vertical-align: middle;
                margin: 5% auto 4% auto;
                background-color: #d6d5d9;
                position: relative;
                &.anyResource {

                }
                &__wrapper {
                    position: absolute;
                    width: 200%;
                    height: 100%;
                    left: -50%;
                    top: 0;
                    text-align: center;
                    img {
                        display: inline-block;
                        height: 100%;
                        width: auto;
                    }
                }

            }
            &__info {
                font-size: 0.6em;
                color: #8a8a91;
                white-space: nowrap;
                line-height: 2;
                max-height: 2.4em;
                text-align: center;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                &__title {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

            }
        }
    }
}
