:global {
  .clientsStream {
    position: fixed;
    left: 0;
    margin-left: -224px;
    top: 120px;
    background-color: #fff;
    height: 80vh;
    width: 272px;
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    z-index: 20;
    transition: all 0.2s ease-in 0s;
    overflow: hidden;
    &.open {
      margin-left: 0px;
      .client {
        width: 100%;
      }

    }
    &__top {
      border-bottom: 1px solid #e0e0e0;
      line-height: 3.6;
      text-align: center;
      color: #5499d3;
      cursor: default;
      background-image: url(../ClientsStream/assets/running.jpg);
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 10%;
      cursor: pointer;
    }
    .timePeriod {
      font-size: 1em;
      color: #87b553;
      line-height: 1.6;
      padding: 0 0 0 1%;
    }
    &__clients {
      padding-bottom: 3.6em;
    }
    .client {
      background-color: #fbf7df;
      margin-bottom: 2%;
      box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.4);
      position: relative;
      padding-top: 40%;
      width: 0%;
      transition: width 0.2s ease-in 0.2s;
      cursor: pointer;
      &__avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 25%;
        height: 100%;
        background-color: white;
        overflow: hidden;
        img {
          height: 100%;
          width: auto;
        }
      }
      &__info {
        position: absolute;
        left: 28%;
        top: 0;
        width: 70%;
      }
      &__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #87b553;
        line-height: 1.6;
      }
      &__phone {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #bdb9b2;
        line-height: 1.6;
      }
      &__service-desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #5b5b58;
        line-height: 1.6;
      }
    }
  }
}