.wrapper {
    width: 1186px;
    margin: auto;
    background-color: #fff;
    overflow: hidden;
    margin-top: 42px;
    .body {
        padding: 3% 5% 0;
        .widget {
            border-bottom: 1px solid #f3f3f3;
            color: #696e6f;
            font-size: 1.1em;
            cursor: pointer;
            padding: 2%;
            white-space: nowrap;
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
            }
            &:hover {
                background-color: #f2f5f6;
            }
            &.header {
                color: #a5acae;
                cursor: default;
                &:hover {
                    background-color: transparent;
                }
                .is_disabled {
                    &:before {
                        background-image: none;
                    }
                }
            }
            .is_disabled {
                display: inline-block;
                vertical-align: middle;
                width: 3%;
                &:before {
                    content: '';
                    display: block;
                    width: 19px;
                    height: 19px;
                    background-image: url('../../../assets/checkbox-icon.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 100% top;
                    margin-right: 3%;
                }
                &.true {
                    &:before {
                        background-position: left top;
                    }
                }
            }
            .number {
                width: 3%;
                display: inline-block;
                vertical-align: middle;
            }
            .settingsItem {
                width: 24%;
                margin-right: 1%;
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .url {
                width: 25%;
                display: inline-block;
                vertical-align: middle;
                padding-right: 1%;
                text-align: left;
                cursor: default;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .btnContainer {
            width: 50%;
            margin: auto;
            padding: 3% 0;
        }
    }
    .modal {
        position: fixed;
        background-color: rgba(0, 0, 0, .8);
        width: 100%;
        height: 100%;
        z-index: 1001;
        top: 0;
        left: 0;
        text-align: center;
        display: none;
        &.show {
            display: block;
        }
        &:before {
            content: '';
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
        .content {
            position: relative;
            max-width: 500px;
            display: inline-block;
            vertical-align: middle;
            padding: 50px;
            background-color: #fff;
            text-align: left;
            > div {
                height: 3em;
            }
            button + button {
                margin-top: 0.7rem;
            }
        }
        .text {
            display: inline-block;
            color: #696e6f;
        }
        .value {
            padding-left: 10px;
            display: inline-block;
            color: #e7832a;
        }
        .error {
            color: #c6272b;
        }
        .btnContainer {
            position: absolute;
            width: 70px;
            right: 50px;
            bottom: 10px;
        }
    }
}
