:global {
  .events {
    position: fixed;
    right: 0;
    margin-right: -224px;
    top: 120px;
    background-color: #fff;
    height: 80vh;
    width: 272px;
    padding: 57px 0;
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    z-index: 20;
    transition: all 0.2s ease-in 0s;
    overflow: hidden;
    &.open {
      margin-right: 0px;
      .event {
        width: 100%;
      }
    }
    &.withLastCall {
      padding-top: 144px;
    }
    &__top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 57px;
      border-bottom: 1px solid #e0e0e0;
      line-height: 3.6;
      text-align: left;
      cursor: pointer;
      &__counter {
        position: absolute;
        left: 9%;
        top: 26%;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: #f55656;
        color: white;
        line-height: 1.4;
        font-size: 0.45em;
        text-align: center;

      }
      &__text {
        display: inline-block;
        vertical-align: top;
        width: 60%;
        height: 100%;
        color: #a8a3a3;
        &.active {
          color: #ffae67;
        }
      }
      &__calls {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        height: 100%;
        background: #fbff9c;
        background: url(../Events/assets/phone-icon.svg) center no-repeat;
        background-size: 45%;
        &.active {
          background-image: url(../Events/assets/phone-active-icon.svg);
        }
      }
      &__bell {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        height: 100%;
        background: url(../Events/assets/bell.png) left center no-repeat;
      }
    }
    &__footer {
      border-top: 1px solid #e0e0e0;
      line-height: 3.6;
      text-align: center;
      color: #6b9e6b;
      cursor: default;
      background-color: white;
      background-image: url(../Events/assets/actions.png);
      background-repeat: no-repeat;
      background-position: left center;
      position: absolute;
      left: 0;
      bottom: 0;
      padding-left: 10%;
      width: 100%;
      z-index: 20;
    }
    .timePeriod {
      font-size: 1em;
      color: #87b553;
      line-height: 1.6;
      padding: 0 0 0 1%;
    }
    &__clients {
      padding-bottom: 3.6em;
    }
    @keyframes :global(hide_event) {
      from {padding-top: 30%;margin-bottom: 2%;}
      to {padding-top: 0;margin-bottom: 0;}
    }
    .event {
      background-color: #fbf7df;
      margin-bottom: 2%;
      box-shadow: 0px 1px 1px 0px rgba(50, 50, 50, 0.4);
      position: relative;
      padding-top: 30%;
      overflow: hidden;
      width: 0%;
      margin-left: auto;
      transition: width 0.2s ease-in 0.2s;
      cursor: pointer;
      &--last-call {
        position: absolute;
        top: 57px;
        left: 0;
      }
      &--read {
        animation-name: hide_event;
        animation-duration: 0.2s;
        animation-iteration-count: 1;
        margin-bottom: 0;
        padding-top: 0;
        //display: none;
      }
      &:before {
        content: '';
        position: absolute;
        left: 4%;
        top: 10%;
        width: 6%;
        padding-top: 6%;
        border-radius: 50%;
        background-color: #ffde00;
      }
      &--viewed {
        background-color: #f7f7f7;
        &:before {
          background-color: #ece6e6;
        }
      }
      &__info {
        position: absolute;
        left: 14%;
        top: 12%;
        width: 70%;
      }
      &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #b7a949;
        line-height: 1.2;
      }

      &__client {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4b4b4b;
        font-size: 0.9em;
        line-height: 1.2;
        margin-bottom: 4%;
      }
      &__date {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #9cab8a;
        line-height: 1.6;
        font-size: 0.8em;
        font-style: italic;
      }

      &__phone {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #bdb9b2;
        line-height: 1.6;
      }
      &__service-desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #5b5b58;
        line-height: 1.6;
      }
    }
  }
}