:global {
  .ClientsList {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    font-size: 1rem;

    &__clients {
      height: 100%;
      position: relative;

      .title {
        width: 100%;
        height: 2.6em;
        padding-left: 6.5%;

        &:hover {
          cursor: pointer;
        }

        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }

        .text {
          color: white;
          font-size: 1.12em;
          display: inline-block;
          vertical-align: middle;

          &.upText {
            border-bottom: 2px solid #efbea8;
          }
        }
      }

      .client {
        height: 4.5em;
        position: relative;
        border-bottom: 1px solid #dadada;
        padding-top: 1%;
        text-align: left;

        &.active {
          background: url("../ClientsList/assets/checkMark.png") 97% 50% no-repeat;
        }

        &:hover {
          cursor: pointer;
        }

        //&:before {
        //  content: '';
        //  display: inline-block;
        //  vertical-align: middle;
        //}
        &:last-child {
          border: none;
        }

        .name {
          font-size: 1.1em;
          line-height: 1.6;
          color: #5499d3;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .phone {
          font-size: 0.79em;
          line-height: 1.6;
          color: #cac8c8;
        }
      }
    }

    &.modal {
      padding-top: 100%;
      z-index: 10;
      position: absolute;
      left: 0;
      top: auto;
      width: 100%;
      height: 100%;
      background-color: white;
      border: 1px solid #e98c3a;
      overflow: visible;
      margin-top: 12px;
      &:before {
        content: '';
        border: 1px solid #e98c3a;
        box-sizing: content-box;
        background-color: #f7f7f7;
        display: block;
        left: 50%;
        margin-left: -7px;
        width: 12px;
        height: 12px;
        padding: 0px;
        position: absolute;
        top: -6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .ClientsList__clients {
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        .client {
          padding: 1% 4% 1% 4%;
          height: auto;
        }
      }

    }
  }

  .triangleRight {
    width: 0;
    height: 0;
    border-top: .5em solid transparent;
    border-left: .5em solid #248cde;
    border-bottom: .5em solid transparent;
    position: absolute;
    left: -0.9em;
    top: 1.7em;
  }

  .checkMark {
    background: url("../ClientsList/assets/loupe.png") 97% 50% no-repeat;
  }

  .scrollTrack {
    position: absolute;
    width: 3px !important;
    transition: opacity 200ms;
    right: 0;
    top: 2%;
    height: 96%;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 102%;
      background-color: #c0bcbc;
      top: -1%;
      left: 1px;
    }
  }

  .scrollThumb {
    width: 3px;
    //height: 16px !important;
    background-color: #949191;
    border-radius: 1px;
    overflow: hidden;
  }
}
