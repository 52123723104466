:global {
  .settings-block--cpro {
    .inputGroup {
      border-bottom: 1px solid #E0E0E0;
      padding-bottom: 1em;
      label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 3;
        color: #666666;
        display: block;
      }
      input {
        color: #2D9CDB;
        width: 2.6em;
        font-size: 1em;
        border: 1px solid #bec3c3;
        border-radius: .25em;
        line-height: 1.7;
        padding: 0 0.5em;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin: 0 0.6em;
      }
      .info {
        font-size: 0.8em;
      }
    }
  }
}