.body {
    background-color: #ededed;
    margin-top: 42px;
    :global {
        .orderfooter {
            //margin-top: 102px;
        }
    }
}

.black {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 1002;
    top: 0;
    left: 0;
}

.addClient {
    position: absolute;
    height: 500px;
    width: 580px;
    z-index: 13;
    .cancel {
        color: #2e7dad;
        display: inline-block;
        vertical-align: middle;
        margin: 0 3% 0 16.5%;
        font-size: 1.1em;
        border-bottom: 2px solid #acc5d9;
        cursor: pointer;
    }
}

.activateClient {
    position: absolute;
    height: 200px;
    width: 771px;
    z-index: 13;
    .cancel {
        color: #2e7dad;
        display: inline-block;
        vertical-align: middle;
        margin: 0 3% 0 16.5%;
        font-size: 1.1em;
        border-bottom: 2px solid #acc5d9;
        cursor: pointer;
    }
}

.addClientIcon {
    width: 23%;
    height: 26%;
    float: left;
    background: #072d33;
}

.activateClientIcon {
    width: 17%;
    height: 19%;
    float: left;
    background: #332607;
}

.gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, #067869, #0e636b);
}

.addClientGradient {
    @extend .gradient;
    width: 23%;
    height: 74%;
    background: linear-gradient(to top, #067869, #0e636b);
}

.activateClientGradient {
    @extend .gradient;
    width: 17%;
    height: 81%;
    background: linear-gradient(to top, #783a06, #6b4c0e);
}

.main {
    background-color: white;
    height: 100%;
    float: right;
}

.addClientMain {
    @extend .main;
    width: 77%;
}

.activateClientMain {
    @extend .main;
    width: 83%;
    position: relative;
}

.title {
    font-size: 2.2em;
    color: #e67e22;
}

.addClientTitle {
    @extend .title;
    line-height: 2.8;
    padding-left: 12%;
}

.activateClientTitle {
    @extend .title;
    padding: 4% 9% 1.6%;
    overflow: hidden;
    /*white-space: nowrap;
    text-overflow: ellipsis;*/
}

.data {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    .raw {
        padding-bottom: 7%;
        position: relative;
        line-height: 1.6;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        .label {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            color: #848889;
            font-size: 0.9em;
            width: 34.5%;
        }
        input {
            display: inline-block;
            vertical-align: top;
            border: 1px solid #bec3c3;
            border-radius: 0.25em;
            font-size: 1.1em;
            color: #485051;
            width: 48%;
            margin-left: 2%;
            padding-left: 3%;
        }
        .phone {
            color: #1abc9c;
        }
        &:last-child {
            padding-bottom: 7%;
            input {
                height: 95px;
            }
        }
        textarea {
            display: inline-block;
            vertical-align: top;
            border: 1px solid #bec3c3;
            border-radius: 0.25em;
            font-size: 1.1em;
            color: #485051;
            width: 48%;
            margin-left: 2%;
            padding-left: 3%;
            resize: none;
        }
    }
}

.button {
    display: inline-block;
    vertical-align: middle;
    width: 54%;
    line-height: 2.3;
    border-radius: 2.6em;
    border: 1px solid #fff;
    color: white;
    //background-color: #73ae2e;
    text-align: center;
    font-size: 1.1em;
    &:hover {
        cursor: pointer;
    }
}

.addClientButton {
    @extend .button;
    background-color: #73ae2e;
    &.disabled {
        background: #848889;
    }
}

.activateClientButton {
    @extend .button;
    background-color: #e67e22;
}

.cyclicNotificationButton {
    @extend .button;
    background-color: #1abc9c;
    width: 35%;
}

.error {
    position: relative;
    color: #ce252c;
    font-size: 0.9em;
    height: 4em;
    text-align: center;
    line-height: 4;
    &.unknown {
        bottom: 1%;
    }
    &:empty {
        display: none;
    }
}

.serviceNameError {
    position: absolute;
    color: #ce252c;
    bottom: -35%;
    left: 0;
    font-size: .9em;
}

.periodError {
    position: absolute;
    color: #ce252c;
    bottom: 71%;
    left: 7%;
    font-size: .9em;
}

.dateError {
    position: absolute;
    color: #ce252c;
    bottom: 45%;
    left: 6%;
    font-size: .9em;
}

.shading {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    &.shaded {
        display: block;
    }
}

.activateData {
    color: #696e6f;
    width: 40%;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 5%;
    &:nth-child(even) {
        margin-left: 8%;
    }
    &:nth-child(odd) {
        margin-left: 7%;
    }
    .fieldTitle {
        font-size: 0.9em;
        padding-bottom: 1%;
    }
    .changeValue {
        display: inline-block;
        position: relative;
        &:hover {
            cursor: pointer;
        }
        &.inputValue {
            &:hover {
                cursor: default;
            }
        }
        .value {
            font-size: 1.5em;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 90%;
        }
        .power {
            display: inline-block;
            color: #ff7113;
        }
        input {
            border: 1px solid #bec3c3;
            border-radius: 0.25em;
            font-size: 1.1em;
            color: #485051;
            width: 6em;
            margin-top: 3%;
            line-height: 1.2;
            padding: 1% 3%;
        }
        .vin {
            width: 100%;
        }
        .select {
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            vertical-align: bottom;
            background: url("../components/Clients/assets/select.png") 100% 60% no-repeat;
        }
    }
}

.outerMenu {
    box-sizing: content-box;
    width: 100%;
    //width: auto;
    min-width: 200px;
    height: auto;
    max-height: 300px;
    position: absolute;
    background-color: white;
    border: 1px solid #ff7113;
    padding: 10px 10px 10px 10px;
    right: -1.34em;
    top: 108%;
    text-align: left;
    line-height: 1.8;
    white-space: normal;
    z-index: 1;
    &:before {
        content: '';
        position: absolute;
        top: -7.5px;
        right: 25px;
        display: block;
        height: 11px;
        width: 11px;
        border-top: solid 1px transparent;
        border-right: solid 1px #ff7113;
        border-left: solid 1px transparent;
        border-bottom: solid 1px #ff7113;
        background-color: white;
        transform: rotate(-135deg);
    }
    .item {
        font-size: 0.9rem;
        color: #868887;
        margin: 0 10px 0 16px;
        padding: 0 6px 0 6px;

        cursor: pointer;
        &:hover {
            background-color: #dff6f1;
        }
    }
    .group {
        color: #53cdb5;
        font-size: 1.2em;
        margin-left: 12px;
    }
}

.outerMenuPeriod {
    @extend .outerMenu;
    min-width: 210px;
    right: -1.46em;
    top: 150%;
}

.outerMenuFields {
    @extend .outerMenu;
    min-width: 180px;
    right: -1.46em;
    top: 134%;
}

.activateText {
    text-align: center;
    color: #696e6f;
    font-size: 0.9em;
}

.activateHeader {
    padding-bottom: 7%;
}

.buttons {
    position: absolute;
    width: 70%;
    bottom: 5%;
    right: -4%;
    &.notification {
        right: -16%;
    }
}

.scrollTrack {
    position: absolute;
    width: 3px !important;
    transition: opacity 200ms;
    right: 0px;
    top: 7%;
    height: 89%;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 110%;
        background-color: #c0bcbc;
        top: -5%;
        left: 1px;
    }
}

.scrollThumb {
    width: 3px;
    //height: 16px !important;
    background-color: #949191;
    border-radius: 1px;
    overflow: hidden;
}

.cyclicNotification {
    position: absolute;
    padding: 25px 40px 0;
    height: 600px;
    width: 690px;
    background-color: white;
    z-index: 13;
    color: #696e6f;
    .header {
        color: #e7832a;
        position: relative;
        max-width: 100%;
        .changeValue {
            display: inline-block;
            position: relative;
            max-width: 100%;
            &:hover {
                cursor: pointer;
            }
        }
        .value {
            font-size: 2.22em;
            display: inline-block;
            line-height: 1.3;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 94.1%;
            &.selectedValue {
                max-width: 94.1%;
                color: #1abc9c;
            }
            &:hover {
                .tooltip {
                    display: block;
                }
                .shadowTriangle {
                    display: block;
                }
            }
        }
        .select {
            width: 2.22em;
            height: 2.7em;
            display: inline-block;
            vertical-align: bottom;
            background: url("../components/Clients/assets/select.png") 89% 50% no-repeat;
        }
        .blueSelect {
            @extend .select;
            background: url("../components/Clients/assets/blueSelect.png") 89% 50% no-repeat;
        }
    }
    .cyclicTitle {
        font-size: 0.9em;
        padding-left: 0.5%;
        padding-top: 9%;
        &.lastTitle {
            padding-top: 5.3%;
        }
    }

    input {
        display: inline-block;
        border: 1px solid #bec3c3;
        border-radius: 0.25em;
        width: 6.2%;
        margin: 1.6% 2% 0 1%;
        text-align: center;
        height: 4.5%;
    }
    .text {
        display: inline-block;
        font-size: 1.1em;
        white-space: pre;
    }
    .period {
        position: relative;
        display: inline-block;
        color: #e7832a;
        padding-left: 2%;
        height: 1.5em;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        &:hover {
            cursor: pointer;
        }
        .periodValue {
            font-size: 1.1em;
            display: inline-block;
        }
        .select {
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            vertical-align: bottom;
            background: url("../components/Clients/assets/select.png") 96% 30% no-repeat;
        }
    }
    .regulatory {
        color: #1abc9c;
        display: inline-block;
        vertical-align: middle;
        width: 30.4%;
        line-height: 2.4;
        border-radius: 2.6em;
        border: 1px solid #1abc9c;
        text-align: center;
        font-size: 0.75em;
        margin-left: 3%;
        &:hover {
            cursor: pointer;
        }
    }
    .serviceDate {
        display: inline-block;
        padding: 2.5% 0 0 0.4%;
        font-size: 1.1em;
        white-space: pre;
        .orange {
            color: #e7832a;
            display: inline-block;
        }
        .serviceDateChange {
            display: inline-block;
        }
        .select {
            position: relative;
            width: 1.5em;
            height: 1.1em;
            display: inline-block;
            vertical-align: bottom;
            background: url("../components/Clients/assets/select.png") 75% 0 no-repeat;
        }
        &.hover {
            &:hover {
                cursor: pointer;
            }
        }
    }
    textarea {
        border: 1px solid #bec3c3;
        border-radius: 0.25em;
        font-size: 1.1em;
        color: #485051;
        width: 98%;
        margin: 8% 1% 0;
        padding: 2% 0 2% 2%;
        resize: none;
    }
    .cancel {
        color: #2e7dad;
        display: inline-block;
        vertical-align: middle;
        margin: 0 3% 0 16.5%;
        font-size: 1.1em;
        border-bottom: 2px solid #acc5d9;
        cursor: pointer;
    }
    .deleteButton {
        width: 27%;
        position: absolute;
        color: #c6272b;
        line-height: 2.3;
        border-radius: 2.6em;
        border: 1px solid #c6272b;
        text-align: center;
        font-size: 1.1em;
        left: -56%;
        &:hover {
            cursor: pointer;
        }
    }
    .disabled {
        &.button {
            background-color: #cacaca;
            pointer-events: none;
        }
        .select {
            background: none;
        }
        * {
            color: #cacaca !important;
            border-color: #e0e0e0 !important;
        }
    }
    .tooltip {
        position: absolute;
        top: 100%;
        max-width: 100%;
        background-color: white;
        font-size: 1rem;
        line-height: 1.25;
        text-align: center;
        padding: 1% 1%;
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: none;
        white-space: normal;
        z-index: 3;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #fff;
            position: absolute;
            bottom: 100%;
            left: 2.9em;
        }
    }
    .shadowTriangle {
        position: absolute;
        bottom: -13%;
        left: 3.05rem;
        background-color: #fff;
        height: 11.3px;
        width: 11.3px;
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: rotate(45deg);
        display: none;
    }
}

.errorMenu {
    &:before {
        content: '';
        position: absolute;
        top: -6px;
        left: 24%;
        display: block;
        height: 11px;
        width: 11px;
        border-top: solid 1px transparent;
        border-right: solid 1px #ce252c;
        border-left: solid 1px transparent;
        border-bottom: solid 1px #ce252c;
        background-color: white;
        transform: rotate(-135deg);
    }
    width: 100%;
    min-width: 200px;
    height: auto;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ce252c;
    color: #ce252c;
    padding: 10px;
    top: 120%;
    //left: -10%;
    line-height: 1.8;
    white-space: normal;
    z-index: 1;
    cursor: default;
}

.errorMenuVin {
    @extend .errorMenu;
    &:before {
        left: 50%;
    }
}

.errorMenuRepeats {
    @extend .errorMenu;
    top: 27%;
    width: 36%;
    &:before {
        left: 7.5%
    }
}

//.responseErrors {
//  position: absolute;
//  width: 41%;
//  height: auto;
//  bottom: 17%;
//  left: 49%;
//  z-index: 1;
//  .errorList {
//    width: 100%;
//    min-width: 200px;
//    height: auto;
//    position: absolute;
//    background-color: #fff;
//    border: 1px solid #ce252c;
//    color: #ce252c;
//    padding: 10px;
//    bottom: 110%;
//    left: 0;
//    line-height: 1.8;
//    white-space: normal;
//    z-index: 1;
//    display: none;
//    &:before {
//      content: '';
//      position: absolute;
//      bottom: -8px;
//      left: 48%;
//      display: block;
//      height: 15px;
//      width: 15px;
//      border-top: 1px solid transparent;
//      border-right: 1px solid #ce252c;
//      border-left: 1px solid transparent;
//      border-bottom: 1px solid #ce252c;
//      background-color: #fff;
//      transform: rotate(45deg);
//    }
//    .message {
//      color: red;
//    }
//  }
//  .error {
//    width: 100%;
//    text-align: center;
//    color: red;
//    font-size: 2em;
//  }
//  &:hover {
//    .errorList {
//      display: block;
//    }
//  }
//}
.wrapper {
    position: fixed;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1002;
    background-color: white;
}
