$selected-color: #e67e22;
$orange-color: #e67e22;
:global {
  .settings-block--gallery {
    font-size: 0;
    overflow: hidden;
    background: #f7f5e6;
    &__block {
      float: left;
      width: 20%;
      position: relative;
      padding-top: 8%;
      overflow: hidden;
      font-size: 1rem;
      line-height: 1;
      margin: 0;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
      button {
        position: absolute;
        left: 1px;
        top: 1px;
      }
      &--button {
        width: 20%;
        padding-top: 8%;
        left: 0;
        top: 0;
        text-align: center;
        color: #72AC2F;
        border: 1px solid #72AC2F;
        background: white url('../assets/add-photo.png') no-repeat left center;
        background-size: contain;
        float: right;
        .text {
          position: absolute;
          left: 20%;
          top: 50%;
          line-height: 0%;
          width: 80%;
          text-align: center;
        }
        label {
          position: absolute;
          left: 0%;
          top: 0%;
          width: 100%;
          height: 100%;
        }
      }
      .slim {
        visibility: hidden;
        position: absolute;
      }

    }
  }
}
