:global {
  .fieldInput {
    display: block;
    margin-bottom: 5%;
  }
  .title {
    display: block;
  }

  .fieldInput.inline {
    .title {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1em;
    }
  }

  .fieldInput.orange {
    .value {
      color: #e7832a;
    }
  }

  .changeValue {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    &:hover {
      cursor: pointer;
    }
    .value {
      font-size: 1.5em;
      line-height: 1.3;
      display: inline-block;
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .select {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      background: url("../FieldInput/assets/select.png") 100% 50% no-repeat;
    }
    .edit {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      vertical-align: bottom;
      background: url("../FieldInput/assets/pencil.png") 100% 50% no-repeat;
    }
    input {
      border: 1px solid #bec3c3;
      border-radius: .25em;
      width: 11em;
      line-height: 1.5;
      padding: 1% 3%;
      display: inline-block;
    }
    .editOk {
      width: 2em;
      height: 2em;
      display: inline-block;
      vertical-align: bottom;
      background: url("../FieldInput/assets/editOk.png") 60% 50% no-repeat;
    }
    .outerMenu {
      box-sizing: content-box;
      width: 100%;
      min-width: 200px;
      height: auto;
      max-height: 300px;
      position: absolute;
      background-color: white;
      border: 1px solid #ff7113;
      padding: 10px 10px 10px 10px;
      right: -1.5em;
      top: 140%;
      text-align: left;
      line-height: 1.8;
      white-space: normal;
      //&:before {
      //  content: '';
      //  position: absolute;
      //  top: -7px;
      //  right: 25px;
      //  display: block;
      //  height: 11px;
      //  width: 11px;
      //  border-top: solid 1px transparent;
      //  border-right: solid 1px #ff7113;
      //  border-left: solid 1px transparent;
      //  border-bottom: solid 1px #ff7113;
      //  background-color: white;
      //  transform: rotate(-135deg);
      //}
      .item {
        font-size: 0.9rem;
        color: #868887;
        margin: 0 10px 0 16px;
        padding: 0 6px 0 6px;

        cursor: pointer;
        &:hover {
          background-color: #dff6f1;
        }
      }
    }
  }

  .scrollTrack {
    position: absolute;
    width: 3px !important;
    transition: opacity 200ms;
    right: 0px;
    top: 7%;
    height: 89%;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 110%;
      background-color: #c0bcbc;
      top: -5%;
      left: 1px;
    }
  }

  .scrollThumb {
    width: 3px;
    //height: 16px !important;
    background-color: #949191;
    border-radius: 1px;
    overflow: hidden;
  }

  .comment {
    padding-top: 2em;
    width: 250%;
    .label {
      display: inline-block;
      margin-right: 1%;
    }
    .pointer {
      cursor: pointer;
    }
    .edit {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      vertical-align: bottom;
      background: url("../FieldInput/assets/pencil.png") 100% 50% no-repeat;
    }
    .editOk {
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      vertical-align: bottom;
      background: url("../FieldInput/assets/editOk.png") no-repeat;
      cursor: pointer;
    }
    .text {
      max-width: 90%;
      display: inline-block;
    }
    textarea {
      border: 1px solid #bec3c3;
      border-radius: .25em;
      font-size: 1.1em;
      color: #485051;
      width: 90%;
      padding: 2% 0 2% 2%;
      margin-right: 1%;
      resize: none;
    }
  }

}
