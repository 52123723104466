:global {
    .contextModalTrigger {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .contextModal {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 0;
        &__body {
            position: absolute;
            display: block;
            width: 584px;
            background-color: #fff;
            color: #868887;
            text-align: center;
            padding: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, .2);
            display: block;
            line-height: 2;
            cursor: default;
            z-index: 10000;
            white-space: nowrap;
            &.availableLinks-1 {
                width: 200px;
                .contextModal__link {
                    width: 60%;
                    padding-top: 42%;
                }
            }
            &.availableLinks-2 {
                width: 400px;
                .contextModal__link {
                    width: 30%;
                }
            }

            &.topAlign {
                bottom: 100%;
                margin-bottom: 10px;
                left: -290px;
                &.availableLinks-1 {
                    left: -100px;
                }
                &.availableLinks-2 {
                    left: -200px;
                }
                .triangleArrow.bottom {
                    display: block;
                }
            }
            &.bottomAlign {
                top: 100%;
                margin-top: 10px;
                left: -290px;
                &.availableLinks-1 {
                    left: -100px;
                }
                &.availableLinks-2 {
                    left: -200px;
                }
                .triangleArrow.top {
                    display: block;
                }
            }
            &.leftAlign {
                top: -12px;
                margin-right: 10px;
                right: 100%;
                .triangleArrow.right {
                    display: block;
                }
            }
            &.rightAlign {
                top: -12px;
                margin-left: 10px;
                left: 100%;
                .triangleArrow.left {
                    display: block;
                }
            }
        }
        &__link {
            white-space: normal;
            display: inline-block;
            width: 20%;
            margin: 8% 6.6% 8% 6.6%;
            line-height: 1.6;
            text-align: center;
            padding-top: 14%;
            color: #aca7a7;
            background-repeat: no-repeat;
            background-position: left top;
            background-size: 200%;
            cursor: pointer;
            &:hover {
                background-position: 100% top;
                color: #5499d3;
            }
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                display: none;
            }
            &--order {
                background-image: url('../ContextModal/assets/icon-to-order.png');
            }
            &--reserve {
                background-image: url('../ContextModal/assets/icon-to-reserve.png');
            }
            &--takeoff {
                background-image: url('../ContextModal/assets/icon-to-takeoff.png');
            }

        }

        .text {
            color: #70ad2b;
            font-size: 1.2em;
            line-height: normal;
            padding: 7% 8% 5% 8%;
        }
        .clear {
            display: inline-block;
            width: auto;
            vertical-align: middle;
            margin: 0 0 10%;
            color: #fff;
            font-size: 1.2em;
            text-align: center;
            background-color: #1abc9c;
            border-radius: 1.4em;
            position: relative;
            overflow: hidden;
            line-height: normal;
            padding: 2% 8% 3% 8%;

        }
        .triangleArrow {
            display: none;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid white;
            position: absolute;
            &.bottom {
                top: 100%;
                left: 50%;
                margin-left: 2px;
            }
            &.top {
                bottom: 100%;
                left: 50%;
                margin-left: 2px;
                transform: rotate(180deg);
            }
            &.right {
                top: 20px;
                left: 100%;
                transform: rotate(-90deg);
                margin-left: -4px;
            }
            &.left {
                top: 20px;
                right: 100%;
                transform: rotate(90deg);
                margin-right: -4px;
            }
        }
    }
}
