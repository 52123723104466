@charset "UTF-8";
.callCreate {
  height: 100%; }
  .callCreate hr {
    margin: 0; }
  .callCreate .goback {
    background-color: #6e707f;
    color: #c7a832;
    height: 4%;
    font-size: 0.8em;
    vertical-align: middle;
    padding-left: 4%;
    display: block;
    cursor: pointer; }
    .callCreate .goback:before {
      content: '';
      display: inline-block;
      background-image: url("../CallView/assets/goback.png");
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
      width: 12px;
      height: 100%;
      vertical-align: middle;
      margin-right: 0.4em; }
    .callCreate .goback .wrapper {
      font-size: 1em;
      display: inline-block;
      vertical-align: middle;
      color: #c7a832; }
  .callCreate .header {
    height: 8.3%;
    background-color: #7d7f8e;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 1.5em;
    line-height: 1.4;
    vertical-align: middle;
    padding-left: 10%; }
    .callCreate .header:before {
      display: inline-block;
      height: 100%;
      content: '';
      vertical-align: middle; }
    .callCreate .header .wrapper {
      display: inline-block;
      vertical-align: middle; }
  .callCreate .body {
    position: relative;
    background-color: #ededed;
    border-left: 1px solid #dddddd;
    height: 88%;
    font-size: 1em;
    line-height: 1.6;
    overflow: hidden; }
    .callCreate .body .call {
      padding: 0;
      height: 100%; }
      .callCreate .body .call.withClient .clientBlock {
        height: 7%;
        background-color: rgba(47, 47, 47, 0); }
        .callCreate .body .call.withClient .clientBlock .clientsList {
          background-color: rgba(255, 255, 255, 0); }
          .callCreate .body .call.withClient .clientBlock .clientsList .client {
            height: 0;
            padding: 0;
            border: none; }
      .callCreate .body .call.withClient .callDetails {
        max-height: 600px; }
      .callCreate .body .call .callDetails {
        overflow: hidden;
        max-height: 0;
        transition: all 0.4s ease-out 0s; }
      .callCreate .body .call .clientBlock {
        height: 100%;
        background-color: #2f2f2f;
        background-image: url(../CallCreate/assets/client-search-icon.png);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: contain;
        transition: all 0.4s ease-out 0s; }
        .callCreate .body .call .clientBlock .text {
          color: #eaeaea;
          text-align: center;
          line-height: 5; }
    .callCreate .body .id {
      font-size: 0.9em;
      line-height: 2;
      margin-bottom: 5%; }
    .callCreate .body .block {
      margin-bottom: 4%;
      padding: 0 6% 0 6%; }
      .callCreate .body .block .label {
        font-size: 0.9em;
        line-height: 1.4;
        padding-left: 1%; }
      .callCreate .body .block .value {
        font-size: 1.2em;
        color: #5d7d75; }
        .callCreate .body .block .value div {
          display: inline-block; }
      .callCreate .body .block input {
        width: 90%;
        border-radius: 2.6em;
        border: 1px solid #bec3c3;
        line-height: 1.8;
        padding: 0 1.7em 0 0.94em; }
    .callCreate .body .commence {
      margin-bottom: 2%; }
      .callCreate .body .commence textarea {
        background-color: white;
        border-radius: 4px;
        width: 100%;
        border: 1px solid #bec3c3;
        padding: 1%;
        resize: none;
        overflow: hidden; }
    .callCreate .body .isImportant {
      display: block;
      margin-bottom: 5%;
      cursor: pointer; }
      .callCreate .body .isImportant:before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 19px;
        background-image: url(../CallView/assets/checkbox-icon.png);
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 1%; }
      .callCreate .body .isImportant.active:before {
        background-position: 100% top; }
    .callCreate .body :global .selectReason {
      padding-right: 7%; }
      .callCreate .body :global .selectReason .control {
        border: none;
        vertical-align: middle;
        white-space: nowrap; }
        .callCreate .body :global .selectReason .control .value {
          vertical-align: middle;
          min-width: 9em;
          max-width: 12em;
          overflow: hidden;
          text-overflow: ellipsis; }
        .callCreate .body :global .selectReason .control .arrow {
          position: relative;
          display: inline-block;
          width: 1em;
          height: 1em;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #e7832a;
          background-color: rgba(255, 255, 255, 0.2);
          margin-left: 0.5em;
          margin-right: 0em;
          vertical-align: middle; }
          .callCreate .body :global .selectReason .control .arrow:before {
            content: '';
            display: block;
            position: relative;
            width: 5px;
            height: 5px;
            border-top: 1px solid #e7832a;
            border-left: 1px solid #e7832a;
            transform: rotate(-135deg);
            margin: 30% auto 20%; }
    .callCreate .body .proceed {
      color: #fff;
      font-size: 1.1em;
      text-align: center;
      line-height: 2.4;
      background-color: #1abc9c;
      border-radius: 2em;
      display: block;
      margin: 8% 0 0 0;
      cursor: pointer; }
      .callCreate .body .proceed.disabled {
        background-color: #bec3c3;
        pointer-events: none; }
    .callCreate .body .callDate {
      position: relative;
      z-index: 10; }
      .callCreate .body .callDate .dateTime {
        font-size: 1.2em; }
        .callCreate .body .callDate .dateTime .time {
          display: block;
          width: 30%;
          color: #e6852f;
          position: relative;
          font-size: 1em; }
          .callCreate .body .callDate .dateTime .time :global .control {
            white-space: nowrap; }
            .callCreate .body .callDate .dateTime .time :global .control .value, .callCreate .body .callDate .dateTime .time :global .control .placeHolder {
              display: inline-block; }
          .callCreate .body .callDate .dateTime .time :global .arrow {
            position: relative;
            display: inline-block;
            width: 1em;
            height: 1em;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid #e7832a;
            background-color: rgba(255, 255, 255, 0.2);
            margin-left: 0.3em;
            margin-right: 0em;
            vertical-align: middle; }
            .callCreate .body .callDate .dateTime .time :global .arrow:before {
              content: '';
              display: block;
              position: relative;
              width: 5px;
              height: 5px;
              border-top: 1px solid #e7832a;
              border-left: 1px solid #e7832a;
              transform: rotate(-135deg);
              margin: 30% auto 20%; }
          .callCreate .body .callDate .dateTime .time .callTimeList {
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            padding: 0;
            border-radius: 2px;
            z-index: 1;
            border: 1px solid #e98c3a;
            font-size: 0.7em;
            line-height: 1.4;
            overflow: hidden;
            width: 50px; }
            .callCreate .body .callDate .dateTime .time .callTimeList.hidden {
              display: none; }
            .callCreate .body .callDate .dateTime .time .callTimeList a {
              display: block;
              padding: 4% 12% 4% 12%;
              text-align: center;
              width: 100%;
              box-sizing: border-box; }
              .callCreate .body .callDate .dateTime .time .callTimeList a:hover {
                background-color: #ededed; }
        .callCreate .body .callDate .dateTime .date {
          display: block;
          font-size: 1em; }
          .callCreate .body .callDate .dateTime .date :global .club-datepicker {
            color: #5d7d75; }
            .callCreate .body .callDate .dateTime .date :global .club-datepicker > span {
              display: none; }
            .callCreate .body .callDate .dateTime .date :global .club-datepicker__leftArrow, .callCreate .body .callDate .dateTime .date :global .club-datepicker__rightArrow {
              display: none; }
    .callCreate .body .search {
      position: relative;
      text-align: center;
      background-color: #ededed;
      z-index: 1; }
      .callCreate .body .search input {
        width: 80%;
        border-radius: 2.6em;
        border: 1px solid #bec3c3;
        line-height: 1.6;
        padding: 0 1.7em 0 0.94em;
        margin: 5%; }
      .callCreate .body .search input::-webkit-input-placeholder {
        font-size: 0.8em;
        opacity: 0.3; }
      .callCreate .body .search input:-moz-placeholder {
        font-size: 0.8em;
        opacity: 0.3; }
    .callCreate .body .callClient {
      height: 7em;
      background-color: transparent;
      padding: 4% 6% 0 6%; }
      .callCreate .body .callClient:before {
        content: 'Клиент';
        font-size: .9em;
        line-height: 1.4;
        padding-left: 1%;
        display: block; }
      .callCreate .body .callClient .name {
        width: 100%;
        color: #e7832a;
        font-size: 1.5em;
        line-height: 1.25;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis; }
    .callCreate .body .clientsList {
      background-color: white;
      transition: all 0.4s ease-out 0s; }
      .callCreate .body .clientsList .client {
        height: 4em;
        padding: 5% 6.5% 0 5%;
        position: relative;
        border-top: 1px solid #dadada;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.4s ease-out 0s; }
        .callCreate .body .clientsList .client .name {
          width: 89%;
          font-size: 1.1em;
          line-height: 1.3;
          color: #216997;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: all 0.4s ease-out 0s; }
        .callCreate .body .clientsList .client .phone {
          font-size: .79em;
          padding-top: 1.8%;
          color: #e7832a; }
  .callCreate :global .scrollTrack {
    position: absolute;
    width: 3px !important;
    transition: opacity 200ms;
    right: 0px;
    top: 7%;
    height: 89%;
    cursor: pointer; }
    .callCreate :global .scrollTrack:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 110%;
      background-color: #c0bcbc;
      top: -5%;
      left: 1px; }
  .callCreate :global .scrollThumb {
    width: 3px;
    background-color: #949191;
    border-radius: 1px;
    overflow: hidden; }

:global .clubselect__outerMenu.selectTime {
  width: 100px;
  min-width: 100px; }
