@keyframes :global(spin) {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

@keyframes :global(club) {
  from {
    background-position: left 0%;
  }
  50% {
    background-position: left 500%;
    //transform:scale(1.1, 1);
  }
  to {
    background-position: left 1000%;

  }
}
:global {
  .preloader {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 0;
    overflow: hidden;
    z-index: 1023;
    transition: opacity 0.2s ease-out, width 0.2s step-end, height 0.2s step-end;
    &.on {
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.2s ease-out, width 0.2s step-start,  height 0.2s step-start;
    }
    &.fixed {
      position: fixed;
    }
    &.notPointed {
      pointer-events: none;
    }
    &:before {
      content: '';
      background-image: url(../Preloader/assets/club-preloader.png);
      background-position: left top;
      display: block;
      position: absolute;
      width: 200px;
      height: 100px;
      //padding-top: 5%;
      left: 45%;
      top: 120px;
      -webkit-animation-name: club;
      animation-name: club;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: steps(5,end);
    }
  }
}
