:global {
  .clubselect {
    width: 100%;
    position: relative;
    &.disabled {
      * {
        color: #a6a6a6 !important;
        border-color: #a6a6a6 !important;
      }
      .control {
        pointer-events: none;
      }
      .arrow {
        pointer-events: none;
        &:before {
          border-right-color: #a6a6a6 !important;
          border-bottom-color: #a6a6a6 !important;
          border-color: #a6a6a6 !important;
        }
      }
    }
    &.wrong {
      .control .value {
        color: red;
      }
    }
    .control .value {
      cursor: default;
    }

    &.opened {
      z-index: 1000 !important;
    }
    &__outerMenu {
      box-sizing: content-box;
      width: 102%;
      min-width: 230px;
      height: auto;
      max-height: 300px;
      position: absolute;
      background-color: white;
      border: 1px solid #ff7113;
      padding: 10px 10px 10px 10px;
      right: -26px;
      top: 10px;
      text-align: left;
      line-height: 1.8;
      white-space: normal;
      &.toTop {
        right: -26px;
        top: auto;
        bottom: 40px;
        text-align: left;
        line-height: 1.8;
        white-space: normal;
      }
      &.autowidth {
        width: auto;
        min-width: 50px;
      }

      &.closed {
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        right: 12px;
        display: block;
        height: 4px;
        width: 26px;
        background-color: white;
      }
      .item,.disabled {
        font-size: 0.9rem;
        color: #868887;
        margin: 0 10px 0 1em;
        padding: 0 6px 0 6px;
        cursor: pointer;
        span {
          font-size: 0.9em;
          display: inline-block;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
          &.disabled {
              color: #c6c9c8;
          }
          &.level2 {
              margin-left: 2em;
          }
          &.level3 {
              margin-left: 3em;
          }
        &:hover {
          background-color: #dff6f1;
        }
      }
      .group {
        color: #53cdb5;
        font-size: 1.2em;
        margin-left: 12px;
      }
      input {
        box-sizing: border-box;
        width: 93%;
        padding-left: 2%;
        padding-right: 14%;
        border-radius: 3px;
        border: 1px solid #bdc2c2;
        color: #868887;
        background: url('../../assets/loupe-grey.png') 97% center no-repeat;
        background-size: 10%;
      }
      .header {
        color: #ff7113;
        font-size: 1.2em;
        margin: 0 10px 0 16px;
        padding: 0 6px 0 6px;
      }
      .scrollTrack {
        position: absolute;
        width: 3px !important;
        transition: opacity 200ms;
        right: 0px;
        top: 7%;
        height: 89%;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 110%;
          background-color: #c0bcbc;
          top: -5%;
          left: 1px;
        }
      }
      .scrollThumb {
        width: 3px;
        //height: 16px !important;
        background-color: #949191;
        border-radius: 1px;
        overflow: hidden;
      }
    }


    .error {
      position: absolute;
      left: 0%;
      width: 200px;
      overflow: visible;
      margin-top: 4px;
      .wrapper {
        background-color: white;
        border: 1px solid #9d223c;
        color: #9d223c;
        padding: 0 10px 0 10px;
        line-height: 2.2;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        display: inline-block;
      }
      &:after {
        content: '';
        position: absolute;
        top: -3px;
        left: 8px;
        display: block;
        height: 7px;
        width: 7px;
        border-top: solid 1px transparent;
        border-right: solid 1px #9d223c;
        border-left: none;
        border-bottom: solid 1px #9d223c;
        background-color: white;
        transform: rotate(-135deg);
      }
    }

    &.inline {
      position: relative;
      display: inline-block;
      width: auto;
      vertical-align: middle;
      &.opened {
        .control {
          .arrow {
            &:after {
              transform: rotate(-135deg);
              top: 30%;
            }
          }
        }
      }
      .control {
        width: 100%;
        position: relative;
        color: #ff7113;
        height: auto;
        vertical-align: middle;
        white-space: nowrap;
        border: none;
        .value, .placeHolder {
          padding: 0;
          width: auto;
          margin: 0;
          display: inline-block;
        }
        .arrow {
          display: inline-block;
          width: 0.9em;
          height: 0.9em;
          vertical-align: middle;
          position: relative;
          border-radius: 50%;
          border: 1px solid #ff7113;
          margin-left: 0.2em;
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 50%;
            width: 50%;
            left: 25%;
            top: 10%;
            border-top: solid 1px transparent;
            border-right: solid 1px #ff7113;
            border-left: solid 1px transparent;
            border-bottom: solid 1px #ff7113;
            background-color: transparent;
            transform: rotate(45deg);
            transition: all 0.2s ease 0s;
          }
          &.icon {
            width: 1.4rem;
            height: 1.4rem;
            border: none;
            background: url('../../assets/select-icon.png') 100% 50% no-repeat;
            //background-size: cover;
            &:after {
              display: none;
            }
          }
        }
      }
    }
    &.select {
      height: 2em;
      position: relative;
      z-index: 21;
      &.opened {
        .control {
          .arrow {
            &:after {
              transform: rotate(-135deg);
              top: 37%;
            }
          }
        }
      }
      .control {
        width: 100%;
        position: relative;
        border: 1px solid;
        border-radius: 1.2em;
        height: auto;
        margin-bottom: 0.70rem;
        vertical-align: middle;
        white-space: nowrap;
        .value {
          display: block;
          width: auto;
          padding-left: 3em;
          margin-right: 4em;
          white-space: nowrap;
          overflow: hidden;
          line-height: 2em;
          vertical-align: middle;
          height: auto;
          padding-bottom: 2px;
          text-overflow: ellipsis;
        }
        .placeHolder {
          display: block;
          width: auto;
          padding-left: 3em;
          margin-right: 4em;
          white-space: nowrap;
          overflow: hidden;
          line-height: 2em;
          vertical-align: middle;
          height: auto;
          padding-bottom: 2px;
          text-overflow: ellipsis;
        }
        .arrow {
          width: 1.6em;
          height: 1.6em;
          vertical-align: middle;
          position: absolute;
          top: 0.2em;
          right: 0.6em;
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 50%;
            width: 50%;
            left: 25%;
            top: 10%;
            border-top: solid 1px transparent;
            border-right: solid 1px;
            border-left: solid 1px transparent;
            border-bottom: solid 1px;
            background-color: transparent;
            transform: rotate(45deg);
            transition: all 0.2s ease 0s;
          }
        }
      }
    }
    &.link {
      display: inline-block;
      width: auto;
      height: 2em;;
      position: relative;
      z-index: 20;
      .control {
        .value {

        }
        .placeholder {

        }
        .arrow {
          //display: none;
        }
      }
      .outerMenu {
        position: absolute;
        left: 50%;
        margin-left: -222px;
      }
    }
    &.toTop {
      .outerMenu {
        right: -26px;
        top: auto;
        bottom: 10px;
        text-align: left;
        line-height: 1.8;
        white-space: normal;
      }
      .error {
        position: absolute;
        left: 0%;
        width: 200px;
        overflow: visible;
        margin-bottom: 4px;
        bottom: 0;
        .wrapper {


        }
        &:after {

        }
      }
    }

    .scrollTrack {
      position: absolute;
      width: 3px !important;
      transition: opacity 200ms;
      right: 0px;
      top: 7%;
      height: 89%;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 110%;
        background-color: #c0bcbc;
        top: -5%;
        left: 1px;
      }
    }
    .scrollThumb {
      width: 3px;
      //height: 16px !important;
      background-color: #949191;
      border-radius: 1px;
      overflow: hidden;
    }
  }
  .selectComponentCustom {
    width: 90%;
    border-radius: 2.6rem;
    border: 1px solid #e7832a;
    color: #e7832a;
    text-align: center;
    margin: 1.5em auto 0;
    line-height: 2.4;
  }
  .selectTriangle {
    content: '';
    position: absolute;
    -webkit-transform: rotate(-135deg);

    display: block;
    height: 14px;
    width: 14px;
    border-top: solid 1px transparent;
    border-right: solid 1px #ff7113;
    border-left: solid 1px transparent;
    border-bottom: solid 1px #ff7113;
    background-color: white;
    top: 3px;
    left: -4px;
    z-index: 2;
    &.toTop {
      transform: rotate(45deg);
      top: auto;
      bottom: 33px;

    }
  }
}
