@import '../../../styles/themes/default/colors.scss';

.reports {
  color: $grey-color;
  .wrapper {
    padding: 2.5% 4% 8%;
  }
  > .filter {
    background-color: #f0f1f3;
    text-align: center;
    text-decoration: underline;
    color: #828a8c;
    font-size: 1.1em;
    line-height: 2.5;
    cursor: pointer;
  }
  .header {
    .title {
      font-size: 2.2em;
      padding-bottom: 1.3%;
      letter-spacing: 0.015em;
      span {
        display: inline-block;
        vertical-align: middle;
      }
      :global {
        .inline {
          cursor: pointer;
          .control {
            .arrow {
              margin-left: 0;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .filter {
      font-size: 0.85em;
      .text {
        display: inline-block;
        padding-right: 0.5%;
      }
      :global {
        .inline {
          cursor: pointer;
          min-width: 20%;
          .control {
            .placeHolder {
              text-decoration: underline;
            }
            .value {
              text-decoration: underline;
            }
          }
        }
      }
      .export {
        display: inline-block;
        color: #379133;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 1%;
        &.processing {
          text-decoration: none;
        }
      }
    }
  }
  .list {
    .header {
      color: #adadad;
      font-size: 0.85em;
      padding: 1% 0;
      margin-top: 2.5%;
      &:before {
        content: '';
        vertical-align: middle;
        display: inline-block;
      }
    }
    .item {
      height: 4.1em;
      border-top: 1px solid $grey-border-color;
      font-size: 1.1em;
      &:before {
        content: '';
        height: 100%;
        vertical-align: middle;
        display: inline-block;
      }
      .card {
        text-decoration: underline;
        cursor: pointer;
      }
      .add {
        height: 3em;
        cursor: pointer;
      }
    }
    .name {
      display: inline-block;
      vertical-align: middle;
      width: 55%;
    }
    .date {
      display: inline-block;
      vertical-align: middle;
      width: 20%;
      text-align: center;
    }
    .card {
      display: inline-block;
      vertical-align: middle;
      width: 14%;
      margin: 0 2%;
      text-align: center;
    }
    .add {
      display: inline-block;
      vertical-align: middle;
      width: 7%;
      text-align: center;
      &.active {
        background-color: #52ff68;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: -9%;
    left: 0;
    width: 100%;
    height: 9%;
    //display: none;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: bottom 0.4s ease-out 0.1s;
    -moz-transition: bottom 0.4s ease-out 0.1s;
    -o-transition: bottom 0.4s ease-out 0.1s;
    transition: bottom 0.4s ease-out 0.1s;
    &.active {
      bottom: 0;
    }
    .content {
      height: 100%;
      width: 1186px;
      margin: auto;
      background-color: #efefef;
      &:before {
        content: '';
        height: 100%;
        vertical-align: middle;
        display: inline-block;
      }
      .reportsCount {
        width: 45%;
        color: $orange;
        display: inline-block;
        vertical-align: middle;
        font-size: 1.5em;
        padding-left: 4%;
        //padding-right: 23.1%;
      }
      .export {
        color: $h-color;
        border: 1px solid $h-color;
        display: inline-block;
        vertical-align: middle;
        padding: 1.1% 2.3% 1.1% 5.5%;
        font-size: 0.85em;
        border-radius: 1.5em;
        margin-right: 2.7%;
        cursor: pointer;
      }
      .addList {
        color: #fff;
        background-color: $h-color;
        display: inline-block;
        vertical-align: middle;
        padding: 1.2% 4.2%;
        font-size: 0.85em;
        border-radius: 1.5em;
        cursor: pointer;
      }
    }
  }
  .exportPopup{
    position: fixed;
    right: 0;
    bottom: 0;
    color: white;
    padding: 20px;
    background-color: #4d4c4c;
    span {
      color: #5794b8;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
