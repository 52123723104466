$selected-color: #e67e22;
$orange-color: #e67e22;
:global {
  .statistics {
    width: 1186px;
    margin: auto;
    background-color: white;
    overflow: hidden;
    color: #485051;
    margin-top: 42px;
    font-size: 1.2em;

    &__submit {
      color: #fff;
      font-size: 1.1em;
      text-align: center;
      line-height: 2.4;
      background-color: #1abc9c;
      border-radius: 2em;
      display: block;
      width: 200px;
      margin: 2% auto 2% auto;
    }
    &-block {
      padding: 1.4% 4.5% 0% 4.5%;
      hr.margin-bottom {
        margin-bottom: 8%;
      }
      &__h1 {
        font-size: 2em;
        font-weight: normal;
        line-height: 1.2;
        margin: 0 0 2.8% 0;
        vertical-align: middle;
        padding: 0;
        > div {
          display: inline-block;
          vertical-align: middle;
        }
        .clubselect.select {
          margin-left: 0.3em;
          color: $orange-color;
          width: auto;
          display: inline-block;
          vertical-align: middle;
          .control {
            border: none;
            .value {
              padding-left: 0;
              padding-right: 1.5em;
              margin-right: 0;
            }
            .arrow {
              width: 0.6em;
              height: 0.6em;
              border: 1px solid;
              border-radius: 50%;
              top: 0.8em;
            }
          }
        }
      }
      &__h2 {
        font-size: 1.7em;
        font-weight: normal;
        color: #9fa9aa;
        line-height: 1.2;
        margin: 0 0 4.9% 0;
      }

      .date-filter {
        vertical-align: middle;
        padding: 0;
        margin-bottom: 4%;
        > div {
          display: inline-block;
          vertical-align: middle;
          padding: 0;
          margin: 0 1em 0 0;
        }
      }
      .status-filter {
        white-space: normal;
        &__title {
          display: inline-block;
          margin-right: 1em;
        }
        &__status {
          display: inline-block;
          margin-right: 1em;
          cursor: pointer;
          &.active {
            color: $orange-color;
          }
        }
      }
      .sorting {
        font-size: 0.8em;
        vertical-align: middle;
        > div {
          display: inline-block;
          width: auto;
          vertical-align: middle;
        }
        .clubselect {
          margin-left: 1em;
          color: $orange-color;
          .control {
            border: none;
            .value {
              padding-left: 0;
              padding-right: 2em;
              margin-right: 0;
            }
            .arrow {
              width: 1em;
              height: 1em;
              border: 1px solid;
              border-radius: 50%;
              top: 0.6em;
            }
          }
        }
      }
      &__table {
        &__headers {
          color: #b1b1b1;
          font-size: 0.8em;
          border-bottom: 1px solid #f2f3f3;
          line-height: 3em;
          div {
            display: inline-block;
            text-align: center;
            width: 20%;
            &:nth-child(1) {
              text-align: left;
              width: 60%;
            }
            &:nth-child(3) {
              text-align: right;
              width: 18%;
            }
          }
        }
        &__item {
          .title {
            color: $orange-color;
            font-size: 1.2em;
            line-height: 3em;
          }
          .stat-info {
            margin-left: 5%;
            border-bottom: 1px solid #f2f3f3;
            line-height: 3em;
            &--summary {
              color: $orange-color;
            }
            > div {
              display: inline-block;
              vertical-align: middle;
              text-align: center;
              width: 21%;
              &:nth-child(1) {
                text-align: left;
                width: 58%;
              }
              &:nth-child(3) {
                text-align: right;

              }
            }
          }
          &--order {
            .stat-info {
              margin-left: 0;
              > div {
                width: 20%;
                &:nth-child(1) {
                  width: 60%;
                }
              }
            }
          }
          &--okk {
            .stat-info {
              margin-left: 0;
              > div {
                width: 20%;
                &:nth-child(1) {
                  text-align: left;
                  width: 80%;
                }
              }
            }
          }
        }
        &__summary {
          color: $orange-color;
          font-size: 1.2em;
          line-height: 3em;
          border-bottom: 1px solid #f2f3f3;

          div {
            display: inline-block;
            text-align: center;
            width: 20%;
            &:nth-child(1) {
              text-align: left;
              width: 60%;
              color: #485051;
            }
            &:nth-child(3) {
              text-align: right;
            }
          }
        }
      }
      &__statistic_type_select {
        width: 340px;
      }



    }

    .analitic-menu {
      width: 100%;
      height: auto;
      background-color: #30677e;
      color: #fff;
      font-size: 1em;
      line-height: 1.3;
      &__link {
        background-color: #204e64;
        display: inline-block;
        width: auto;
        padding: 2% 2% 2% 4%;
        span {
          color: white;
          text-align: left;
          display: block;
          width: 100%;
          white-space: nowrap;
        }
        &.active, &:hover {

        }
      }

    }
    &-menu {
      width: 100%;
      height: auto;
      background-color: #9ca2a4;
      color: #fff;
      font-size: 1em;
      line-height: 1.3;
      &__link {
        display: table-cell;
        width: 1%;
        background-repeat: no-repeat;
        background-position: left center;
        border-right: 1px solid #7a8183;
        &:last-child {
          border-right: none;
        }
        span {
          color: white;
          text-align: left;
          display: block;
          padding: 6% 10% 6% 26%;
          width: 100%;
        }
        &--common {
          //background-image: url(../Main/assets/icon-common.png);
        }
        &--services {
          //background-image: url(../Main/assets/icon-services.png);
        }
        &--spec {
          //background-image: url(../Main/assets/icon-specs.png);
        }
        &--resources {
          //background-image: url(../Main/assets/icon-resources.png);
        }
        &--card {
          //background-image: url(../Main/assets/icon-card.png);
        }

        &.active, &:hover {
          background-color: #7a8183;
        }
      }

    }
  }
}
