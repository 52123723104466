.callView {
  height: 100%;
  hr {
    margin: 0 -6% 6% -6%;
  }
  .goback {
    background-color: #6e707f;
    color: #c7a832;
    height: 4%;
    font-size: 0.8em;
    vertical-align: middle;
    padding-left: 4%;
    display: block;
    &:before {
      content: '';
      display: inline-block;
      background-image: url('../CallView/assets/goback.png');
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
      width: 12px;
      height: 100%;
      vertical-align: middle;
      margin-right: 0.4em;
    }
    .wrapper {
      font-size: 1em;
      display: inline-block;
      vertical-align: middle;
      color: #c7a832;
    }
  }
  .header {
    height: 8.3%;
    background-color: #7d7f8e;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 1.5em;
    line-height: 1.4;
    vertical-align: middle;
    padding-left: 10%;
    &:before {
      display: inline-block;
      height: 100%;
      content: '';
      vertical-align: middle;
    }
    .wrapper {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .body {
    background-color: #ededed;
    border-left: 1px solid #dddddd;
    height: 88%;
    font-size: 1em;
    line-height: 1.6;
    .call {
      padding: 4% 10% 4% 10%;
    }
    .id {
      font-size: 0.9em;
      line-height: 2;
      margin-bottom: 5%;
    }
    .block {
      margin-bottom: 10%;
      .label {
        font-size: 0.9em;
        line-height: 1.4;
      }
      .value {
        font-size: 1.2em;
        color: #5d7d75;
        div {
          display: inline-block;
        }
      }
    }
    .isImportant {
      display: block;
      margin-bottom: 5%;
      cursor: pointer;
      &:before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 19px;
        background-image: url(../CallView/assets/checkbox-icon.png);
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 1%;
      }
      &.active {
        &:before {
          background-position: 100% top;
        }
      }
    }
    .commence {
      background-color: white;
      border-radius: 4px;
      width: 100%;
      border: 1px solid #bec3c3;
      padding: 1%;
      resize: none;
      overflow: hidden;
    }
    .proceed {
      color: #fff;
      font-size: 1.1em;
      text-align: center;
      line-height: 2.4;
      background-color: #1abc9c;
      border-radius: 2em;
      display: block;
      margin: 20% 0 0 0;
      cursor: pointer;
      &.processed {
        background-color: #e67e22;
      }
    }
    .callDate {
      margin-bottom: 1%;

      .dateTime {
        font-size: 1.2em;
        .time {
          display: inline-block;
          color: #e6852f;
        }
        .date {
          display: inline-block;
          color: #5d7d75;
        }
      }
    }
    .callShift {
      color: #e6852f;
      font-size: 0.8em;
      text-align: center;
      line-height: 2.3;
      border: 1px solid #e6852f;
      border-radius: 2em;
      min-width: 165px;
      margin-right: 1.5%;
      margin-bottom: 10%;
      padding-left: 8%;
      background-image: url("../CallView/assets/call-delay.png");
      content: '';
      width: 80%;
      display: inline-block;
      background-repeat: no-repeat;
      position: relative;
      :global {
        .club-datepicker {
          z-index: 6;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          .text {
            display: none;
          }
          .dpSwitcher {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            a {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              border: none;
            }
          }
        }
      }
    }
  }

  :global {
    .scrollTrack {
      position: absolute;
      width: 3px !important;
      transition: opacity 200ms;
      right: 0px;
      top: 7%;
      height: 89%;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 110%;
        background-color: #c0bcbc;
        top: -5%;
        left: 1px;
      }
    }
    .scrollThumb {
      width: 3px;
      //height: 16px !important;
      background-color: #949191;
      border-radius: 1px;
      overflow: hidden;
    }
  }
}