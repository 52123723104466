.body {
  width: 1188px;
  height: 700px;
  margin: 42px auto 0 auto;
  box-shadow: 0 0 10px 0.5px rgba(0,0,0,.1);
  overflow: hidden;
  .leftColumn {
    width: 24%;
    height: 100%;
    position: relative;
    float: left;
  }
  .rightColumn {
    width: 76%;
    height: 100%;
    position: relative;
    float: right;
  }
}