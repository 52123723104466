:global {
  .notFoundcomponent {
    width: 100vw;
    height: 100vh;
    background-color: #eeeff1;
    text-align: center;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .block {
      font-family: 'Fira Sans';
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      color: #444d76;
      line-height: 1.4;
      overflow: hidden;
      text-align: center;
      .logo {
        position: absolute;
        display: block;
        width: 100%;
        padding-top: 35%;
        background: url(../NotFound/assets/ortus.png) center no-repeat;
        background-size: contain;
      }
      .text {
        padding-top: 32%;
        a {
          display: block;
          width: 25%;
          border-radius: 3.2em;
          padding: 1.1% 3%;
          margin: 6.5% auto 0;
          cursor: pointer;
          border: 1px solid #fff;
          background: #1abc9c;
          color: #fff;
          line-height: 1;
          font-size: 1.2vw;
        }
        .errorCode {
          display: inline-block;
          color: #434170;
          font-size: 4.6vw;
          font-weight: 600;
          padding-right: 2%;
        }
        .errorText {
          display: inline-block;
          color: #434170;
          text-align: left;
          font-size: 1.2vw;
        }
      }
    }
  }
}