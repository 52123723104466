.callsDiary {
  height: 100%;
  .header {
    height: 12.3%;
    background-color: #7d7f8e;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.1em;
    line-height: 1.4;
    vertical-align: middle;
    &:before {
      display: inline-block;
      height: 100%;
      content: '';
      vertical-align: middle;
    }
    .wrapper {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      :global {
        .club-datepicker {
          &__leftArrow{
            &:after {
              left: 55%;
            }
          }
          &__rightArrow {
            &:after {
              left: 25%;
            }
          }
        }
      }
    }
  }
  .searchForm {
    height: 10%;
    position: relative;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    box-shadow: inset -7px 0 5px -5px rgba(0,0,0,0.1);
    z-index: 2;
    input {
      width: 91%;
      border-radius: 2.6em;
      border: 1px solid #bec3c3;
      line-height: 1.6;
      padding: 0 1.7em 0 .94em;
      margin-top: 2.8%;
      background: url("../CallsDiary/assets/loupe.png") 97% 50% no-repeat;
    }
    .filter {
      font-size: 0.75em;
      line-height: 1.4;
      padding: 3% 3% 0 3%;
      text-align: center;
      > div {
        padding: 0 2% 0 2%;
        font-size: 1em;
        line-height: 1.4;
        display: inline-block;
        cursor: pointer;
        &.active {
          background-color: #7d7f8e;
          color: #bebfc7;
          border-radius: 1.4em;
        }
      }
    }
  }
  .callsday {
    font-size: 1em;
    height: 70%;
    background-color: white;
    position: relative;
    box-shadow: inset -7px 0 5px -5px rgba(0,0,0,0.1);
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 23%;
      height: 100%;
      background-color: #dddddd;
      z-index: 1;
    }
    .interval {
      position: relative;
      z-index: 100;
      padding-left: 23%;
      &:first-child {
        margin-top: 2%;
        .calls {
          border-top: 3px solid #d3d3d3;
          .noCall, .call {
            //border-top: 3px solid #d3d3d3;

          }
        }
      }
      .callsHeader {
        position: absolute;
        left: 0;
        top: -4px;
        width: 23%;
        text-align: center;
        font-size: 0.9em;
        line-height: 0.8em;
        color: #79a348;
      }
      .calls {
        .noCall {
          border-bottom: 3px solid #d3d3d3;
          background-color: white;
          padding: 2% 2% 0 6%;
          overflow: hidden;
          font-size: 0.8em;
          box-shadow: inset -7px 0 5px -5px rgba(0,0,0,0.1);
          &:before {
            display: block;
            padding-top: 38.5%;
            content: '';
            width: 1px;
            float: left;
          }
          &.expired {
            background-color: #eeeeee;
            color: #a6a6a6;
          }
        }
        .call {
          border-bottom: 3px solid #d3d3d3;
          background-color: white;
          overflow: hidden;
          position: relative;
          box-shadow: inset -7px 0 5px -5px rgba(0,0,0,0.1);
          cursor: pointer;
          &:before {
            display: block;
            padding-top: 38.5%;
            content: '';
            width: 1px;
          }
          &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            content: '';
          }
          &.expired {
            background-color: #eeeeee;
            .wrapper {

              .name {
                color: #a6a6a6;
              }
              .phone {
                color: #a6a6a6;
              }
              .status {
                color: #a6a6a6;
              }

            }
          }
          &.lid {

          }
          &.active {
            &:after {
              background-color: #a9c48c;
            }
          }
          &.sleep {

          }
          &.removed {

          }
          &.notActivated {
            &:after {
              background-color: #ffcd7d;
            }
          }
          .wrapper {
            position: absolute;
            left: 0;
            top: 0;
            padding: 2% 2% 0 7%;
            width: 100%;
            .name {
              color: #598fb1;
              line-height: 1.6;
              white-space: nowrap;
              width: 95%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .phone {
              color: #ec9a52;
              font-size: 0.8em;
              line-height: 1.4;
            }
            .status {
              font-size: 0.8em;
              line-height: 2.8;
              &.notActivated {
                color: #a1732a;
              }
              &.regular {
                color: #6d9b3b;
              }
            }
            :global {
              .selectEmployee {
                position: absolute;
                left: 78%;
                top: 70%;
                width: 17%;
                font-size: 0.8em;
                .control {
                  border-radius: 2px;
                  border: none;
                  background-color: #d7d5d5;
                  color: white;
                  .placeHolder {
                    width: 50%;
                    margin: 0 0 0 10%;
                    text-align: center;
                    font-size: 0.8em;
                    line-height: 1.2;
                    display: inline-block;
                  }
                  .value {
                    width: 50%;
                    margin: 0 0 0 10%;
                    text-align: center;
                    line-height: 1.2;
                    color: white;
                    padding: 0;
                    text-overflow: clip;
                    display: inline-block;
                  }
                  .arrow {
                    text-align: center;
                    display: inline-block;
                    height: 0.9em;
                    width: 0.9em;
                    right: 0.1em;
                    &:after {
                      color: white;
                      border-right: 1px solid #fff;
                      border-bottom: 1px solid #fff;
                    }
                  }
                }

              }
            }
          }
        }
      }
      .timeArrow {
        position: absolute;
        left: 0;
        height: 2px;
        background-color: #4f8528;
        width: 100%;
        z-index: 200;
        &:before {
          border-radius: 50%;
          width: 4px;
          height: 4px;
          background-color: #4f8528;
          overflow: hidden;
          position: absolute;
          left: 4%;
          top: -1px;
          content: '';
          z-index: 1;
        }
        &:after {
          border-radius: 50%;
          width: 4px;
          height: 4px;
          background-color: #4f8528;
          overflow: hidden;
          position: absolute;
          left: 18%;
          top: -1px;
          content: '';
          z-index: 1;
        }
        .time {
          position: absolute;
          left: 5%;
          top: -300%;
          background-color: white;
          color: #4f8528;
          border: 1px solid #4f8528;
          font-size: 0.6em;
          line-height: 1.4;
          padding: 0 6px 0 6px;
          border-radius: 2px;
          z-index: 2;
        }
      }
    }
  }
  .unProcessed {
    display: block;
    background-color: #eba779;
    color: white;
    text-align: left;
    height: 8%;

    > div {
      font-size: 1.2em;
      display: inline-block;
      color: white;
      line-height: 2.8;
      padding-left: 6.5%;
      width: 100%;
      box-shadow: inset -7px 0 5px -5px rgba(0,0,0,0.1);
      cursor: pointer;
    }
  }
  .makeCall {
    position: absolute;
    bottom: 4%;
    right: 4%;
    width: 17%;
    padding-top: 17%;
    background-color: #4f8528;
    background-image: url("../CallsDiary/assets/call.png");
    background-size: contain;
    z-index: 200;
    border-radius: 50%;
    box-shadow: 1px 1px 3px rgba(0,0,0,.3);
    cursor: pointer;
  }
  :global {
    .select.selectEmployee {
      .control {
        .value {
          .name {
            display: none;
          }
        }
      }
    }
    .select__outerMenu.selectEmployee {

    }
    .scrollTrack {
      position: absolute;
      width: 3px !important;
      transition: opacity 200ms;
      right: 0px;
      top: 7%;
      height: 89%;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 110%;
        background-color: #c0bcbc;
        top: -5%;
        left: 1px;
      }
    }
    .scrollThumb {
      width: 3px;
      //height: 16px !important;
      background-color: #949191;
      border-radius: 1px;
      overflow: hidden;
    }
  }
}
:global {
  .select__outerMenu.selectEmployee {
    width: auto;
    padding: 2px;
    border: solid 1px #d7d5d5;
    right: -19px;
    top: -3px;
    .nameWords {
      display: none;
    }
    &:before {
      border-top: 1px solid transparent;
      border-right: 1px solid #d7d5d5;
      border-left: 1px solid transparent;
      border-bottom: 1px solid #d7d5d5;
    }
    .item {
      padding: 0;
      margin: 0;
      text-align: center;

    }
  }
}