@keyframes buttonFetching {
  from {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;

  }
}
.editordermodal {
  position: absolute;
  width: 586px;
  background-color: white;
  color: #868887;
  text-align: center;
  padding: 0;
  -moz-box-shadow: 0 0 10px #000;
  -webkit-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: block;
  line-height: 2;
  cursor: default;
  &.topAlign {
    bottom: 100%;
    margin-bottom: 10px;
    left: -290px;
    .triangle.bottom {
      display: block;
    }
  }
  &.bottomAlign {
    top: 100%;
    margin-top: 10px;
    left: -290px;
    .triangle.top {
      display: block;
    }
  }
  &.leftAlign {
    top: -12px;
    margin-right: 10px;
    right: 100%;
    .triangle.right {
      display: block;
    }
  }
  &.rightAlign {
    top: -12px;
    margin-left: 10px;
    left: 100%;
    .triangle.left {
      display: block;
    }
  }
  &.finish {
    width: 310px;
    background-image: url('../OrderModal/assets/finish-order-bgr.png');
    &:before {
      display: block;
      content: '';
      padding-top: 24%;
    }
    &.topAlign {
      bottom: 100%;
      margin-bottom: 10px;
      left: -153px;
      .triangle.bottom {
        display: block;
      }
    }
    &.bottomAlign {
      top: 100%;
      margin-top: 10px;
      left: -153px;
      .triangle.top {
        display: block;
      }
    }
    &.leftAlign {
      top: -12px;
      margin-right: 10px;
      right: 100%;
      .triangle.right {
        display: block;
      }
    }
    &.rightAlign {
      top: -12px;
      margin-left: 10px;
      left: 100%;
      .triangle.left {
        display: block;
      }
    }
    .text {
      color: #70ad2b;
      font-size: 1.2em;
      line-height: normal;
      padding: 7% 8% 5% 8%;
    }
    .clear {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      margin: 0 0 10%;
      color: #fff;
      font-size: 1.2em;
      text-align: center;
      background-color: #1abc9c;
      border-radius: 1.4em;
      display: inline-block;
      width: auto;
      position: relative;
      overflow: hidden;
      line-height: normal;
      padding: 2% 8% 3% 8%;
      cursor: pointer;
    }
  }
  .triangle {
    display: none;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
    position: absolute;
    &.bottom {
      top: 100%;
      left: 50%;
      margin-left: 2px;
    }
    &.top {
      bottom: 100%;
      left: 50%;
      margin-left: 2px;
      transform: rotate(180deg);
    }
    &.right {
      top: 20px;
      left: 100%;
      transform: rotate(-90deg);
      margin-left: -4px;
    }
    &.left {
      top: 20px;
      right: 100%;
      transform: rotate(90deg);
      margin-right: -4px;
    }
  }
  .header {
    position: relative;
    width: 100%;
    line-height: normal;
    font-size: 1.6em;
    padding: 4% 0 4% 0;
    text-align: center;
    color: #868887;
    white-space: normal;
    background-color: #f6f6f6;
    span {
      color: #ff6804;
      white-space: nowrap;
    }
  }
  .body {
    padding: 0 1% 0 1%;
    position: relative;
    overflow: visible;
    z-index: 500;
    .resource {
      font-size: 0.9em;
      line-height: 3;
      padding-bottom: 4%;
    }
    .dateAndService {
      padding: 2% 1.4% 4% 1%;
      overflow: visible;
      height: auto;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
        .date {
          width: 255px;
          float: left;
          padding: 0 0 0 0;
          margin-right: 2%;
          text-align: right;
          font-size: 1rem;
          line-height: 2rem;
          &.hidden {
            visibility: hidden;
          }
          span {
            color: #73ae2e;
            font-size: 1.2em;
          }
        }
      .services {
        width: 290px;
        float: left;
        padding: 0 0 0 0;
        :global {
          .clubselect.select {
            color: #ff6804;
            .control {
              cursor: pointer;
            }
          }
        }
      }
      .service {
        width: 290px;
        float: left;
        padding: 0 0 0 0;
        color: #ff6804;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .serviceDuration {
        font-size: 0.9em;
        text-align: left;
        padding-right: 0;
        line-height: normal;
        color: #868887;
        > span {
          color: #73ae2e;
          display: inline-block;
        }
      }
    }
    .services {
      .row {
        text-align: left;
        border-bottom: 1px solid #f1f0f0;
        margin: 0 5%;
        padding: 1.5% 0;
        &:before {
          content: '';
          height: 100%;
          display: inline-block;
          vertical-align: middle;
        }
        .name {
          width: 56%;
          display: inline-block;
          vertical-align: middle;
          white-space: normal;
        }
        .duration {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          color: #73ae2e;
          line-height: 1.5;
          > div {
            display: inline-block;
            border-bottom: 1px dashed #73ae2e;
            height: auto;
            cursor: pointer;
          }
        }
        .price {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          color: #73ae2e;
          font-weight: 600;
          padding-right: 1%;
          line-height: 1.5;
          input {
            border: 1px solid #bec3c3;
            border-radius: .25em;
            width: 100%;
            color: #868887;
            padding: 1% 3%;
            display: inline-block;
          }
          .value {
            display: inline-block;
            border-bottom: 1px dashed #73ae2e;
            cursor: pointer;
          }
        }
        .remove {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          background: url('../OrderModal/assets/delete-icon.png') no-repeat;
          background-size: cover;
          cursor: pointer;
          &:hover {
            background-position: 100% top;
          }
        }
      }
    }
    .totalPrice {
      text-align: right;
      padding: 3% 9% 10% 0;
      .label {
        display: inline-block;
        padding-right: 2%;
      }
      .value {
        display: inline-block;
        color: #73ae2e;
        font-weight: 600;
      }
    }
  }
  hr {
    border: none;
    background-color: #e3e1e1;
    width: 96%;
    height: 1px;
    margin: auto;
  }
  .footer {
    text-align: right;
    width: 96%;
    overflow: hidden;
    line-height: normal;
    padding: 2% 1% 2% 1%;
    z-index: 1;
    clear: both;
    > div {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      cursor: pointer;
      &.order {
        margin: 0;
        color: #fff;
        font-size: 1em;
        text-align: center;
        background-color: #73ae2e;
        border-radius: 1.4em;
        display: inline-block;
        width: auto;
        position: relative;
        overflow: hidden;
        line-height: normal;
        padding: 1% 5% 1% 5%;
        &.fetching {
          -webkit-animation-name: buttonFetching;
          animation-name: buttonFetching;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          pointer-events: none;
        }
      }
      &.blocked {
        background-color: grey;
        cursor: not-allowed;
      }
      &.cancel {
        color: #2e7dad;
        line-height: normal;
        padding: 1% 5% 1% 5%;
        text-decoration: underline;
      }
    }
  }
}
