@import '../../../../../styles/themes/default/colors.scss';

.main {
  .header {
    padding: 4.3% 0 2.5%;
    text-align: center;
    color: $orange;
    border-bottom: 1px solid $grey-border-color;
    .button {
      display: inline-block;
      border: 1px solid transparent;
      border-radius: 1.5em;
      padding: 1% 6.3%;
      cursor: pointer;
      &.active {
        border: 1px solid $orange;
      }
      &:hover {
        border: 1px solid $orange;
      }
    }
    .button+.button {
      margin-left: 7%;
    }
  }
}
