:global {
  .modalWindow {
    &__shadowScreen {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
      overflow: hidden;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1001;
      &__window {
        position: relative;
        width: 1130px;
        min-height: 726px;
        height: 80%;
        background-color: #ffffff;
        top: 10%;
        margin: 0 auto 0 auto;
        border-radius: 15px;
        overflow: hidden;
      }
    }
  }
}
.default {
  border: 1px solid #bec3c3;
  border-radius: .25em;
  width: auto;
  line-height: 1.5;
  padding: 1% 3%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
}
.success {
  @extend .default;
  border-color: green !important;
}
.fail {
  @extend .default;
  border-color: #ce252c !important;
}
