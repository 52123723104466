.black {
  position: fixed;
  background-color: rgba(0,0,0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1002;
  top: 0;
  left: 0;
}
.wrapper {
  position: fixed;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1002;
}
.order {
  position: absolute;
  height: 666px;
  width: 690px;
  z-index: 13;
  background-color: #f6f6f6;
}
.header {
  background-color: white;
  color: #1abc9c;
  font-size: 2.25em;
  padding-left: 109px;
  line-height: 2.9;
  padding-bottom: 1.3%;
}
.body {
  margin: 2.9% 7.1% 0;
  color: #696e6f;
  font-size: 1.1em;
}
.row {
  padding-bottom: 6.2%;
}
.item {
  display: inline-block;
  vertical-align: top;
  &:first-child {
    width: 57.7%;
  }
}
.title {
  font-size: 0.75rem;
  padding: 0 0 3px 3px;
}
.phone {
  font-size: 0.9rem;
  padding-top: 6px;
}
.services {
  padding-top: 2.3%;
}
.service {
  border-bottom: 1px solid #e1e3e3;
  padding: 2.5% 0;
  &:last-child {
    border-bottom: none;
  }
  .name {
    display: inline-block;
    width: 75%;
  }
  .duration {
    display: inline-block;
    width: 22%;
    text-align: right;
  }
}
.comment {
  padding-top: 8%;
}
.btnContainer {
  width: 48%;
  margin: auto;
  padding-top: 11.5%;
}
.button {
  display: inline-block;
  vertical-align: middle;
  width: 54%;
  line-height: 2.3;
  border-radius: 2.6em;
  border: 1px solid #fff;
  color: white;
  //background-color: #73ae2e;
  text-align: center;
  font-size: 1.1em;
  cursor: pointer;
}
.green {
  color: #1abc9c;
}
.orange {
  color: #e7832a;
}
.inlineBlock {
  display: inline-block;
}
