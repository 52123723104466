@import '../../../../../../styles/themes/default/colors.scss';

.wrapper {
  color: $grey-color;
  .visualSettings {
    position: relative;
    width: 100%;
    height: 1344px;
    padding-top: 6.9%;
    padding-left: 33.3%;
    border-bottom: 1px solid $grey-border-color;
    .imageBlock {
      position: absolute;
      left: 3%;
      width: 26%;
      &.header {
        top: 10%;
      }
      &.footer {
        bottom: 10%;
      }
      .filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0.3;
      }
      label {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        z-index: 11;
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
        img {
          width: 100%;
          height: 100%;
        }
      }
      :global {
        .slim {
          height: 100%;
          background-color: #c7c7c7;
          filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
          filter: gray; /* IE6-9 */
          -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
          &.slim-no-bg {
            position: absolute;
            top: 0;
            left: 0;
          }
          .slim-btn-remove {
            display: none;
          }
        }
      }

    }
    .firstBlock {
      .title {
        color: $h-color;
        font-size: 2.2em;
        letter-spacing: 0.015em;
      }
    }
  }
  .additionalSettings {
    width: 100%;
    height: 1344px;
    padding-top: 8.5%;
    padding-left: 33.3%;
    .pollItem {
      .link {
        line-height: 1.23;
      }
      &:first-child {
        padding-bottom: 29%;
      }
    }
  }
  .pollItem {
    &.general {
      padding-top: 19%;
    }
    &.params {
      padding-top: 34.8%;
    }
    &.wishes {
      padding-top: 25%;
    }
    .link {
      color: $h-color;
      font-size: 1.5em;
      display: inline-block;
      text-decoration: underline;
      padding-bottom: 0.6%;
      cursor: pointer;
    }
    > div {
      font-size: 1.1em;
    }
  }
  .changeImage {
    font-size: 1.1em;
    padding-top: 6.5%;
    .loadImage {
      padding-top: 1.5%;
      > div {
        display: inline-block;
      }
      .button {
        background-color: #1abc9c;
        color: #fff;
        padding: 0.3% 6.3% 0.7%;
        border-radius: 2em;
        margin-left: 1.5%;
        cursor: pointer;
      }
    }
    :global {
      .inline {
        cursor: pointer;
        .control {
          border-color: transparent;
          .value {
            color: $grey-color;
          }
          .placeHolder {
            color: $grey-color;
          }
        }
      }
    }
  }
}
