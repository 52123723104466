$selected-color: #e67e22;
$orange-color: #e67e22;
:global {
  .settings-block--common {
    background: url("../../../Main/components/Common/assets/card-bgr.png") no-repeat center top;
    width: 100%;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      float: left;
      width: 1px;
      padding-top: 60%;
    }
    input[type="text"], textarea {
      color: #797979;
      width: 100%;
      font-size: 1em;
      margin: 0;
      border: 1px solid #bec3c3;
      border-radius: .25em;
      line-height: 1.5;
      padding: 1% 0.5em;
      display: inline-block;
      vertical-align: middle;
    }
    .leftColumn {
      float: left;
      width: 25%;
      overflow: hidden;
    }
    .rightColumn {
      float: right;
      width: 25%;
      overflow: hidden;
    }
    .yellow-block {
      background: #F7F5E6;
      border: 1px solid #E7E3E3;
      box-sizing: border-box;
      border-radius: 7px;
      width: 100%;
      padding: 0.4em 1em 1em 1em;
      margin-bottom: 3em;
      position: relative;
      clear: both;
      h2 {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 18px;
        margin: 0 0 1em 0;
        color: #54AFE2;
      }

      &__1 {

      }
      &__2 {

      }
      &__3 {

      }
      &__4 {

      }
      &__5 {

      }
      &__label {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 14px;
        color: #54AFE2;
        margin: 0 0 0.5em;
      }
      > div {
        margin-bottom: 1.6em;
        &:last-child {
          margin-bottom: 0;
        }
      }

    }
    .phones {
      .phoneInput {
        white-space: nowrap;
        margin-bottom: 4%;
        input:first-child {
          display: inline-block;
          width: 60%;
          vertical-align: middle;
          padding-right: 1.6em;
        }
        input:last-child, .text {
          display: inline-block;
          width: 33%;
          margin-left: 10%;
          vertical-align: middle;
        }
        .removePhone {
          display: inline-block;
          margin-left: -1.4em;
          width: 0.9em;
          height: 0.9em;
          background: url(../../../../../assets/remove.svg) no-repeat;
          background-size: contain;
          vertical-align: middle;
        }
        &:first-child {
          input:first-child {
            padding-right: 0;
          }
        }
      }
      .addPhone {
        color: #72ac2f;
        font-size: 0.9em;
        text-align: center;
        line-height: 2.4;
        background-color: transparent;
        border-radius: 2em;
        border: 1px solid #72ac2f;
        display: block;
        width: 100%;
        margin: 2% auto 2% auto;
        cursor: pointer;
      }
    }
    .mainPhoto {
      > div {
        display: inline-block;
        width: 60%;
      }
      label {
        color: #fff;
        text-align: center;
        line-height: 1.8;
        background-color: #72ac2f;
        border-radius: 2em;
        display: inline-block;
        margin: 0;
        font-size: 0.9em;
        padding: 0 1em 0 1em;
        cursor: pointer;
      }
    }
    .foundation_year {
      white-space: nowrap;
      div {
        display: inline-block;
        width: 72%;
      }
      input {
        display: inline-block;
        width: 28%;
        text-align: center;
      }
    }

    .workdays-block {
      &__days {
        margin-top: -3%;
        vertical-align: top;
      }
      &__day {
        display: inline-block;
        color: #c9c9c9;
        width: 9%;
        text-align: center;
        margin-right: 2.5%;
        vertical-align: top;
        font-size: 0.9em;
        line-height: 1.8;
        cursor: pointer;
        position: relative;

        &:nth-child(7) {
          margin-left: 4%;
        }
        &:nth-child(7), &:nth-child(8) {
          color: $selected-color;
        }
        &.is_on {
          &:after {
            background: url('../Common/assets/is-on.png') no-repeat center center;
            background-size: contain;
          }
        }
        &:before {
          content: '';
          color: transparent;
          border: 1px solid #bec3c3;
          display: block;
          width: 67%;
          height: 33%;
          box-sizing: content-box;
          border-radius: 4px;
          position: absolute;
          bottom: 0;
          left: 10%;
          z-index: 1;
          background-color: white;
        }
        &:after {
          content: '';
          color: transparent;
          display: block;
          width: 100%;
          height: 50%;
          padding-top: 100%;
          box-sizing: content-box;
          z-index: 6;
          position: relative;
        }
      }
    }
    .worktime-block, .breaktime-block {
      &__time {
        color: #818788;
        position: relative;
        vertical-align: top;
        white-space: nowrap;
        &.hidden {
          display: none;
        }
        .inputGroup {
          border: 1px solid #bec3c3;
          border-radius: .25em;
          width: auto;
          background-color: white;
          display: inline-block;
          &--disabled {
            border: 1px solid #bec3c3;
            background-color: #f3f3f3;
            input {
              color: #cacaca;
            }
          }
        }
        input {
          width: 2em;
          padding: 4% 0;
          text-align: center;
          color: #818788;
          margin: 0;
          border: none;
          background-color: transparent;
        }
        .divider {
          display: inline-block;
          padding: 0 1em 0 1em;
          &:first-child {
            padding: 0 1em 0 0;
          }
        }
      }
      .breaktime-block__switch {
        margin-left: 1em;
        font-size: 0.8em;
        line-height: 1;
        display: inline-block;
        white-space: nowrap;
        color: #818788;
        vertical-align: middle;
        input {
          margin: 0;
          vertical-align: middle;
        }
      }
    }
    .breaktime-block1 {
      &__time {
        color: #1abc9c;
        position: relative;
        vertical-align: top;
        &:before {
          content: '';
          position: relative;
          display: inline-block;
          //background-image: url('components/Common/assets/breaktime-icon.png');
          width: 6.5%;
          padding-top: 6.2%;
          vertical-align: top;
          background-size: contain;
          background-repeat: no-repeat;
        }
        input {
          width: 8%;
          padding: 1% 2%;
          text-align: center;
          color: #818788;
          margin: 0 0.6% 0 0.6%;
        }
      }
      &__switch {
        margin-left: 12%;
        font-size: 1em;
        line-height: 2.4;
        input {
          margin: 0 2% 0 1%;
        }
      }
    }
    .citiesInput {
      position: relative;
    }
    .citiesList {
      box-sizing: content-box;
      width: 100%;
      height: auto;
      max-height: 300px;
      position: absolute;
      background-color: #fff;
      border: 1px solid #1abc9c;
      top: 100%;
      text-align: left;
      line-height: 1.8;
      white-space: normal;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        right: 25px;
        display: block;
        height: 11px;
        width: 11px;
        border-top: 1px solid transparent;
        border-right: 1px solid #1abc9c;
        border-left: 1px solid transparent;
        border-bottom: 1px solid #1abc9c;
        background-color: #fff;
        transform: rotate(-135deg);
      }
      &__item {
        padding: 4px 10px 4px 10px;
        cursor: pointer;
        span {
          display: block;
          line-height: 1.2;
        }
        .title {
          font-size: 0.9em;
        }
        .region-title {
          font-size: 0.8em;
          color: #9ca2a4;
        }
      }
    }
    .column--screen {
      position: absolute;
      left: 37.1%;
      top: 0;
      width: 25.6%;
      padding-top: 56%;
      //border: 1px solid green;
      line-height: 1;
      .cover {
        position: absolute;
        left: 0;
        top: 13.5%;
        height: 23%;
        width: 100%;
        z-index: 1;
        .cover-img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          button {
            position: absolute;
            left: 3%;
            top: 3%;
            z-index: 2;
          }
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .4);
          }
        }
        .defaultCover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;

          &.auto {
            background-image: url("./assets/auto-default.png");
          }
          &.beauty {
            background-image: url("./assets/beauty-default.png");
          }
        }
        .slim {
          height: 100%;
          background-color: transparent;
          z-index: 3;
          .slim-file-hopper {
            background: transparent;
          }
          .slim-btn-group {
            position: absolute;
            bottom: auto;
            top: 2%;
            padding: 0;
            text-align: left;
          }
          .slim-area {
            .slim-result {
              .in {
                object-fit: cover;
                height: 100%;
              }
            }
          }
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .4);
          }
        }
      }
      .heart {
        position: absolute;
        z-index: 3;
        width: 8%;
        padding-top: 8%;
        background-image: url("../Common/assets/heart.png");
        top: 29%;
        background-size: contain;
        background-repeat: no-repeat;
        left: 46%;
      }
      .since_year {
        position: absolute;
        z-index: 3;
        width: 20%;
        top: 34%;
        left: 40%;
        color: white;
        text-align: center;
        font-size: 0.5em;
      }
      .name {
        z-index: 2;
        position: absolute;
        width: 88%;
        left: 6%;
        color: #3c8cd4;
        font-family: PT Sans Narrow;
        font-weight: 700;
        font-size: 1.1em;
        line-height: 1;
        /* text-transform: uppercase; */
        top: 38%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__short-description {
        z-index: 2;
        position: absolute;
        width: 88%;
        left: 6%;
        color: #afafb4;
        font-family: PT Sans Narrow, serif;
        font-weight: 300;
        font-size: 0.7em;
        line-height: 1;
        top: 43.5%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__description {
        z-index: 2;
        position: absolute;
        width: 88%;
        height: 3%;
        left: 6%;
        color: #afafb4;
        font-family: "Fira Sans", serif;
        font-weight: 300;
        font-size: 0.8em;
        line-height: 1;
        top: 96.5%;
        text-align: left;
        overflow: hidden;
      }
      .favorite {
        z-index: 2;
        position: absolute;
        width: 22%;
        padding-top: 4%;
        left: 39%;
        top: 47%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-image: url('../Common/assets/star.png');
        background-position: left center;
        background-size: contain;
        background-color: white;
        &__value {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-image: url('../Common/assets/star--good.png');
          background-position: left center;
          background-size: contain;
        }
      }
      .schedule_until {
        z-index: 2;
        position: absolute;
        width: 34%;
        padding-left: 1em;
        left: 33%;
        color: #afafb4;
        font-weight: 300;
        font-size: 0.5em;
        line-height: 1.6;
        border-radius: 1em;
        /* text-transform: uppercase; */
        top: 52.5%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid #e1f1c9;
        background-image: url('../Common/assets/clock.png');
        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: white;
      }
      .showMap {
        position: absolute;
        width: 32%;
        display: none;
        line-height: 1.8;
        font-size: .75em;
        text-align: right;
        text-decoration: none;
        vertical-align: middle;
        white-space: nowrap;
        padding: 0 1.8em;
        background: url(../Common/assets/location.png) 14.5% 44% no-repeat;
        background-size: 7% 50%;
        border-radius: 1.2em;
        border: 1px solid #fff;
        font-family: Fira Sans;
        color: #fff;
        z-index: 3;
        top: 38%;
        right: 6%;

      }
      .phones {
        z-index: 2;
        position: absolute;
        width: 88%;
        left: 16%;
        color: #55ade2;
        font-family: "Fira Sans", serif;
        font-size: 0.8em;
        line-height: 1;
        top: 84%;
        > div {
          margin-bottom: 0.8em;
          &:nth-child(3) ~ div {
            display: none;
          }
          span {
            color: #bcbdc3;
          }
        }

      }
      .schedule {
        display: none;
        z-index: 3;
        left: 4%;
        top: 45%;
        position: absolute;
        font-size: 0.8em;
        font-family: "Fira Sans";
        width: auto;
        white-space: nowrap;
        .today {
          display: inline-block;
        }
        &:after {
          content: '';
          display: inline-block;
          width: 1.2em;
          height: 1.2em;
          vertical-align: middle;
          background-size: cover;
          background-image: url(../Common/assets/switcher.svg);
          position: relative;
          margin-left: .6em;
        }
      }
      &__url {
        left: 16%;
        top: 78.2%;
        position: absolute;
        font-size: 0.8em;
        font-family: "Fira Sans", serif;
        width: auto;
        white-space: nowrap;
        color: #b9b9bf;
      }
      &__location {
        z-index: 3;
        left: 16%;
        top: 71.2%;
        width: 81%;
        position: absolute;
        font-size: 0.8em;
        font-family: "Fira Sans", serif;
        white-space: normal;
        color: #b9b9bf;
        height: 2.7em;
        overflow: hidden;
        div {

        }
      }
    }


    &__form {
      background: url("../../../Main/components/Common/assets/card-bgr.png") no-repeat center top;
      width: 1146px;
      margin: auto;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        float: left;
        width: 1px;
        padding-top: 60%;
      }
      &__toCommon {
        color: #1abc9c;
        font-size: 0.8em;
        white-space: nowrap;
        text-decoration: underline;
        font-family: "Fira Sans", serif;
      }
      &__text {
        color: #4490d6;
      }
      &__label {

      }

      .companyName {

      }
      .foundation_year {
        white-space: nowrap;
        label {
          display: inline-block;
          width: 60%;
        }
        .input {
          display: inline-block;
          width: 40%;
          text-align: right;
          input {
            width: 5em;
          }
        }
      }
      .siteUrl {
        position: absolute;
        left: 0%;
        top: 50%;
        width: 25%;
      }
      .phones {
        position: absolute;
        left: 0%;
        top: 65%;
        width: 33%;
        .phoneInput {
          vertical-align: middle;
          margin-bottom: 4%;
          .text {
            display: inline-block;
            vertical-align: middle;
            width: 35%;
            margin: 0 0.4em 0 0;
          }
          input {
            display: inline-block;
            width: 46%;
            vertical-align: middle;
            margin: 0 0.4em 0 0;
            &:first-child {
              width: 35%;
            }
          }
          .removePhone {
            vertical-align: middle;
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            background-image: url('../../assets/delete-icon.png');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .addPhone {
          color: #fff;
          font-size: 1.1em;
          text-align: center;
          line-height: 1.8;
          background-color: #1abc9c;
          border-radius: 2em;
          display: inline-block;
          margin: 0;
          text-transform: lowercase;
          padding: 0 16% 0 16%;
        }
      }
      .schedule {
        position: absolute;
        left: 75%;
        top: 43%;
        width: 25%;
      }

      .form__location {
        position: absolute;
        left: 75%;
        top: 58%;
        width: 25%;
        white-space: nowrap;
        &__address {
          > div {
            display: inline-block;
            width: 24%;
            margin-right: 14%;
            input {
              margin-top: 18%;
            }
          }
        }



      }


    }
    &__description {
      background-image: url('../Common/assets/card-description-bgrr.png');
      background-repeat: no-repeat;
      background-position: center;
      height: 610px;
      width: 956px;
      margin: auto;
      position: relative;
      &__label {
        font-size: 1.1em;
        line-height: 1;
        margin: 0 0 0.6em;
      }
      &__info {
        position: absolute;
        left: 40%;
        top: 0%;
        width: 60%;
        textarea {
          width: 100%;
          border-radius: 4px;
          padding: 1% 3%;
          margin-bottom: 2%;
        }
        input {
          width: 100%;
        }
      }
      &__screen {
        position: absolute;
        left: 5.5%;
        top: 12%;
        width: 28%;
        height: 76%;
        color: white;
        line-height: 1;
        &--short {
          padding-top: 4.6%;
          .description {
            display: none;
          }
          .item {
            display: block;
          }
        }
        &--full {
          .description {
            display: block;
          }
          .item {
            display: none;
          }
        }
        .description {
          color: white;
          position: absolute;
          left: 10%;
          top: 18%;
          width: 80%;
          font-size: 0.8em;
          white-space: pre-wrap;
        }
        .item {
          overflow: hidden;
          font-family: PT Sans Narrow;
          border-bottom: .15rem solid #e0e2e7;
          padding: 1% 0 1% 0%;
          vertical-align: middle;
          background-size: cover;
          background-position: center center;
          position: relative;
          white-space: nowrap;
          &.fake {
            &:after {
              background-color: rgba(0, 0, 0, .6);
            }
            .content {
              opacity: 0.4;
            }
          }
          &:before {
            content: '';
            padding-top: 33.5%;
            width: 33%;
            display: inline-block;
            vertical-align: middle;
            z-index: 2;
            background-size: contain;
          }
          &.auto {
            &:before {
              background-image: url(../../assets/icon-demo-auto.png);
            }
          }
          &.style {
            &:before {
              background-image: url(../../assets/icon-demo-style.png);
            }
          }
          &.catering {
            &:before {
              background-image: url(../../assets/icon-demo-catering.png);
            }
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .4);
            z-index: 1;
            background-image: url(../../assets/arrow.png);
            background-repeat: no-repeat;
            background-position: 100% center;
            background-size: 6% auto;
          }
          .content {
            display: inline-block;
            vertical-align: middle;
            z-index: 3;
            position: relative;
            white-space: normal;
            width: 67%;
            div:first-child {
              display: block;
              font-size: 1.2em;
              font-weight: 700;
              line-height: 1;
              color: #fff;
              text-transform: uppercase;
            }
            div:last-child {
              display: block;
              font-size: 1em;
              font-weight: normal;
              line-height: 1;
              color: #fff;
            }
          }
        }
      }
    }
    &__gallery {
      &__block {
        display: inline-block;
        width: 20%;
        margin-right: 1%;
        position: relative;
        padding-top: 10%;
        img {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          top: 0;
        }
        button {
          position: absolute;
          left: 1px;
          top: 1px;
        }
        label {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: gray;
          text-align: center;
          color: white;
          padding-top: 14%;
        }
        .slim {
          visibility: hidden;
          position: absolute;
        }

      }
    }

  }
}
