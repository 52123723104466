.download {
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-size: 4.5vw;
  text-align: center;
  .head {
    width: 100%;
    height: 40vw;
    background-color: #673ab7;
    &:before {
      content: '';
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .text {
      span {
        font-weight: 600;
      }
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      padding-left: 4.5vw;
      background: url('../assets/connect-pro-icon.svg') left center no-repeat;
      background-size: 11%;
    }
  }
  .body {
    width: 100%;
    height: 70%;
    .text {
      text-align: center;
      font-size: 0.8em;
      color: #999ead;
      padding-top: 10%;
      margin-bottom: 4%;
    }
    .links {
      text-align: center;
      a {
        display: block;
        width: 37vw;
        height: 11vw;
        max-width: 230px;
        max-height: 65px;
        margin: 2% auto 4% auto;
        //background-size: contain;
        //background-repeat: no-repeat;
        position: relative;
        //&.android {
        //  background-image: url('../assets/android.png');
        //}
        //&.other {
        //  background-image: url('../assets/apple.png');
        //}
      }
    }
  }
}

@media (min-width: 768px) {
  .download {
    font-size: 30px;
    .head {
      height: 30%;
      .text {
        padding-left: 36px;
      }
    }
    .body  {
      .text {
        padding-top: 100px;
        margin-bottom: 20px;
      }
      .links a {
        width: 230px;
        height: 65px;
        display: inline-block;
        margin: 20px;
      }
    }
  }
}